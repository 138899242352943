import { sha256 } from "js-sha256";
import { getCookieValue } from "./App";
import { getBusinessAccounts } from "./Util_API_calls";

export const isAccountTransaction = (transaction) => {
  return transaction.card_id === undefined;
};

export const isTopUpCardTransaction = (accountTransaction) => {
  return (
    accountTransaction?.transaction_type?.toLowerCase() === "transfer" &&
    (accountTransaction?.description?.toLowerCase() === "top up card" ||
      accountTransaction?.description?.toLowerCase() === "ricarica carta")
  );
};

export const transactionTypeKey = (accountTransaction) => {
  // Checking if request_id starts with specific prefixes
  if (accountTransaction.request_id) {
    if (accountTransaction.request_id.startsWith("EPAY-PHR")) {
      return "account_transaction_type_phone_recharge";
    } else if (accountTransaction.request_id.startsWith("EPAY-BACR-")) {
      return "description_EPAY-BACR";
    } else if (accountTransaction.request_id.startsWith("EPAY-896")) {
      return "description_EPAY-896";
    } else if (accountTransaction.request_id.startsWith("EPAY-BLL")) {
      return "description_EPAY-BLL";
    } else if (accountTransaction.request_id.startsWith("EPAY-MAV")) {
      return "description_EPAY-MAV";
    } else if (accountTransaction.request_id.startsWith("EPAY-PPA")) {
      return "description_EPAY-PPA";
    } else if (accountTransaction.request_id.startsWith("EPAY-PHR")) {
      return "description_EPAY-PHR";
    } else if (accountTransaction.request_id.startsWith("EPAY-RAV")) {
      return "description_EPAY-RAV";
    } else if (accountTransaction.request_id.startsWith("EPAY-PIN")) {
      return "description_EPAY-PIN";
    } else if (accountTransaction.request_id.startsWith("EPAY")) {
      return "account_transaction_type_generic_purchase";
    }
  }

  // Check for a top-up card transaction
  return isTopUpCardTransaction(accountTransaction)
    ? "account_transaction_type_top_up_card"
    : `account_transaction_type_${accountTransaction?.transaction_type?.toLowerCase()}`;
};

export const isCreditTransaction = (transaction) => {
  return (
    transaction.transaction_type === "FUNDING" ||
    transaction.transaction_type === "EXCHANGE" ||
    transaction.direction?.toUpperCase() === "CREDIT"
  );
};

export const isNOOPTransaction = (transaction) => {
  return transaction.direction?.toUpperCase() === "NOOP";
};

export const hashText = (text) => {
  return sha256(text);
};

export const getDisplayName = (profile) => {
  const businessId = getCookieValue("business_id") || "";
  if (businessId) {
    const account = getBusinessAccounts().find(
      (e) => e.business_id === businessId
    );
    return account.label;
  } else {
    return `${profile.first_name} ${profile.last_name}`;
  }
};
