import React, { useState } from 'react';
import { Typography, Link, CircularProgress, ThemeProvider, TextField, Button, Box } from '@mui/material';
import { sendPasswordResetEmail, getAuth } from "firebase/auth";
import { deleteCookie } from './App';
import { t } from './Util_format';
import { theme, ErrorContainer, LoginCentralPic, UnauthenticatedMainContainer, UnauthenticatedLeftContainer, UnauthenticatedRightContainer, SuccessContainer, LoginLogoMobile, UnauthenticatedTitle } from '@style/styled.js';

function ResetPasswordRequest() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isResetEmailSent, setIsResetEmailSent] = useState(false);

  const handleResetPassword = async () => {
    setIsProcessing(true);
    setSuccessMessage();
    setError();
    deleteCookie();
    if (!email.trim()) {
      setError(t("please_insert_email"));
      setIsProcessing(false);
      return;
    }

    const auth = getAuth();

    try {
      await sendPasswordResetEmail(auth, email);
      setSuccessMessage(t("reset_password_email_sent"));
      setIsResetEmailSent(true); // Add this line
    } catch (err) {
      setError(err.message);

      switch (err.code) {
        case "auth/too-many-requests":
          setError(t("too_many_requests_try_later"));
          break;
        case "auth/invalid-email":
          setError(t("invalid_email"));
          break;
        default:
          setError(err.message);
      }
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box id="page_reset_my_password">
        <UnauthenticatedMainContainer>
          <UnauthenticatedLeftContainer>
            <LoginCentralPic></LoginCentralPic>
          </UnauthenticatedLeftContainer>

          <UnauthenticatedRightContainer>

            <LoginLogoMobile />
            <UnauthenticatedTitle>
              {t("password_reset_title")}
            </UnauthenticatedTitle>
            <Typography variant="body2" gutterBottom >
              {t("password_reset_description")}
            </Typography>
            {error && (
              <ErrorContainer isModal={false}>{error}</ErrorContainer>
            )}
            {successMessage &&
              <SuccessContainer isModal={false}>{successMessage}</SuccessContainer>
            }
            {!isResetEmailSent && (
              <>
                <TextField
                  label={t("email_address")}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' && !isProcessing) {
                      handleResetPassword();
                    }
                  }}
                />
                <Button
                  sx={{
                    textTransform: "none",
                    backgroundColor: isProcessing
                      ? "#AAAAAA !important"
                      : undefined,
                    color: isProcessing ? "#FFFFFF !important" : undefined,
                    minWidth: "100%",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                  onClick={handleResetPassword}
                  disabled={isProcessing}
                >
                  {isProcessing ? <CircularProgress size={24} color="inherit" /> : t("send_reset_email")}
                </Button>
              </>
            )}
            <Link href="/login" >
              <Typography variant="body2" >
                {t('back_to_login')}
              </Typography>
            </Link>
          </UnauthenticatedRightContainer>
        </UnauthenticatedMainContainer>
      </Box>
    </ThemeProvider>
  );
}

export default ResetPasswordRequest;
