// ModalTransactionDetails.js
import React from "react";
import { Box, Button, Dialog, DialogContent, Grid2, } from "@mui/material";
import { format } from "date-fns";
import { it } from "date-fns/locale";
import jsPDF from "jspdf";
import { number_to_italian_currency, t } from "./Util_format";
import { getDisplayName } from "./Util_app";
import { ModalTitle } from '@style/styled.js';
import pdf_icon from '@images/pdf_icon.svg';
import logo_big from '@images/logo_big.png';

function ModalTransactionDetails({ onClose, transaction, pageData, info, isCardTransaction }) {
  const specialTransferPrefixes = ['EPAY-BACR', 'EPAY-896', 'EPAY-BLL', 'EPAY-MAV', 'EPAY-PPA', 'EPAY-PHR', 'EPAY-RAV', 'EPAY-PIN'];

  const transactionDescription = transaction.request_id && specialTransferPrefixes.includes(transaction.request_id.split('-').slice(0, 2).join('-'))
    ? t('description_' + transaction.request_id.split('-').slice(0, 2).join('-'))
    : transaction.description ? transaction.description.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : transaction.transaction_type_description;

  const isEpayTransaction = transaction.request_id ? specialTransferPrefixes.includes(transaction.request_id.split('-').slice(0, 2).join('-')) : false;

  const generatePDF = () => {
    if (!pageData || !info || isCardTransaction === undefined) return;
    const headerFontSize = 20;
    const header2FontSize = 16;
    const bodyFontSize = 12;

    const pdf = new jsPDF();

    const logoWidthMM = 510 * 0.1;
    const logoHeightMM = 510 * 0.1;

    const pageWidth = pdf.internal.pageSize.getWidth();
    const centerX = (pageWidth - logoWidthMM) / 2;
    pdf.addImage(logo_big, "PNG", centerX, 10, logoWidthMM, logoHeightMM);

    // Function to format address
    const formatAddress = (details) => {
      const addressParts = [
        details.addr_line_1,
        details.addr_line_2,
        details.zip_code,
        details.city,
        details.state,
        details.country
      ].filter(Boolean);
      
      const chunks = [];
      let currentChunk = [];
      let currentLength = 0;
      
      addressParts.forEach((part, index) => {
        if (currentLength + part.length > 40 || index === addressParts.length - 1) {
          if (currentChunk.length > 0) {
            chunks.push(currentChunk.join(', '));
          }
          currentChunk = [part];
          currentLength = part.length;
        } else {
          currentChunk.push(part);
          currentLength += part.length + 2; // +2 for ", "
        }
      });
      
      if (currentChunk.length > 0) {
        chunks.push(currentChunk.join(', '));
      }
      
      return chunks;
    };

    // Function to wrap text
    const wrapText = (text, maxWidth, fontSize) => {
      pdf.setFontSize(fontSize);
      const words = text.split(' ');
      let lines = [];
      let currentLine = words[0];

      for (let i = 1; i < words.length; i++) {
        const word = words[i];
        const width = pdf.getStringUnitWidth(currentLine + ' ' + word) * fontSize / pdf.internal.scaleFactor;
        
        if (width < maxWidth) {
          currentLine += ' ' + word;
        } else {
          lines.push(currentLine);
          currentLine = word;
        }
      }
      lines.push(currentLine);
      return lines;
    };

    // details
    const headerText1 = isCardTransaction
      ? t("card_transaction_receipt")
      : t("bankaccount_transaction_receipt");

    const name = getDisplayName(pageData.details);
    const headerText2 = `${t('account_owner_name')}: ${name}`;
    
    const addressChunks = formatAddress(pageData.details);
    
    const headerText4 = isCardTransaction
      ? `${t('card_number')}: **** **** **** ${info}`
      : `IBAN: ${info}`;
    const headerText5 = `${t('generated_at')}: ${format(
      new Date(),
      "hh:mm a, dd/MM/yyyy",
      { locale: it }
    )}`;

    let currentY = 70;
    const spacing = 10;

    // Main header
    pdf.setFontSize(headerFontSize);
    pdf.text(headerText1, 15, currentY);
    currentY += spacing * 1.5;

    // Name
    pdf.setFontSize(header2FontSize);
    pdf.text(headerText2, 15, currentY);
    currentY += spacing;

    // Address with wrapping
    pdf.text(`${t('account_owner_address')}:`, 15, currentY);
    currentY += spacing;

    addressChunks.forEach(chunk => {
      const wrappedChunk = wrapText(chunk, pageWidth - 35, bodyFontSize);
      wrappedChunk.forEach(line => {
        pdf.setFontSize(bodyFontSize);
        pdf.text(line, 20, currentY);
        currentY += spacing * 0.8;
      });
    });

    currentY += spacing * 0.2;

    // Account/Card info
    pdf.setFontSize(header2FontSize);
    pdf.text(headerText4, 15, currentY);
    currentY += spacing;

    pdf.text(headerText5, 15, currentY);
    currentY += spacing * 1.5;

    // Transaction details
    pdf.setFontSize(headerFontSize);
    pdf.text(t("Ricevuta della transazione"), 15, currentY);
    currentY += spacing;

    pdf.setFontSize(header2FontSize);

    const transactionDetails = [
      [`${t('transfer_type')}: ${t(transaction.transaction_type)}`],
      [`${t('amount')}: ${number_to_italian_currency(transaction.amount)}`],
      [`${t('transfer_description')}: ${transactionDescription}`]
    ];

    if (isCardTransaction && transaction.merchant_name) {
      transactionDetails.push([`${t('merchant_name')}: ${transaction.merchant_name}`]);
    } else if (!isEpayTransaction) {
      transactionDetails.push(
        [`${t('sender_iban')}: ${transaction.account_from}`],
        transaction.sender_name ? [`${t("sender_name")}: ${transaction.sender_name}`] : null,
        [`${t('receiver_iban')}: ${transaction.account_to}`]
      );
    }

    transactionDetails.push([
      `${t('payment_date')}: ${new Date(
        transaction.transaction_date_time ?? transaction.created_at
      ).toLocaleDateString()}`
    ]);

    transactionDetails.filter(Boolean).forEach(detail => {
      const wrappedDetail = wrapText(detail[0], pageWidth - 30, header2FontSize);
      wrappedDetail.forEach(line => {
        pdf.text(line, 15, currentY);
        currentY += spacing;
      });
    });

    const pdfFileName = `Transaction_${transaction.transaction_id}.pdf`;
    pdf.save(pdfFileName);
  };

  return (
    <Dialog open={!!transaction} onClose={onClose} maxWidth="sm" fullWidth>
      <ModalTitle>
        {t("transaction_receipt")}
      </ModalTitle>
      <DialogContent>
        <Grid2 container rowSpacing={5} columnSpacing={2}>
          <Grid2 size={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>
            {t("amount")}
          </Grid2>
          <Grid2 size={{ xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }}>
            {number_to_italian_currency(transaction.amount)}
          </Grid2>
          <Grid2 size={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>
            {t("transfer_type")}
          </Grid2>
          <Grid2 size={{ xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }}>
            {t(transaction.transaction_type)}
          </Grid2>
          <Grid2 size={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>
            {t("status")}
          </Grid2>
          <Grid2 size={{ xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }}>
            {transaction.status && t(transaction.status)}
            {transaction.transaction_status &&
              t(
                `card_transaction_status_${transaction.transaction_status.toLowerCase()}`
              )}
          </Grid2>
          {!isEpayTransaction && transaction.sender_name && (
            <>
              <Grid2 size={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>
                {t("sender_name")}
              </Grid2>
              <Grid2 size={{ xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }}>
                {transaction.sender_name}
              </Grid2>
            </>
          )}
          {!isEpayTransaction && transaction.beneficiary_name && transaction.beneficiary_surname && (
            <>
              <Grid2 size={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>
                {t("beneficiary_name_title")}
              </Grid2>
              <Grid2 size={{ xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }}>
                {`${transaction.beneficiary_name} ${transaction.beneficiary_surname}`}
              </Grid2>
            </>
          )}
          {transaction.merchant_id && (
            <>
              <Grid2 size={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>
                {t("merchant_id")}
              </Grid2>
              <Grid2 size={{ xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }}>
                {transaction.merchant_id}
              </Grid2>
            </>
          )}
          {transaction.merchant_name && (
            <>
              <Grid2 size={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>
                {t("merchant_name")}
              </Grid2>
              <Grid2 size={{ xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }}>
                {transaction.merchant_name}
              </Grid2>
            </>
          )}
          {(!isEpayTransaction && transaction.account_from) && (
            <>
              <Grid2 size={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>
                {t("sender_iban")}
              </Grid2>
              <Grid2 size={{ xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }}>
                {transaction.account_from}
              </Grid2>
            </>
          )}
          {(!isEpayTransaction && transaction.account_to && transaction.account_to !== 'FDT_FEES_ACCOUNT') && (
            <>
              <Grid2 size={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>
                {t("receiver_iban")}
              </Grid2>
              <Grid2 size={{ xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }}>
                {transaction.account_to}
              </Grid2>
            </>
          )}
          <Grid2 size={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>
            {t("description")}
          </Grid2>
          <Grid2 size={{ xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }}>
            {transactionDescription}
          </Grid2>
          <Grid2 size={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>
            {t("payment_date")}
          </Grid2>
          <Grid2 size={{ xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }}>
            {new Date(
              transaction.transaction_created_at ??
              transaction.transaction_date_time ??
              transaction.created_at
            ).toLocaleDateString()}
          </Grid2>
          <Grid2 size={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>
            {t("transaction_id")}
          </Grid2>
          <Grid2 size={{ xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }}>
            {transaction.transaction_id}
          </Grid2>
        </Grid2>
      </DialogContent>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "20px 0",
        }}
      >
        <Button
          startIcon={
            <img
              src={pdf_icon}
              alt="PDF"
              style={{ height: "30px", width: "30px" }}
            />
          }
          onClick={generatePDF}
          style={{ height: "30px", minWidth: "30px", padding: "0 12px" }}
        >
          {t("download_pdf")}
        </Button>
      </Box>
    </Dialog>
  );
}

export default ModalTransactionDetails;
