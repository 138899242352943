import React, { useState } from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, InputAdornment, TextField } from '@mui/material';
import { getAuth, reauthenticateWithCredential, EmailAuthProvider, updatePassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { getCookieValue, setCookieValue, app, auth, db } from './App';
import { t } from './Util_format';
import { passwordRegex } from './utils/Validator';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { get_api_url } from './Util_API_calls';
import { collection, query, where, getDocs, addDoc } from "firebase/firestore";
import { hashText } from './Util_app';
import { ErrorContainer, ModalTitle } from '@style/styled.js';     

async function getPasswordSetTime(password) {
  const user = auth.currentUser;
  const hashedPassword = hashText(password);
  const q = query(
    collection(db, "passwords"),
    where("user_id", "==", user.uid),
    where("hashed_password", "==", hashedPassword)
  );
  const querySnapshot = await getDocs(q);
  if (!querySnapshot.empty) {
    return new Date(querySnapshot.docs[0].data().created_at).toLocaleString();
  }
  return null;
}

async function setPassword(password) {
  const user = auth.currentUser;
  const hashedPassword = hashText(password);
  await addDoc(collection(db, "passwords"), {
    user_id: user.uid,
    hashed_password: hashedPassword,
    created_at: new Date().toISOString(),
  });
}

function ModalUpdatePassword({ onSave, onClose }) {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();

  const handleTogglePassword = () => setShowPassword(!showPassword);

  const validatePassword = (password) => {
    return passwordRegex.test(password);
  };

  const handleUpdatePassword = async () => {

    // handle firebase no_user_signed_in error
    if (oldPassword === '' || newPassword === '' || confirmNewPassword === '') {
      setErrorMessage(t('password_empty'));
      return;
    }

    if (!validatePassword(newPassword)) {
      setErrorMessage(t('password_complexity_error'));
      return;
    }

    if (newPassword !== confirmNewPassword) {
      setErrorMessage(t('passwords_do_not_match'));
      return;
    }

    if (confirmNewPassword === oldPassword) {
      setErrorMessage(t('error_message_can_not_use_password'));
      return;
    }

    setIsProcessing(true);

    const auth = getAuth(app);
    const user = auth.currentUser;

    if (!user) {
      navigate('/login?action=clean-cookie&reason=user_not_found');
      return;
    }

    try {
      const time = await getPasswordSetTime(confirmNewPassword);
      if (time != null) {
        setIsProcessing(false);
        setErrorMessage(t('error_message_can_not_use_password'));
        return;
      }
    } catch (error) {
      setIsProcessing(false);
      setErrorMessage(t(error.code) || error.message);
      return;
    }

    const credential = EmailAuthProvider.credential(user.email, oldPassword);

    try {
      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, newPassword);

      const initOldPassword = async () => {
        try {
          const time = await getPasswordSetTime(oldPassword);
          if (time === undefined || time === null) {
            await setPassword(oldPassword);
          }
        } catch (_) { }
      }
      initOldPassword();
      try {
        setPassword(confirmNewPassword);
      } catch (_) { }
      await sendEmailConfirmation(); // Ensures email is sent after password update
      navigate("/login?message=password_update_success");
    } catch (error) {
      // Check for the specific error code
      if (error.code === 'auth/invalid-login-credentials') {
        setErrorMessage(t('invalid_login_credentials'));
      } else {
        // Handle other errors generally
        setErrorMessage(t(error.code) || error.message);
      }
    } finally {
      setIsProcessing(false);
    }
  };

  const sendEmailConfirmation = async () => {
    const token = getCookieValue('firebaseToken');
    try {
      const API_URL = await get_api_url();
      const response = await fetch(`${API_URL}/api/user/send_email_password_updated`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }
      setCookieValue('EmailDoubleAuthExp', new Date(Date.now() + 10 * 60000));
    } catch (error) {
      setErrorMessage(t(error.code) || error.message);
    }
  };

  return (
    <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
      <ModalTitle>
        {t('update_password_title')}
      </ModalTitle>
      {errorMessage && (
        <ErrorContainer isModal={false}>{errorMessage}</ErrorContainer>
      )}
      <DialogContent style={{ paddingLeft: '40px', paddingRight: '40px' }}>
        <TextField
          margin="dense"
          label={t('old_password')}
          type={showPassword ? "text" : "password"}
          fullWidth
          variant="outlined"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          disabled={isProcessing}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {showPassword ? (
                  <Visibility onClick={handleTogglePassword} style={{ cursor: "pointer" }} />
                ) : (
                  <VisibilityOff onClick={handleTogglePassword} style={{ cursor: "pointer" }} />
                )}
              </InputAdornment>
            )
          }}
        />
        <TextField
          margin="dense"
          label={t('new_password')}
          type={showPassword ? "text" : "password"}
          fullWidth
          variant="outlined"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          disabled={isProcessing}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {showPassword ? (
                  <Visibility onClick={handleTogglePassword} style={{ cursor: "pointer" }} />
                ) : (
                  <VisibilityOff onClick={handleTogglePassword} style={{ cursor: "pointer" }} />
                )}
              </InputAdornment>
            )
          }}
        />
        <TextField
          margin="dense"
          label={t('confirm_new_password')}
          type={showPassword ? "text" : "password"}
          fullWidth
          variant="outlined"
          value={confirmNewPassword}
          onChange={(e) => setConfirmNewPassword(e.target.value)}
          disabled={isProcessing}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {showPassword ? (
                  <Visibility onClick={handleTogglePassword} style={{ cursor: "pointer" }} />
                ) : (
                  <VisibilityOff onClick={handleTogglePassword} style={{ cursor: "pointer" }} />
                )}
              </InputAdornment>
            )
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}  disabled={isProcessing}>
          {t('cancel')}
        </Button>
        <Button
          onClick={handleUpdatePassword}
          variant="contained"
          
          disabled={isProcessing}
        >
          {isProcessing ? <CircularProgress size={24} /> : t('update_password')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalUpdatePassword;
