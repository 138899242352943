import React, { useState, useEffect, useRef } from 'react';

import { useNavigate } from 'react-router-dom';
import { Box, Dialog, DialogActions, DialogContent, Button, CircularProgress, Autocomplete, Table, TableBody, TableCell, TableRow } from '@mui/material';
import TextField from '@mui/material/TextField';
import { getCookieValue } from './App';
import { NumericFormat } from 'react-number-format';
import { getCurrencySymbol } from './Util_currencies';
import { fetchBankAccountBalance } from './Util_API_calls';
import { number_to_italian_currency, italian_currency_to_number, formatString, t } from './Util_format';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { get_api_url } from './Util_API_calls';
import { ErrorContainer, SuccessContainer, ModalTitle, SliderSettings } from '@style/styled.js';
import SliderCardBlue from '@images/card-blue.webp';
import SliderCardWhite from '@images/card-white.webp';

function ModalStartCardWithdraw({ onClose, card_accounts, closeModal, url_card_id, UserAccountIDFrom }) {
  const [ModalStepNumber, setModalStepNumber] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const inputsRef = useRef([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const step3_submitButtonRef = useRef(null);
  const navigate = useNavigate();
  const [SelectedRechargeAmount] = useState(null);
  const [transferAmount, setTransferAmount] = useState('');
  const [cardAccountBalance, setCardAccountBalance] = useState(null);
  const hasInsufficientFunds = cardAccountBalance !== null && cardAccountBalance - italian_currency_to_number(transferAmount) < 0;
  const amountInputRef = useRef(null);
  const [isNextButtonEnabled, setIsNextButtonEnabled] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  const styles = {
    step_description: {
      paddingBottom: '20px'
    },
  };

  const isAmountValid = () => {
    const amount = italian_currency_to_number(transferAmount);
    return !isNaN(amount) && amount > 0;
  };

  useEffect(() => {
    const isAmountValid = () => {
      const amount = italian_currency_to_number(transferAmount);
      return !isNaN(amount) && amount > 1;
    };

    const isValid = isAmountValid();
    setIsNextButtonEnabled(isValid && !isProcessing);
  }, [transferAmount, isProcessing]);

  useEffect(() => {
    const card = card_accounts.find(
      (e) => e.card_id === url_card_id
    );
    if (!card) return;
    const cardAccountId = card.account_id;
    fetchBankAccountBalance(cardAccountId).then((data) => {
      setCardAccountBalance(data);
    });
  }, [card_accounts, url_card_id]);

  const restart_recharge = () => {
    setErrorMessage('');
    setSuccessMessage('');
    setModalStepNumber(1);
  };

  const step2_review_recharge_details = async () => {
    setErrorMessage('');
    setSuccessMessage('');
    setIsProcessing(true);
    const cardAccountId = card_accounts.find((e) => e.card_id === url_card_id).account_id;
    const balance = await fetchBankAccountBalance(cardAccountId);
    setIsProcessing(false);
    // Update the state with the fetched data
    setCardAccountBalance(balance);
    setModalStepNumber(2);
  };

  const onWithdrawCardToAccount = async () => {
    setErrorMessage('');
    setSuccessMessage('');
    setIsProcessing(true);
    const token = getCookieValue('firebaseToken');

    try {
      const API_URL = await get_api_url();
      const response = await fetch(`${API_URL}/api/baas/transfer/card_to_bank_account`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          bankaccount_account_id: UserAccountIDFrom,
          card_id: url_card_id,
          amount: italian_currency_to_number(transferAmount).toFixed(2),
        })
      });

      const responseData = await response.json();
      
      if (!response.ok) {
        if (responseData.fdt_error_code === "UNLIMIT_PLANNED_MAINTENANCE") {
          throw new Error(t('unlimit_planned_maintenance_error'));
        }
        if (response.status === 500) {
          throw new Error("unexpected_error");
        }
        if (response.status === 429) {
          throw new Error("pleasewait30secs");
        }
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      setModalStepNumber(3);
      setSuccessMessage(t('recharge_initiated_successfully'));
    } catch (error) {
      const errorMsg = error.message === "too_many_requests_try_later" ? t("too_many_requests_try_later") : t(error.message);
      setErrorMessage(errorMsg);
      setSuccessMessage('');
    } finally {
      setIsProcessing(false);
    }
};

  const handleAutocompleteChange = (event, newValue) => {
    if (newValue) {
      // Set the transferAmount state with the selected value
      setTransferAmount(newValue.value.toString());
    } else {
      // Reset the transferAmount state if nothing is selected
      setTransferAmount('');
    }
  };

  useEffect(() => {
    if (card_accounts.length === 0) {
      closeModal();
      navigate('/bank-transfers/create-beneficiary');
    }
  }, [card_accounts,closeModal, navigate]);

  useEffect(() => {
    const isValid = isAmountValid(); // Assuming isAmountValid checks the validity of the input
    setIsNextButtonEnabled(isValid && !isProcessing);
  // eslint-disable-next-line
  }, [transferAmount, isProcessing]); // Depend on transferAmount and isProcessing


  useEffect(() => {
    if (ModalStepNumber === 3 && inputsRef.current[0]) {
      inputsRef.current[0].focus();
    }
  }, [ModalStepNumber, inputsRef]);

  // card selection if the link is from homepage
  if (url_card_id === 'select-card') {
    return (
      <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
        <ModalTitle>
          {t('card_to_withdraw_from')}
        </ModalTitle>
        <DialogContent>
          <Box sx={{ width: "550px", height: "300px", paddingTop: '30px' }}>
            <Slider {...SliderSettings(currentSlide, setCurrentSlide)}>
              {card_accounts.map((card, index) => (
                <Box
                  key={index}
                  sx={{
                    cursor: "pointer",
                    position: "relative",
                    textAlign: 'center',
                    display: 'flex !important',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingBottom: '30px', // Add some padding for spacing
                    paddingTop: '0px', // Add some padding for spacing
                  }}
                >
                  <Box style={{
                    position: 'relative',
                    width: '80%',
                    maxWidth: '400px',
                    margin: '0 auto',
                  }}>
                    {
                      card.current_status === "ACTIVE" ? (
                        <Box style={{
                          color: '#3dc690',
                          position: "absolute",
                          bottom: "25px",
                          left: "50%",
                          transform: "translateX(-50%)",
                          zIndex: 10,
                          fontSize: '1rem',
                          width: '100px',
                          textAlign: 'center',
                          lineHeight: '26px'
                        }}>
                          {t('card_active')}
                        </Box>
                      ) : card.current_status === "FDT_INITIATED" || card.current_status === null || card.current_status === 'ISSUE_PENDING' ? (
                        <Box style={{
                          color: '#aaaaaa',
                          position: "absolute",
                          bottom: "25px",
                          left: "50%",
                          transform: "translateX(-50%)",
                          zIndex: 10,
                          fontSize: '1rem',
                          width: '100px',
                          textAlign: 'center',
                          lineHeight: '26px'
                        }}>
                          {t('card_being_created')}
                        </Box>
                      ) : (
                        <Box style={{
                          color: '#e34542',
                          position: "absolute",
                          bottom: "25px",
                          left: "50%",
                          transform: "translateX(-50%)",
                          zIndex: 10,
                          fontSize: '1rem',
                          width: '100px',
                          textAlign: 'center',
                          lineHeight: '26px'
                        }}>
                          {t('card_inactive')}
                        </Box>
                      )
                    }
                    <img
                      style={{
                        width: "100%",
                        margin: '0 auto',
                        cursor: "pointer",
                        display: 'block',
                      }}
                      src={card.card_color === 'blue' ? SliderCardBlue : SliderCardWhite}
                      alt="card img"
                    />
                    <Box
                      style={{
                        position: "absolute",
                        bottom: "70px",
                        left: "50%",
                        transform: "translateX(-50%)",
                        zIndex: 55,
                        color: "#333333",
                        width: '100%',
                        textAlign: 'center'
                      }}
                    >
                      {card.card_last_four_digits ? '**** **** **** ' + card.card_last_four_digits : '**** **** **** ' + card.card_last_four_digit}
                    </Box>
                  </Box>
                </Box>
              ))}
            </Slider>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            ref={step3_submitButtonRef}
            onClick={closeModal}
            variant="contained"
            disabled={isProcessing}
          >
            {isProcessing ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              t('close')
            )}
          </Button>
          <Button
            ref={step3_submitButtonRef}
            onClick={() => {
              const currentCard = card_accounts[currentSlide];
              if (currentCard) {
                navigate(`/cards/withdraw/${currentCard.card_id}`);
              }
            }}
            variant="contained"
            disabled={isProcessing}
          >
            {isProcessing ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              t('next_button')
            )}
          </Button>

        </DialogActions>
      </Dialog>

    );
  }

  switch (ModalStepNumber) {
    case 2:
      return (
        <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
          {errorMessage && (
            <ErrorContainer isModal={false}>{errorMessage}</ErrorContainer>
          )}
          {successMessage &&
            <SuccessContainer isModal={false}>{successMessage}</SuccessContainer>
          }
          <DialogContent>
            <Table style={{ width: '100%' }}>
              <TableBody>
                <TableCell >
                  <span>{t('recharge_amount')}: </span>
                  <span>{number_to_italian_currency(transferAmount)}</span>
                </TableCell>
                <TableRow>
                  <TableCell >
                    <span>{t('available_balance_card')}: </span>
                    <span> {number_to_italian_currency(cardAccountBalance)}</span>
                  </TableCell> {/* Display the saved transfer description */}
                </TableRow>
                <TableRow>
                  <TableCell >
                    <span>{t('available_balance_card_post_recharge')}: </span>
                    <span
                      style={{
                        color: (cardAccountBalance - italian_currency_to_number(transferAmount)) < 0 ? 'red' : 'inherit'
                      }}
                    >
                      {number_to_italian_currency(cardAccountBalance - italian_currency_to_number(transferAmount))}
                    </span>
                  </TableCell> {/* Display the saved transfer description */}
                </TableRow>
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => restart_recharge()} // Corrected function call
              variant="contained"
              disabled={isProcessing}              >
              {t('edit_button')}
            </Button>
            <Button
              onClick={onWithdrawCardToAccount}
              variant="contained"
              disabled={hasInsufficientFunds || isProcessing}
            >
              {hasInsufficientFunds ? t('not_enough_funds') :
                isProcessing ? <CircularProgress size={24} color="inherit" /> :
                  t('start_account_recharge')}
            </Button>
          </DialogActions>
        </Dialog>

      );
    case 3:
      return (
        <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
          <ModalTitle>
            {t('recharge_status')}
          </ModalTitle>
          {errorMessage && (
            <ErrorContainer isModal={false}>{errorMessage}</ErrorContainer>
          )}
          {successMessage &&
            <SuccessContainer isModal={false}>{successMessage}</SuccessContainer>
          }
          <DialogContent>
            {isProcessing ? (
              <Box style={styles.step_description}>
                {t('recharge_in_progress_do_not_close_this_window')}
              </Box>
            ) : (
              <Box style={styles.step_description}>
                {t('you_can_close_this_window')}
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              ref={step3_submitButtonRef}
              onClick={closeModal}
              variant="contained"
              disabled={isProcessing}
            >
              {isProcessing ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                t('close')
              )}
            </Button>
          </DialogActions>
        </Dialog>
      );
    default:
      const lastFourDigits = card_accounts.find(
        (e) => e.card_id === url_card_id
      ).card_last_four_digits;
      const amounts = [
        { label: '10 €', value: 10 },
        { label: '20 €', value: 20 },
        { label: '50 €', value: 50 },
        { label: '100 €', value: 100 },
        { label: '200 €', value: 200 },
        { label: '500 €', value: 500 }
      ];
      return (
        <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
          {errorMessage && (
            <ErrorContainer isModal={false}>{errorMessage}</ErrorContainer>
          )}
          {successMessage && (
            <SuccessContainer isModal={false}>{successMessage}</SuccessContainer>
          )}
          <DialogContent>
            <Box style={styles.step_description}>
              {t("choose_recharge_account_amount_from_card_description")}
            </Box>
            <Box sx={{ width: 300, marginBottom: 2 }}>
              <Autocomplete
                id="beneficiary-autocomplete"
                options={amounts}
                getOptionLabel={(option) => option.label}
                value={SelectedRechargeAmount}
                onChange={handleAutocompleteChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("common_amounts")}
                    variant="outlined"
                  />
                )}
                renderNoOptionsText={() => null} // Disable "No options" message
              />
            </Box>
            <NumericFormat
              value={transferAmount}
              thousandSeparator={"."}
              decimalSeparator={","}
              isNumericString={true}
              suffix={" " + getCurrencySymbol("EUR")}
              customInput={TextField}
              label={t("amount")}
              variant="outlined"
              name="transfer_amount"
              decimalScale={2}
              fullWidth
              inputRef={amountInputRef}
              allowNegative={false}
              onValueChange={(values) => {
                const { value } = values;
                // Directly set the state with the numeric value
                setTransferAmount(value); // This will trigger the useEffect hook
              }}
            />
            <Box sx={{ marginTop: "16px" }}>
              <span>
                {formatString(t("card_x_balance"), {
                  number: lastFourDigits,
                })}
                :{" "}
              </span>
              {cardAccountBalance ? (
                <span> {number_to_italian_currency(cardAccountBalance)}</span>
              ) : (
                <CircularProgress size={16} color="inherit" />
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={step2_review_recharge_details}
              variant="contained"
              disabled={isProcessing || !isNextButtonEnabled} // Use the state variable directly
            >
              {isProcessing ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                t("next_button")
              )}
            </Button>
          </DialogActions>
        </Dialog>
      );
  }
}

export default ModalStartCardWithdraw;