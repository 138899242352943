import { NotificationsActive, TouchApp } from '@mui/icons-material';
import { Box, Typography, Stack } from '@mui/material';
import { t } from '../Util_format';

export const AnimatedPushNotification = () => {
    return (
      <Stack
        spacing={2}
        alignItems="center"
        sx={{ p: 2 }}
      >
        <Box
          sx={{
            position: 'relative',
            animation: 'bounce 2s infinite',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'primary.main',
            borderRadius: 2,
            p: 2,
            width: 'fit-content',
          }}
        >
          <NotificationsActive
            sx={{
              color: 'white',
              fontSize: '2rem',
              mr: 1
            }}
          />
          <Typography
            sx={{
              color: 'white',
              fontWeight: 'medium'
            }}
          >
            {t("waiting_to_approve_from_mobile")}
          </Typography>
        </Box>
        <TouchApp
          sx={{
            fontSize: '2rem',
            animation: 'pulse 1.5s infinite',
            mt: 2
          }}
        />
      </Stack>
    );
  };