import React, { useState, useEffect, useMemo } from 'react';
import { Grid2, Card, CardContent, Typography, Button, Box, Table, TableBody, TableCell, TextField, TableHead, TableRow, TableSortLabel, Select, MenuItem, FormControl, InputLabel, ListItemIcon, ListItemText, ThemeProvider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ShortcutIcon from '@mui/icons-material/Shortcut';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import ListIcon from '@mui/icons-material/List';
import BankIcon from '@mui/icons-material/AccountBalance';
import RechargeIcon from '@mui/icons-material/AccountBalanceWallet';
import BarChartIcon from '@mui/icons-material/BarChart';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import UpdateIcon from '@mui/icons-material/Update';
import CardIcon from '@mui/icons-material/CreditCard';
import { changeLanguage, getCookieValue } from './App';
import Loading from './Loading';
import { format } from 'date-fns';
import CircularProgress from '@mui/material/CircularProgress';
import { fetchAllAccountBalances, fetchBankAccountBalance, fetchPageData, isBusinessAccount } from './Util_API_calls';
import { t, number_to_italian_currency, italian_currency_to_number_with_negative_amounts, number_to_italian_currency_nocents } from './Util_format';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ModalStartCardEdit from './ModalStartCardEdit';
import { Cell, LineChart, Line, XAxis, YAxis, ResponsiveContainer, CartesianGrid, BarChart, Bar, Tooltip } from 'recharts';
import Slider from 'react-slick';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import Slide from '@mui/material/Slide';
import { getDisplayName, isCreditTransaction, isNOOPTransaction, isTopUpCardTransaction, transactionTypeKey } from './Util_app';
import SliderCardBlue from '@images/card-blue.webp';
import SliderCardWhite from '@images/card-white.webp';
import BusinessGrayCard from '@images/business_gray_card.webp';
import { SliderSettings, HomeCardBgPicture, HomeAccountBalance, PageTitle, theme, CurrencyTableCell, FDTTableFooter, HomeCardHoverType, HomeCardHoverNumber, HomeCardHoverStatus, HomeWelcomeBoxLogo } from '@style/styled.js';
import AccessTimeIcon from '@mui/icons-material/AccessTime';


const VariationColumnChart = ({ userAccounts, userData }) => {
  const [account, setAccount] = useState('');

  const handleChange = (event) => {
    const selected = event.target.value;
    setAccount(selected);
  };

  useEffect(() => {
    if (!account && userAccounts.length > 0) {
      const firstAccount = userAccounts.find(acc => acc.type === 'account');
      if (firstAccount) {
        setAccount(firstAccount);
      }
    }
  }, [userAccounts, account]);

  const initializeChartData = () => {
    return [
      { name: t("col_chart_incomes"), value: 0 },
      { name: t("col_chart_expenses"), value: 0 }
    ];
  };

  let ColumnsChart = initializeChartData();

  const processTransactions = (transactions, isIncoming, isAccountTransaction) => {
    if (isAccountTransaction) {
      if (isIncoming) {
        return transactions.reduce((total, tx) => {
          if (tx.status !== "REJECTED" && (tx.transaction_type === "FUNDING" || tx.transaction_type === "EXCHANGE")) {
            return total + Math.abs(tx.amount);
          }
          return total;
        }, 0);
      } else {
        return transactions.reduce((total, tx) => {
          if (tx.status !== "REJECTED" && (tx.transaction_type === "TRANSFER" || tx.transaction_type === "INVOICE")) {
            return total + Math.abs(tx.amount);
          }
          return total;
        }, 0);
      }
    }

    return transactions.reduce((total, tx) => {
      if (tx.direction === (isIncoming ? "CREDIT" : "DEBIT")) {
        return total + Math.abs(tx.amount);
      }
      return total;
    }, 0);
  };

  if (userData && account) {
    let transactions = [];
    if (account.type === 'account' && userData.current_month_transactions?.bank_accounts?.[account.account_id]) {
      transactions = userData.current_month_transactions.bank_accounts[account.account_id];
    } else if (account.type === 'card' && userData.current_month_transactions?.cards?.[account.card_id]) {
      transactions = userData.current_month_transactions.cards[account.card_id];
    }

    if (transactions.length > 0) {
      const totalIncoming = processTransactions(transactions, true, account.type === 'account');
      const totalOutgoing = processTransactions(transactions, false, account.type === 'account');

      ColumnsChart = [
        { name: t("col_chart_incomes"), value: totalIncoming },
        { name: t("col_chart_expenses"), value: totalOutgoing },
      ];
    }
  }

  // Calculate the maximum value for Y-axis
  const maxValue = Math.max(...ColumnsChart.map(item => item.value));

  // Function to get nice tick values based on data range
  const getNiceTicks = (maxVal) => {
    // Add 20% padding to max value
    const targetMax = maxVal * 1.2;

    // For very small amounts (less than 100€)
    if (targetMax <= 100) {
      const niceMax = Math.ceil(targetMax / 20) * 20;
      return [0, niceMax / 4, niceMax / 2, (niceMax * 3) / 4, niceMax];
    }

    // For small amounts (less than 1000€)
    if (targetMax <= 1000) {
      const niceMax = Math.ceil(targetMax / 100) * 100;
      return [0, niceMax / 4, niceMax / 2, (niceMax * 3) / 4, niceMax];
    }

    // For larger amounts
    const pow10 = Math.pow(10, Math.floor(Math.log10(targetMax)));
    const niceMax = Math.ceil(targetMax / pow10) * pow10;
    return [0, niceMax / 4, niceMax / 2, (niceMax * 3) / 4, niceMax].map(val => Math.round(val));
  };

  const ticks = getNiceTicks(maxValue);
  const yAxisMax = ticks[ticks.length - 1];

  return (
    <>
      <FormControl variant="outlined" style={{ marginRight: '10px', width: '450px', marginBottom: '30px' }}>
        <InputLabel id="account-select-label" shrink={true}>
          {t('select_account_bankaccount_or_card')}
        </InputLabel>
        <Select
          labelId="account-select-label"
          id="account-select"
          value={account}
          label={t('select_account')}
          onChange={handleChange}
          IconComponent={ListIcon}
          renderValue={(selected) => (
            <Box display="flex" alignItems="center">
              {selected && selected.type === 'account' ?
                <AccountBalanceIcon sx={{ color: 'IconsInDropdown.main' }} /> :
                <CreditCardIcon sx={{ color: 'IconsInDropdown.main' }} />}
              <Typography variant="body2" noWrap style={{ marginLeft: '8px' }}>
                {selected ? selected.name : ''}
              </Typography>
            </Box>
          )}
        >
          {userAccounts.map((item) => (
            <MenuItem key={item.card_id || item.account_id} value={item}>
              <ListItemIcon>
                {item.type === 'account' ?
                  <AccountBalanceIcon sx={{ color: 'IconsInDropdown.main' }} /> :
                  <CreditCardIcon sx={{ color: 'IconsInDropdown.main' }} />}
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={ColumnsChart} margin={{ top: 20, right: 20, left: 20, bottom: 20 }}>
          <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" vertical={false} />
          <XAxis
            dataKey="name"
            axisLine={false}
            tickLine={false}
            tick={{ fill: '#657583', fontSize: 12 }}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            tick={{ fill: '#657583', fontSize: 12 }}
            tickFormatter={(value) => `${Math.round(value)} €`}
            ticks={ticks}
            domain={[0, yAxisMax]}
          />
          <Tooltip
            formatter={(value) => [number_to_italian_currency(value), t('variation_balance')]}
            labelStyle={{ display: 'none' }}
          />
          <Bar dataKey="value" barSize={60}>
            {ColumnsChart.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={entry.name === t('col_chart_incomes') ? '#36ae54' : '#e34542'}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};


function VariationTrendChart({ userAccounts, userData }) {
  const [account, setAccount] = useState('');
  const [accountType, setAccountType] = useState(null);
  const [variationBalanceChart, setVariationBalanceChart] = useState([]);

  const handleChange = (event) => {
    const selected = event.target.value;
    setAccountType(selected.type);
    setAccount(selected); // Store the entire selected object
  };

  useEffect(() => {
    if (!account && userAccounts.length > 0) {
      const firstAccount = userAccounts.find(acc => acc.type === 'account');
      const initialAccount = firstAccount;
      if (initialAccount) {
        setAccount(initialAccount);
        setAccountType(initialAccount.type);
      }
    }

    if (userData && account) {
      let dailyBalances = {};
      let transactions = [];

      if (
        userData &&
        userData.current_month_transactions &&
        userData.current_month_transactions.bank_accounts &&
        userData.current_month_transactions.bank_accounts.hasOwnProperty(account.account_id)
      ) {
        transactions = userData.current_month_transactions.bank_accounts[account.account_id];
      } else if (
        userData &&
        userData.current_month_transactions &&
        userData.current_month_transactions.cards &&
        userData.current_month_transactions.cards.hasOwnProperty(account.card_id)
      ) {
        transactions = userData.current_month_transactions.cards[account.card_id];
      } else {
        transactions = [];
      }

      // Get the current date in local time
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();
      const currentDay = currentDate.getDate();

      // Initialize all days from the 1st to today with a balance of 0
      for (let day = 1; day <= currentDay; day++) {
        const dateKey =
          currentYear.toString() +
          ('0' + (currentMonth + 1)).slice(-2) +
          ('0' + day).slice(-2);
        dailyBalances[dateKey] = 0;
      }

      transactions.forEach((transaction) => {
        // Create date object and get its local components
        const transactionDate = new Date(
          transaction.transaction_created_at ||
          transaction.transaction_date_time
        );
        const transactionYear = transactionDate.getFullYear();
        const transactionMonth = transactionDate.getMonth();
        const transactionDay = transactionDate.getDate();

        // Only include transactions from this month
        if (transactionMonth === currentMonth &&
          transactionYear === currentYear) {
          const dateKey =
            transactionYear.toString() +
            ('0' + (transactionMonth + 1)).slice(-2) +
            ('0' + transactionDay).slice(-2);

          if (
            transaction.status !== "REJECTED" &&
            !isNOOPTransaction(transaction)
          ) {
            dailyBalances[dateKey] = isCreditTransaction(transaction)
              ? (dailyBalances[dateKey] || 0) + transaction.amount
              : (dailyBalances[dateKey] || 0) - transaction.amount;
          }
        }
      });

      const sortedDates = Object.keys(dailyBalances).sort();
      let accumulatedBalance = 0;

      const chartData = sortedDates.map(date => {
        accumulatedBalance += dailyBalances[date];
        // Get day of month directly from the date string
        const dayOfMonth = parseInt(date.slice(-2));

        return {
          name: dayOfMonth,
          value: accumulatedBalance
        };
      });

      setVariationBalanceChart(chartData);
    }
  }, [account, accountType, userAccounts, userData]);

  return (
    <>
      <FormControl variant="outlined" style={{ marginRight: '10px', width: '450px', marginBottom: '20px' }}>
        <InputLabel
          id="account-select-label"
          shrink={true}
        >
          {t('select_account_bankaccount_or_card')}
        </InputLabel>
        <Select
          labelId="account-select-label"
          id="account-select"
          value={account}
          label={t('select_account')}
          onChange={handleChange}
          IconComponent={ListIcon}
          renderValue={(selected) => (
            <Box display="flex" alignItems="center">
              {selected && selected.type === 'account' ? <AccountBalanceIcon sx={{ color: 'IconsInDropdown.main' }} /> : <CreditCardIcon sx={{ color: 'IconsInDropdown.main' }} />}
              <Typography variant="body2" noWrap style={{ marginLeft: '8px' }}>
                {selected ? selected.name : ''}
              </Typography>
            </Box>
          )}
        >
          {userAccounts.map((item) => (
            <MenuItem key={item.card_id || item.account_id} value={item}>
              <ListItemIcon>
                {item.type === 'account' ? <AccountBalanceIcon sx={{ color: 'IconsInDropdown.main' }} /> : <CreditCardIcon sx={{ color: 'IconsInDropdown.main' }} />}
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </MenuItem>
          ))}
        </Select>

      </FormControl>
      <ResponsiveContainer width="100%" height={310}>
        <LineChart
          data={variationBalanceChart}
          margin={{ top: 20, right: 20, left: 20, bottom: 20 }}
        >
          <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" vertical={false} />
          <XAxis
            dataKey="name"
            axisLine={false}
            tickLine={false}
            tick={{ fill: '#657583', fontSize: 12 }}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            tick={{ fill: '#657583', fontSize: 12 }}
            tickFormatter={(value) => number_to_italian_currency_nocents(value)}
          />
          <Line type="monotone" dataKey="value" stroke="#333333" strokeWidth={3} dot={{ r: 1 }} />
          <Tooltip
            formatter={(value) => [number_to_italian_currency_nocents(value), t('variation_balance')]}
            labelFormatter={(label) => {
              const currentDate = new Date();
              const monthNumber = currentDate.getMonth() + 1;
              return `${t('chart_balance_day')}: ${label}/${monthNumber}`;
            }}
            cursor={{ strokeDasharray: '3 3' }}
          />
        </LineChart>
      </ResponsiveContainer>
    </>
  )
}

function TransactionsTable({ userAccounts, userData, page, rowsPerPage, setPage, setRowsPerPage, pageData }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'desc' });
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [account, setAccount] = useState('');
  const [accountType, setAccountType] = useState(null);

  const handleChange = (event) => {
    const selected = event.target.value;
    setAccountType(selected.type);
    setAccount(selected); // Store the entire selected object
  };

  // select default on opening
  useEffect(() => {
    if (!account && userAccounts.length > 0) {
      const firstAccount = userAccounts.find(acc => acc.type === 'account');
      const initialAccount = firstAccount; // firstCard || firstAccount;
      if (initialAccount) {
        setAccount(initialAccount);
        setAccountType(initialAccount.type);
      }
    }
  }, [userAccounts, account]);

  useEffect(() => {
    if (account && userData && userData.current_month_transactions) {
      setPage(0)
      let newTransactions = [];

      // Process transactions based on the account type
      if (accountType === 'account') {


        let accountTransactions = [];

        if (
          userData &&
          userData.current_month_transactions &&
          userData.current_month_transactions.bank_accounts &&
          userData.current_month_transactions.bank_accounts.hasOwnProperty(account.account_id)
        ) {
          accountTransactions = userData.current_month_transactions.bank_accounts[account.account_id];
        } else {
          accountTransactions = [];
        }

        if (accountTransactions) {
          newTransactions = accountTransactions.map(tx => {
            const formatter = new Intl.NumberFormat('it-IT', {
              style: 'currency',
              currency: tx.currency,
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
            const rawAmount = tx.transaction_type === 'PURCHASE' ? -Math.abs(tx.amount) : tx.amount;
            const formattedAmount = formatter.format(rawAmount);
            const lastFourDigits = isTopUpCardTransaction(tx)
              ? pageData.user_accounts.card_accounts.find(
                (e) => e.account_number === tx.account_to
              )?.card_last_four_digits || ""
              : "";

            // Modified description construction
            let transactionDescription = `${t(transactionTypeKey(tx))}${lastFourDigits ? ` ${lastFourDigits}` : ""}`;

            // Add beneficiary info if available
            if (tx.beneficiary_name && tx.beneficiary_surname) {
              transactionDescription += ` - ${isCreditTransaction(tx) ? t("received_from") : t("sent_to")} ${tx.beneficiary_name} ${tx.beneficiary_surname}`;
            }

            return {
              date: tx.transaction_date_time,
              description: transactionDescription,
              amount: formattedAmount,
              status: tx.status,
              transaction_type: tx.transaction_type,
              direction: tx.direction
            };
          });
        }
      } else if (accountType === 'card') {


        let cardTransactions = [];

        if (
          userData &&
          userData.current_month_transactions &&
          userData.current_month_transactions.cards &&
          userData.current_month_transactions.cards.hasOwnProperty(account.card_id)
        ) {
          cardTransactions = userData.current_month_transactions.cards[account.card_id];
        } else {
          cardTransactions = [];
        }

        if (cardTransactions) {
          newTransactions = cardTransactions.map(tx => {
            const rawAmount = tx.transaction_type === 'PURCHASE' ? -Math.abs(tx.amount) : tx.amount;
            const formattedAmount = number_to_italian_currency(rawAmount);
            return {
              date: tx.transaction_created_at ? tx.transaction_created_at : '',
              description: tx.description ? tx.description : tx.merchant_name ? t(tx.transaction_type) + ' ' + tx.merchant_name : t(tx.transaction_type),
              amount: formattedAmount,
              transaction_id: tx.transaction_id,
              status: tx.status,
              transaction_type: tx.transaction_type,
              direction: tx.direction,
              transaction_financial_status: tx.transaction_financial_status
            };
          });
        }
      }

      // Apply sorting to the newTransactions
      const sortedTransactions = newTransactions.sort((a, b) => {
        if (sortConfig.key === 'amount') {
          const amountA = italian_currency_to_number_with_negative_amounts(a.amount);
          const amountB = italian_currency_to_number_with_negative_amounts(b.amount);
          return amountA < amountB ? (sortConfig.direction === 'asc' ? -1 : 1) : amountA > amountB ? (sortConfig.direction === 'asc' ? 1 : -1) : 0;
        } else if (sortConfig.key === 'date') {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateA < dateB ? (sortConfig.direction === 'asc' ? -1 : 1) : dateA > dateB ? (sortConfig.direction === 'asc' ? 1 : -1) : 0;
        } else if (sortConfig.key === 'description') {
          return a.description < b.description ? (sortConfig.direction === 'asc' ? -1 : 1) : a.description > b.description ? (sortConfig.direction === 'asc' ? 1 : -1) : 0;
        }
        return 0;
      });

      // Apply filtering to the sortedTransactions
      const filteredTransactions = sortedTransactions.filter(transaction =>
        transaction.description.toLowerCase().includes(searchTerm.toLowerCase())
      );

      setFilteredTransactions(filteredTransactions); // Update the filtered transactions state
    }
  }, [userAccounts, userData, account, accountType, sortConfig, searchTerm, rowsPerPage, setPage, pageData?.user_accounts?.card_accounts]);

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const renderSortLabel = (key, label) => (
    <TableSortLabel
      active={sortConfig.key === key}
      direction={sortConfig.key === key ? sortConfig.direction : 'asc'}
      onClick={() => requestSort(key)}
    >
      {label}
    </TableSortLabel>
  );

  return (
    <>
      <FormControl variant="outlined" style={{ marginRight: '10px', width: '450px', marginBottom: '30px' }}>
        <InputLabel
          id="account-select-label"

          shrink={true}
        >
          {t('select_account_bankaccount_or_card')}
        </InputLabel>
        <Select
          labelId="account-select-label"
          id="account-select"
          value={account}
          label={t('select_account')}
          onChange={handleChange}

          IconComponent={ListIcon}

          renderValue={(selected) => (
            <Box display="flex" alignItems="center">
              {selected && selected.type === 'account' ? <AccountBalanceIcon sx={{ color: 'IconsInDropdown.main' }} /> : <CreditCardIcon sx={{ color: 'IconsInDropdown.main' }} />}
              <Typography variant="body2" noWrap style={{ marginLeft: '8px' }}>
                {selected ? selected.name : ''}
              </Typography>
            </Box>
          )}
        >
          {userAccounts.map((item) => (
            <MenuItem key={item.card_id || item.account_id} value={item}>
              <ListItemIcon>
                {item.type === 'account' ? <AccountBalanceIcon sx={{ color: 'IconsInDropdown.main' }} /> : <CreditCardIcon sx={{ color: 'IconsInDropdown.main' }} />}
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </MenuItem>
          ))}
        </Select>

      </FormControl>

      <TextField
        name="search_filter_unique"
        id="search_filter_unique_id"
        label={t('search_filter')}
        variant="outlined"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        autoComplete="no-autofill"
      />
<Table style={{ marginTop: '20px' }}>
  <TableHead>
    <TableRow>
      <TableCell key={`header-date`}>
        {renderSortLabel('date', t('date'))}
      </TableCell>
      <TableCell key={`header-operation`}>
        {renderSortLabel('operation', t('operations'))}
      </TableCell>
      <TableCell key={`header-description`}>
        {renderSortLabel('description', t('description'))}
      </TableCell>
      <TableCell key={`header-amount`} align="right">
        {renderSortLabel('amount', t('amount'))}
      </TableCell>
    </TableRow>
  </TableHead>
  <TableBody>
  {filteredTransactions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((transaction, index) => {
    const beneficiaryInfo = transaction.beneficiary_name && transaction.beneficiary_surname 
      ? ` - ${isCreditTransaction(transaction) 
          ? t("received_from") 
          : t("sent_to")} ${transaction.beneficiary_name} ${transaction.beneficiary_surname}`
      : "";

    // Check if this is a maintenance fee transaction
    let description = transaction.description || transaction.merchant_name || '';
    if (transaction.transaction_type === "FEE" && description.includes("Charge for maintenance")) {
      description = t("white_account_monthly_fee");
    }

    return (
      <TableRow key={`${transaction.date}-${transaction.description}-${index}`}>
        <TableCell>
          {format(new Date(transaction.date), "dd/MM/yyyy HH:mm")}
        </TableCell>
        <TableCell>
          {t(transactionTypeKey(transaction)) + beneficiaryInfo}
          {transaction.status === "PENDING" && (
            <AccessTimeIcon style={{ marginLeft: "5px", color: "#CCCCCC" }} />
          )}
        </TableCell>
        <TableCell>
          {description}
        </TableCell>
        {transaction.status === "REJECTED" ||
          isNOOPTransaction(transaction) || transaction.transaction_financial_status === "ON_HOLD" ? (
          <TableCell align="right">
            {transaction.status === "REJECTED"
              ? t("cancelled")
              : transaction.amount}
          </TableCell>
        ) : (
          <CurrencyTableCell amount={transaction.amount} isCredit={isCreditTransaction(transaction)} />
        )}
      </TableRow>
    );
  })}
</TableBody>
</Table>
      <FDTTableFooter
        component="div"
        count={filteredTransactions.length}
        page={page}
        onPageChange={(event, newPage) => setPage(newPage)}
        rowsPerPage={rowsPerPage}
        labelRowsPerPage={t('rows_per_page')}
        labelDisplayedRows={
          ({ from, to, count }) => {
            return '' + from + '-' + to + ` ${t('of')} ` + count
          }
        }
        onRowsPerPageChange={(event) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(0);
        }}
      />
    </>
  );
}

function CardBalance({ accountBalances, accountId }) {
  const accountBalance = accountBalances.find((e) => e.id === accountId);
  if (accountBalance) {
    return `${t("card_balance")}: ${number_to_italian_currency(
      accountBalance.balance.available_amount ?? accountBalance.balance.amount
    )}`;
  }
  return null;
}
function PageHome({ onDataChange, ...props }) {
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [bankAccountID, setbankAccountID] = useState(null);
  const [bankAccountBalance, setBankAccountBalance] = useState(null);
  const [isBalanceApiMaintain, setBalanceApiMaintain] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [userAccounts, setUserAccounts] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [selectedCard, setSelectedCard] = useState(null);
  const [accountBalances, setAccountBalances] = useState(null);

  const displayName = useMemo(() => {
    if (userData) {
      const name = getDisplayName(userData.details);
      // Split the name into words, capitalize each word, then join back
      return name.split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
    } else {
      return '';
    }
  }, [userData]);
  // Inside the get_page_data function, after fetching the data
  const processAccountsData = (userData) => {
    const cardAccounts = userData.user_accounts.card_accounts
      .filter(account => account.card_last_four_digits !== null)  // Filter out accounts with null last four digits
      .map(account => ({
        name: `${t('card_label')} **** **** **** ${account.card_last_four_digits}`, // Masked PAN
        card_id: `${account.card_id}`,
        type: 'card'
      }));

    const currentAccounts = userData.user_accounts.current_accounts.map(account => ({
      name: `${t('bank_accounts')} ${account.account_number}`,
      account_id: `${account.account_id}`,
      type: 'account'
    }));


    const allAccounts = [...currentAccounts, ...cardAccounts];
    setUserAccounts(allAccounts);
  };

  const getAccountBalances = async () => {
    try {
      const data = await fetchAllAccountBalances();
      setAccountBalances(data);
    } catch (error) {
      console.error("Error fetching all account balances:", error);
      const isMaintain = error.message === "UNLIMIT_PLANNED_MAINTENANCE";
      if (isMaintain !== isBalanceApiMaintain) {
        setBalanceApiMaintain(isMaintain);
      }
    }
  };

  const get_page_data = async () => {
    setIsLoading(true);
    try {
      const apidata = await fetchPageData('home');
      if (apidata.status === 'error') {
        if (apidata.fdt_error_code === 'TOKEN_EXPIRED') {
          navigate('/login?action=clean-cookie&reason=session_expired');
          return;
        }
        if (apidata.fdt_error_code === 'NO_BUSINESS_ACCOUNT_FOUND') {
          alert(t('error_no_business_account_found'));
        } else {
          alert(t('error_getting_data_from_server'));
        }
        return;
      }

      const data = apidata.data;

      const language = getCookieValue("language") === "en" ? "en" : "it";
      if (data.settings?.general?.user_language && data.settings?.general?.user_language !== language) {
        if (localStorage.getItem("change_user_language") !== "true") {
          changeLanguage(data.settings?.general?.user_language);
        }
      } else if (localStorage.getItem("change_user_language") === "true") {
        localStorage.removeItem("change_user_language");
      }
      processAccountsData(data);
      if (onDataChange) {
        onDataChange(data);
      }
      if (data && data.user_accounts.current_accounts[0].account_id) {
        setbankAccountID(data.user_accounts.current_accounts[0].account_id);
      }
      setUserData(data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    get_page_data();
    getAccountBalances();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const formatter = new Intl.NumberFormat('it-IT', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    async function updateBankAccountBalance() {
      try {
        const balance = await fetchBankAccountBalance(bankAccountID, getCookieValue);
        setBankAccountBalance(formatter.format(balance));
      } catch (error) {
        const isMaintain = error.message === "UNLIMIT_PLANNED_MAINTENANCE";
        if (isMaintain !== isBalanceApiMaintain) {
          setBalanceApiMaintain(isMaintain);
        }
      }
    }

    if (bankAccountID) {
      updateBankAccountBalance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankAccountID, isBalanceApiMaintain]);

  const StartBankTransfer = () => {
    navigate('/bank-transfers/make-payment/');
  };

  const UpdateYourDetails = () => {
    navigate('/profile/');
  };


  function SlideTransition(props) {
    return <Slide {...props} direction="left" />;
  }

  const currentLanguage = getCookieValue("language");
  const now = new Date();
  const dateString = now.toLocaleString(currentLanguage, {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  }).replace(',', ' alle');

  if (isLoading) {
    return <Loading></Loading>;
  }

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
  }

  const handleOpenEditModal = (card) => {
    setSelectedCard(card);
    setIsEditModalOpen(true);
  };

  const handleCopy = () => {
    // Copy account number logic
    navigator.clipboard.writeText(userData.user_accounts.current_accounts[0].account_number);

    // Show Snackbar
    setOpenSnackbar(true);

    // Automatically close Snackbar after 2 seconds
    setTimeout(() => {
      setOpenSnackbar(false);
    }, 2000);
  };

  const hasNoCard =
    userData?.user_accounts.card_accounts.filter(
      (card) => card.card_status !== "UNKNOWN"
    ).length === 0;

  return (
    <ThemeProvider theme={theme}>
      {isEditModalOpen && (
        <ModalStartCardEdit
          isOpen={isEditModalOpen}
          onClose={handleCloseEditModal}
          card_accounts={userData.user_accounts.card_accounts}
          cardId={selectedCard.card_id}
          card_current_status={selectedCard.current_status}
          closeModal={handleCloseEditModal}
        />
      )}
      {/*Displays welcome message and account balance information*/}
      <Grid2 container rowSpacing={5} columnSpacing={2}>
        <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
          <Card
            style={{
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              ...HomeCardBgPicture,
            }}
          >

            <Box
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "20px",
                width: '100%', textAlign: 'left',
                maxWidth: "500px",
              }}
            >
              {!getCookieValue("business_id") && <HomeWelcomeBoxLogo />}
              <Typography
                style={{ ...HomeAccountBalance, fontWeight: 500, fontSize: '1.5rem', }}
              >
                {t("welcome_back")} {displayName}
              </Typography>
            </Box>
            {userData &&
              userData.user_accounts &&
              userData.user_accounts.current_accounts &&
              userData.user_accounts.current_accounts.length > 0 && <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  marginBottom: "20px",
                  maxWidth: "500px",
                }}
              >
                {!isBalanceApiMaintain && (
                  <Typography
                    style={{ ...HomeAccountBalance }}
                  >
                    {t("account_balance")}:
                  </Typography>
                )}
                {!isBalanceApiMaintain && (
                  <Typography
                    variant="h4"
                    style={{ ...HomeAccountBalance, fontWeight: 500, fontSize: '1.5rem' }}
                  >
                    {bankAccountBalance === null ? (
                      <CircularProgress size={14} />
                    ) : (
                      bankAccountBalance
                    )}
                  </Typography>
                )}
                {isBalanceApiMaintain && (
                  <Typography
                    variant="body2"
                  >
                    {t("error_message_balance_api_maintain")}
                  </Typography>
                )}
              </Box>}
            {userData &&
              userData.user_accounts &&
              userData.user_accounts.current_accounts &&
              userData.user_accounts.current_accounts.length > 0 &&
              <>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    marginBottom: "20px",
                    maxWidth: "500px",
                  }}
                >
                  <Typography
                    variant="body2" style={{ ...HomeAccountBalance }}>
                    {t("account_number")}:&nbsp;
                  </Typography>

                  <Typography
                    variant="body2"
                    style={{ ...HomeAccountBalance, cursor: "pointer" }}
                    onClick={handleCopy}
                  >
                    {
                      userData.user_accounts.current_accounts[0]
                        .account_number
                    }
                    <FontAwesomeIcon
                      icon={faCopy}
                      onClick={handleCopy}
                      style={{ marginLeft: "10px" }}
                    />
                  </Typography>
                  <Snackbar
                    open={openSnackbar}
                    onClose={() => setOpenSnackbar(false)}
                    TransitionComponent={SlideTransition}
                    autoHideDuration={2000}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  >
                    <SnackbarContent
                      message={t("iban_copied")}
                      style={{ backgroundColor: "#333333" }}
                    />
                  </Snackbar>
                </Box>
                <Box style={{ width: '100%', textAlign: 'left', maxWidth: "500px", }}>
                  <Typography variant="body2" style={{ ...HomeAccountBalance }}>
                    {t("last_updated_info")} {dateString}
                  </Typography>
                </Box>
              </>
            }
          </Card>
        </Grid2>
        {/*Shows user's cards in a slider with balance information*/}
        <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
          <Card
            style={{
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              ...HomeCardBgPicture,
              position: "relative",
            }}
          >
            <Box
              style={{
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
                top: "16px",
                zIndex: 56,
                color: "#ffffff",
                fontWeight: "bold",
              }}
            >
              {!hasNoCard ? (
                isBalanceApiMaintain || (userData?.user_accounts.card_accounts[currentSlide]?.account_id ?? "") === "INACTIVE" ? (
                  <Box />
                ) : accountBalances ? (
                  <CardBalance
                    accountBalances={accountBalances}
                    accountId={
                      userData?.user_accounts.card_accounts[currentSlide]
                        ?.account_id ?? ""
                    }
                  />
                ) : (
                  <CircularProgress size="1rem" />
                )
              ) : (
                <Box />
              )}
            </Box>
            {isBusinessAccount() && <Box sx={{ width: "400px" }}>
              <img
                style={{
                  width: "calc(100% - 20px)",
                  marginRight: "10px",
                  marginLeft: "10px",
                  boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.15)",
                  borderRadius: "17px",
                }}
                src={BusinessGrayCard}
                alt={`order new card`}
              />
            </Box>}
            {!isBusinessAccount() && <Box sx={{ width: "400px" }}>
              {hasNoCard && (
                // If no cards are left after filtering, you can perform some actions here or render something specific
                <Box sx={{ cursor: "pointer", position: "relative" }}>
                  <img
                    style={{
                      width: "calc(100% - 20px)",
                      marginRight: "10px",
                      marginLeft: "10px",
                      cursor: "pointer",
                      boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.15)",
                      borderRadius: "17px",
                      filter: "blur(2px)",
                    }}
                    src={SliderCardWhite}
                    alt={`order new card`}
                  />
                  <Button
                    onClick={() => navigate("/cards/order")}


                    sx={{
                      width: "75%",
                      position: "absolute",
                      bottom: "40%",
                      left: "50%",
                      transform: "translateX(-50%)",
                      zIndex: 55,
                    }}
                  >
                    {t("order_your_first_card")}
                  </Button>
                </Box>
              )}
              <Slider {...SliderSettings(currentSlide, setCurrentSlide)}>
                {userData?.user_accounts.card_accounts
                  .filter((card) => card.card_status !== "UNKNOWN")
                  .map(
                    (card, index) => (
                      (
                        <Box
                          key={index}
                          sx={{ cursor: "pointer", position: "relative" }}
                        >
                          {card.card_type === "VIRTUAL" ? (
                            <HomeCardHoverType>
                              {t("BAAS_CARD_VIRTUAL_title")}{" "}
                            </HomeCardHoverType>
                          ) : (
                            <HomeCardHoverType>
                              {t("BAAS_CARD_PLASTIC_title")}{" "}
                            </HomeCardHoverType>
                          )}

                          <HomeCardHoverStatus $current_status={card.current_status}>
                            {t(`card_${card.current_status}`)}{" "}
                          </HomeCardHoverStatus>

                          <img
                            style={{
                              width: "calc(100% - 20px)",
                              marginRight: "10px",
                              marginLeft: "10px",
                              cursor: "pointer",
                              boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.15)",
                              borderRadius: "17px",
                            }}
                            src={
                              card.card_color === "blue"
                                ? SliderCardBlue
                                : SliderCardWhite
                            }
                            alt={`card ${card.card_color}`}
                          />

                          <HomeCardHoverNumber>
                            {card.card_last_four_digits
                              ? "**** **** **** " + card.card_last_four_digits
                              : "**** **** **** ****"}
                          </HomeCardHoverNumber>

                          <Button
                            onClick={() => handleOpenEditModal(card)}


                            disabled={
                              card.current_status !== "ACTIVE" &&
                              card.current_status !== "BLOCKED" &&
                              card.current_status !== "INACTIVE"
                            }
                            sx={{
                              position: "absolute",
                              bottom: "20px",
                              left: "50%",
                              transform: "translateX(-50%)",
                              zIndex: 55,
                            }}
                          >
                            {t("show_card_data")}
                          </Button>
                        </Box>
                      )
                    )
                  )}
              </Slider>
            </Box>}
          </Card>
        </Grid2>
        {/*Grid of action buttons for common banking operations*/}
        <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
          <Card style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', height: '100%' }}>
            <CardContent>
              <Grid2>
                <PageTitle>{t("quick_actions")}<ShortcutIcon /></PageTitle>
              </Grid2>

              <Grid2 container rowSpacing={2} columnSpacing={2}>
                <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 4, xl: 4 }} sx={{ paddingBottom: '10px' }}>
                  <Button
                    style={{
                      width: "100%",
                    }}
                    startIcon={<BankIcon />}
                    fullWidth
                    onClick={StartBankTransfer}
                  >
                    {t("bank_transfer_sepa")}
                  </Button>
                </Grid2>
                {!isBusinessAccount() && <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 4, xl: 4 }}>
                  <Button
                    style={{

                      width: "100%",
                    }}


                    startIcon={<RechargeIcon />}
                    fullWidth
                    onClick={() => navigate("/cards/recharge/select-card")}
                  >
                    {t("recharge_your_card")}
                  </Button>
                </Grid2>}
                {!isBusinessAccount() && <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 4, xl: 4 }}>
                  <Button
                    style={{
                      width: "100%",
                    }}


                    startIcon={<RechargeIcon />}
                    fullWidth
                    onClick={() => navigate("/cards/withdraw/select-card")}
                  >
                    {t("recharge_account_from_card")}
                  </Button>
                </Grid2>}
                <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 4, xl: 4 }}>
                  <Button
                    style={{
                      width: "100%",
                    }}


                    startIcon={<UpdateIcon />}
                    fullWidth
                    onClick={UpdateYourDetails}
                  >
                    {t("update_your_details")}
                  </Button>
                </Grid2>
                {!isBusinessAccount() && <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 4, xl: 4 }}>
                  <Button
                    style={{
                      width: "100%",
                    }}


                    startIcon={<CardIcon />}
                    fullWidth
                    onClick={() => navigate("/cards/order")}
                  >
                    {t("order_a_new_card")}
                  </Button>
                </Grid2>}
                {!isBusinessAccount() && <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 4, xl: 4 }}>
                  <Button
                    style={{
                      width: "100%",
                    }}


                    startIcon={<CardIcon />}
                    fullWidth
                    onClick={() => navigate("/services/mobile")}
                  >
                    {t("phone_top_up")}
                  </Button>
                </Grid2>}
              </Grid2>
            </CardContent>
          </Card>
        </Grid2>
        {/*Line chart showing balance changes over time */}
        <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
          <Card
            style={{
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              minHeight: "450px",
            }}
          >
            <CardContent>
              <Grid2>
                <PageTitle>{t("this_month_balance_change")}<ShowChartIcon /></PageTitle>
              </Grid2>
              <VariationTrendChart
                userAccounts={userAccounts}
                userData={userData}
              />
            </CardContent>
          </Card>
        </Grid2>
        {/*// Bar chart comparing incomes and expenses */}
        <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
          <Card
            style={{
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              minHeight: "450px",
            }}
          >
            <CardContent>
              <Grid2>
                <PageTitle>{t("cards_home_chart")}<BarChartIcon /></PageTitle>
              </Grid2>
              <VariationColumnChart
                userAccounts={userAccounts}
                userData={userData}
              />
            </CardContent>
          </Card>
        </Grid2>
        {/*// Table displaying recent transactions with sorting and filtering */}
        <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
          <Card
            style={{
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              padding: "10px",
              minHeight: "380px",
            }}
          >
            <CardContent>
              <Grid2>
                <PageTitle>{t("latest_transactions_bankaccount_and_cards")}<CurrencyExchangeIcon /></PageTitle>
              </Grid2>
              <TransactionsTable
                userData={userData}
                page={page}
                rowsPerPage={rowsPerPage}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
                userAccounts={userAccounts}
                pageData={userData}
              />
            </CardContent>
          </Card>
        </Grid2>
      </Grid2>
    </ThemeProvider>
  );
}

export default PageHome;