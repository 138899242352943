import React, { useState } from 'react';

import { Button, CircularProgress, Dialog, DialogActions, DialogContent } from '@mui/material';
import { getCookieValue } from './App';
import { useNavigate } from 'react-router-dom';
import { t } from './Util_format';
import { get_api_url } from './Util_API_calls';
import { ModalTitle, BeneficiaryDetailsSummary, ErrorContainer } from '@style/styled.js';

function ModalDeletebeneficiary({ beneficiary, onClose, closeDeleteModal }) {
  const [errorMessage, setErrorMessage] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();
  const handleDelete = async () => {
    setIsProcessing(true);

    const token = getCookieValue('firebaseToken');
    const requestOptions = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Bearer ${token}`
      },
      body: new URLSearchParams({
        'beneficiary_id': beneficiary.beneficiary_id
      })
    };

    try {
      const API_URL = await get_api_url();
      const response = await fetch(`${API_URL}/api/baas/beneficiaries/delete`, requestOptions);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      await response.json();
      closeDeleteModal();
      navigate('/bank-transfers/');
      window.location.reload();
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage(error.message || 'An error occurred while deleting the beneficiary.');
    } finally {
      setIsProcessing(false);
    }
  };

  function formatItalianDate(dateString) {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat('it-IT', options);
    return formatter.format(date);
  }

  return (
    <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
      <ModalTitle>
        {t('confirm_delete_beneficiary_description')}
      </ModalTitle>
      {errorMessage && (
        <ErrorContainer isModal={false}>{errorMessage}</ErrorContainer>
      )}

      <DialogContent>
        <BeneficiaryDetailsSummary>{t('payment_circuit')}: {beneficiary.payment_type}</BeneficiaryDetailsSummary>
        <BeneficiaryDetailsSummary>{t('name_and_surname')}: {beneficiary.beneficiary_name} {beneficiary.beneficiary_surname}</BeneficiaryDetailsSummary>
        <BeneficiaryDetailsSummary>{t('account_number')}: {beneficiary.beneficiary_iban.match(/.{1,4}/g).join(' ')}</BeneficiaryDetailsSummary>
        <BeneficiaryDetailsSummary>{t('email_address')}: {beneficiary.beneficiary_email}</BeneficiaryDetailsSummary>
        <BeneficiaryDetailsSummary>{t('swift_code')}: {beneficiary.beneficiary_swift_code}</BeneficiaryDetailsSummary>
        <BeneficiaryDetailsSummary>{t('bic_code')}: {beneficiary.bank_bic}</BeneficiaryDetailsSummary>
        <BeneficiaryDetailsSummary>{t('currency')}: {beneficiary.beneficiary_currency}</BeneficiaryDetailsSummary>
        <BeneficiaryDetailsSummary>{t('creation_date')}: {formatItalianDate(beneficiary.created_at)}</BeneficiaryDetailsSummary>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} >
          {t('cancel')}
        </Button>
        <Button
          onClick={handleDelete}
          variant="contained"
          
          disabled={isProcessing}
        >
          {isProcessing ? <CircularProgress size={24} color="inherit" /> : t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalDeletebeneficiary;