import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid2, Card, CardContent, InputAdornment, Button, TextField, TableHead, Table, TableBody, TableCell, TableRow, ThemeProvider, CircularProgress } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton from '@mui/material/IconButton';
import Loading from './Loading';
import config from './config/env.json';
import ModalStartCardRecharge from './ModalStartCardRecharge';
import ModalStartCardOrder from './ModalStartCardOrder';
import ModalStartCardEdit from './ModalStartCardEdit';
import ModalStartCardWithdraw from './ModalStartCardWithdraw';
import { useNavigate } from 'react-router-dom';
import PaymentIcon from '@mui/icons-material/Payment';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import EditIcon from '@mui/icons-material/Edit';
import { fetchAllAccountBalances, fetchPageData } from './Util_API_calls'; // Adjust the path as necessary
import { number_to_italian_currency, t } from './Util_format';
import RechargeIcon from '@mui/icons-material/AccountBalanceWallet';
import { PageTitle, theme, FDTTableSortLabel, FDTTableFooter } from '@style/styled.js';
import SearchIcon from '@mui/icons-material/Search';

function CardBalance({ accountBalances, accountId }) {
  const accountBalance = accountBalances.find((e) => e.id === accountId);
  if (accountBalance) {
    return (
      <>
        {number_to_italian_currency(
          accountBalance.balance.available_amount ??
          accountBalance.balance.amount
        )}
      </>
    );
  }
  return <></>;
}

function PageCards({ onDataChange, ...props }) {
  const location = useLocation();
  const isMobile = useMediaQuery('(max-width:768px)');
  const [isLoading, setIsLoading] = useState(true);
  const [card_accounts, setcard_accounts] = useState([]);
  const [UserAccountNumberFrom, setUserAccountNumberFrom] = useState('');
  const [UserAccountIDFrom, setUserAccountIDFrom] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'desc' });
  const [isRechargeModalOpen, setIsRechargeModalOpen] = useState(false);
  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);
  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedCardStatus, setSelectedCardStatus] = useState(null);
  const [selectedCardID, setselectedCardID] = useState(null);
  const [accountBalances, setAccountBalances] = useState(null);
  const [isBalanceApiMaintain, setBalanceApiMaintain] = useState(false);
  const [pageData, setPageData] = useState(null);
  const navigate = useNavigate();

  const renderSortLabel = (key, label) => (
    <FDTTableSortLabel
      active={sortConfig.key === key}
      direction={sortConfig.key === key ? sortConfig.direction : 'asc'}
      onClick={() => requestSort(key)}
    >
      {label}
    </FDTTableSortLabel>
  );

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const [sortedcard_accounts, setSortedcard_accounts] = useState([...card_accounts]);


  const handleRecharge = (card_id) => {
    navigate('/cards/recharge/' + card_id);
  };

  const handleEdit = (card_id, current_status) => {
    setSelectedCardStatus(current_status);
    setselectedCardID(card_id);
    setIsEditModalOpen(true);
  };

  const handleOrder = () => {
    navigate('/cards/order');
    // setIsOrderModalOpen(true);
  }


  const handleRechargeSelectCard = () => {
    navigate('/cards/recharge/select-card');
    // setIsOrderModalOpen(true);
  }

  const handleWithdrawSelectCard = () => {
    navigate('/cards/withdraw/select-card');
  }

  const handleStartRechargeModalClose = () => {
    setIsRechargeModalOpen(false);
  };

  const handleStartWithdrawModalClose = () => {
    setIsWithdrawModalOpen(false);
  };

  const handleOrderModalClose = () => {
    setIsOrderModalOpen(false);
  };

  const handleEditModalClose = () => {
    setIsEditModalOpen(false);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [searchQuery, setSearchQuery] = useState('');

  const get_page_data = async () => {
    setIsLoading(true);
    try {
      const apidata = await fetchPageData('cards');
      if (apidata.status === 'error') {
        if (apidata.fdt_error_code === 'TOKEN_EXPIRED') {
          navigate('/login?action=clean-cookie&reason=session_expired');
          return;
        }
        if (apidata.fdt_error_code === 'NO_BUSINESS_ACCOUNT_FOUND') {
          alert(t('error_no_business_account_found'));
        } else {
          alert(t('error_getting_data_from_server'));
        }
        return;
      }
      const data = apidata.data;
      setPageData(data); // Set the fetched data to pageData state
      // After setting the state, call the onDataChange with the new data to update the notification bell
      if (onDataChange) {
        onDataChange(data);
      }

      setcard_accounts(data.card_accounts);
      setcard_accounts(data.user_accounts.card_accounts);
      // console.log();
      setUserAccountNumberFrom(data.user_accounts.current_accounts[0].account_number);
      setUserAccountIDFrom(data.user_accounts.current_accounts[0].account_id);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };

  const handleCloseRechargeModal = () => {
    setIsRechargeModalOpen(false);
  };

  const handleCloseOrderModal = () => {
    setIsOrderModalOpen(false);
  }

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
  }

  useEffect(() => {
    function sortList(card_accounts) {
      return [...card_accounts].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    setSortedcard_accounts(sortList(card_accounts));
  }, [sortConfig, card_accounts]);

  const getAccountBalances = async () => {
    try {
      const data = await fetchAllAccountBalances();
      setAccountBalances(data);
    } catch (error) {
      console.error('Error fetching all account balances:', error);
      const isMaintain = error.message === "UNLIMIT_PLANNED_MAINTENANCE";
      if (isMaintain !== isBalanceApiMaintain) {
        setBalanceApiMaintain(isMaintain);
      }
    }
  };

  useEffect(() => {
    get_page_data();
    getAccountBalances();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const filterAccounts = (accounts) => {
      return accounts.filter((account) => {
        const searchableText = [
          account.card_last_four_digits,
          account.card_type,
        ].join(' ').toLowerCase();
        return searchableText.includes(searchQuery.toLowerCase());
      });
    };

    if (searchQuery) {
      const filteredAccounts = filterAccounts(card_accounts);
      setSortedcard_accounts(filteredAccounts);
    } else {
      setSortedcard_accounts(card_accounts);
    }
  }, [searchQuery, card_accounts]);

  useEffect(() => {
    const path = location.pathname;
    const normalizedPath = path.endsWith("/") ? path.slice(0, -1) : path;
    if (
      normalizedPath === "/cards/recharge" ||
      normalizedPath === "/cards/withdraw"
    ) {
      navigate("/cards");
    } else if (normalizedPath.startsWith("/cards/recharge/select-card")) {
      setIsRechargeModalOpen(true);
    } else if (normalizedPath.startsWith("/cards/recharge/")) {
      setIsRechargeModalOpen(true);
    } else if (normalizedPath.startsWith("/cards/edit/")) {
      setIsEditModalOpen(true);
    } else if (
      normalizedPath.startsWith("/cards/withdraw/select-card") ||
      normalizedPath.startsWith("/cards/withdraw/")
    ) {
      setIsWithdrawModalOpen(true);
    }
    if (normalizedPath === "/cards/order") {
      setIsOrderModalOpen(true);
    }
  }, [location, navigate]);

  if (isLoading) {
    return <Loading></Loading>;
  }

  return (

    <ThemeProvider theme={theme}>
      {isWithdrawModalOpen && (
        <ModalStartCardWithdraw
          isOpen={isWithdrawModalOpen}
          onClose={handleStartWithdrawModalClose}
          card_accounts={card_accounts}
          closeModal={handleStartWithdrawModalClose}
          UserAccountIDFrom={UserAccountIDFrom}
          url_card_id={location.pathname.split('/').pop()}
        />
      )}
      {isRechargeModalOpen && (
        <ModalStartCardRecharge
          isOpen={isRechargeModalOpen}
          onClose={handleStartRechargeModalClose}
          card_accounts={card_accounts}
          closeModal={handleCloseRechargeModal}
          UserAccountNumberFrom={UserAccountNumberFrom}
          UserAccountIDFrom={UserAccountIDFrom}
          url_card_id={location.pathname.split('/').pop()}
        />
      )}
      {isOrderModalOpen && (
        <ModalStartCardOrder
          isOpen={isOrderModalOpen}
          onClose={handleOrderModalClose}
          closeModal={handleCloseOrderModal}
          UserAccountIDFrom={UserAccountIDFrom}
          userDetails={pageData?.details}
          card_accounts={card_accounts}
        />
      )}
      {isEditModalOpen && (
        <ModalStartCardEdit
          isOpen={isEditModalOpen}
          onClose={handleEditModalClose}
          card_accounts={card_accounts}
          closeModal={handleCloseEditModal}
          UserAccountNumberFrom={UserAccountNumberFrom}
          UserAccountIDFrom={UserAccountIDFrom}
          cardId={selectedCardID}
          card_current_status={selectedCardStatus}
        />
      )}
      <Card>
        <CardContent>
          <Grid2>
            <PageTitle>{t("my_cards")}<PaymentIcon /></PageTitle>
          </Grid2>

          <Grid2 container spacing={2} sx={{ mb: 4 }}>
            <Grid2 xs={12} sm={3}>
              <Button
                variant="contained"
                startIcon={<PaymentIcon />}
                fullWidth
                onClick={handleOrder}
              >
                {t("order_new_card")}
              </Button>
            </Grid2>

            <Grid2 xs={12} sm={3}>
              <Button
                variant="contained"
                startIcon={<RechargeIcon />}
                fullWidth
                onClick={handleRechargeSelectCard}
              >
                {t("recharge_your_card")}
              </Button>
            </Grid2>

            <Grid2 xs={12} sm={3}>
              <Button
                variant="contained"
                startIcon={<RechargeIcon />}
                fullWidth
                onClick={handleWithdrawSelectCard}
              >
                {t("recharge_account_from_card")}
              </Button>
            </Grid2>

            <Grid2 xs={12} sm={3}>
              <TextField
                variant="outlined"
                label={t('search_filter')}
                fullWidth
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid2>
          </Grid2>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{renderSortLabel('card_number', t('card_label'))}</TableCell>
                {!isMobile && <TableCell>{renderSortLabel('card_type', t('card_type'))}</TableCell>}
                {!isMobile && <TableCell>{renderSortLabel('status', t('status'))}</TableCell>}
                {!isMobile && <TableCell>{renderSortLabel('balance', t('balance'))}</TableCell>}
                <TableCell>{t('card_recharge')}</TableCell>
                <TableCell>{t('manage_card')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedcard_accounts
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((card, index) => {
                  const productKey = Object.keys(config).find(key => {
                    const configValue = config[key];
                    const productId = card.product_id;
                    if (configValue && productId) {
                      return configValue.toLowerCase() === productId.toLowerCase();
                    }
                    return false;
                  });
                  const translatedProductName = productKey ? t(productKey) : card.product_id;

                  return (
                    <TableRow key={index}>
                      <TableCell>
                        {!isMobile
                          ? card.card_last_four_digits
                            ? `**** **** **** ${card.card_last_four_digits}`
                            : t("not_available")
                          : card.card_last_four_digits
                            ? card.card_last_four_digits
                            : t("not_available")}
                      </TableCell>
                      <TableCell>{translatedProductName}</TableCell>
                      {!isMobile && (
                        <TableCell>
                          {card.current_status
                            ? t(card.current_status)
                            : t("activating")}
                        </TableCell>
                      )}
                      {!isMobile && (
                        <TableCell>
                          {isBalanceApiMaintain || card.current_status === "INACTIVE" ? (
                            t("not_available")
                          ) : accountBalances ? (
                            <CardBalance
                              accountBalances={accountBalances}
                              accountId={card.account_id}
                            />
                          ) : (
                            <CircularProgress size="1rem" />
                          )}
                        </TableCell>
                      )}
                      <TableCell>
                        {card.current_status === "ACTIVE" && (
                          <IconButton onClick={() => handleRecharge(card.card_id)}>
                            <AccountBalanceWalletIcon />
                          </IconButton>
                        )}
                      </TableCell>
                      <TableCell>
                        {(card.current_status === "ACTIVE" || card.current_status === "INACTIVE" || card.current_status === "BLOCKED") && (
                          <IconButton onClick={() => handleEdit(card.card_id, card.current_status)}>
                            <EditIcon />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <FDTTableFooter
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={sortedcard_accounts.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={t('rows_per_page')}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('of')} ${count}`}
          />
        </CardContent>
      </Card>
    </ThemeProvider>
  );
}

export default PageCards;