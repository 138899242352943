import "vanilla-cookieconsent/dist/cookieconsent.css";
import { t } from "../Util_format";

export const getCookieConsentConfig = (language) => {
  const commonModalSettings = {
    layout: "box wide inline",
    flipButtons: false,
    equalWeightButtons: true,
  };

  const cookieCategories = {
    technical: { enabled: true },
    preference: { enabled: true },
    statistical: { enabled: true },
    marketing: { enabled: false },
    thirdparty: { enabled: true },
  };

  const createButton = (data, translationKey, className = "cm__btn") => `
    <button
      type="button"
      class="${className}"
      data="${data}"
    >
      ${t(translationKey)}
    </button>
  `;

  const createModalFooter = () => ({
    footer: `${createButton("accept", "cookie_accept")}
      ${createButton("decline", "cookie_reject")}
      ${createButton("setting", "cookie_settings", "cm__btn cm__btn--secondary")}`
  });

  const createCommonSections = () => [
    {
      description: t('cookie_main_description'),
    },
    {
      title: t('cookie_technical_title'),
      description: t('cookie_technical_description'),
      linkedCategory: "technical",
    },
    {
      title: t('cookie_preference_title'),
      description: t('cookie_preference_description'),
      linkedCategory: "preference",
    },
    {
      title: t('cookie_statistical_title'),
      description: t('cookie_statistical_description'),
      linkedCategory: "statistical",
    },
    {
      title: t('cookie_marketing_title'),
      description: t('cookie_marketing_description'),
      linkedCategory: "marketing",
    },
    {
      title: t('cookie_thirdparty_title'),
      description: t('cookie_thirdparty_description'),
      linkedCategory: "thirdparty",
    },
  ];

  const modalContent = {
    consentModal: {
      description: t('cookie_description'),
      ...createModalFooter()
    },
    preferencesModal: {
      title: t('cookie_info_title'),
      acceptAllBtn: t('cookie_accept_all'),
      savePreferencesBtn: t('cookie_save_settings'),
      sections: createCommonSections()
    }
  };

  return {
    guiOptions: {
      consentModal: {
        ...commonModalSettings,
        position: "bottom center",
      },
      preferencesModal: {
        ...commonModalSettings,
        layout: "box",
      },
    },
    categories: cookieCategories,
    language: {
      default: language,
      translations: {
        en: modalContent,
        it: modalContent,
      }
    },
  };
};