import React, { useState, useEffect, useRef } from 'react';
import { TextField, InputAdornment, CircularProgress, Button, ThemeProvider } from '@mui/material';
import { getAuth, applyActionCode, confirmPasswordReset, checkActionCode } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { t } from './Util_format';
import { passwordRegex } from './utils/Validator';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { theme, LoginCentralPic, UnauthenticatedLeftContainer, UnauthenticatedRightContainer, UnauthenticatedMainContainer, SuccessContainer, ErrorContainer, LoginLogoMobile, UnauthenticatedTitle } from '@style/styled.js';

function Auth_action() {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [IsProcessing, setIsProcessing] = useState(false);
  const [Mode, setMode] = useState(null);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState(''); // New state variable
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const didMountRef = useRef(false);
  const handleTogglePassword = () => setShowPassword(!showPassword);
  const [isResetPasswordEmailSent, setIsResetPasswordEmailSent] = useState(false);

  useEffect(() => {
    if (!didMountRef.current) {
      const urlParams = new URLSearchParams(window.location.search);
      const oobCode = urlParams.get('oobCode');
      const mode = urlParams.get('mode');
      setMode(mode);

      if (!oobCode) return;
      setIsProcessing(true);
      const auth = getAuth();
      checkActionCode(auth, oobCode).then(actionCodeInfo => {
        switch (actionCodeInfo.operation) {
          case "VERIFY_EMAIL":
            return applyActionCode(auth, oobCode).then((_) => actionCodeInfo.operation);
          case "PASSWORD_RESET":
            // We won't handle the reset immediately since we'll show a form to the user.
            break;
          default:
            throw new Error('Unsupported action.');
        }
      }).then((operation) => {
        setIsProcessing(false);
        setError(null);
        if (operation === "VERIFY_EMAIL") {
          setSuccess(t('verification_success'));
          redirectMobileLogin();
        }
      }).catch(err => {
        console.error("Error processing action:", err);
        setIsProcessing(false);
        setError(t("link_invalid_try_again"));
        if (isMobileDevice()) {
          window.location.href = `carta://`;
        }
      });

      didMountRef.current = true;
    }
  }, []);

  function isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  function redirectMobileLogin() {
    if (isMobileDevice()) {
      const queryString = window.location.search;
      window.location.href = `carta://login?${queryString}`;
    }
  }

  const handlePasswordReset = () => {
    if (newPassword !== confirmPassword) {
      setError(t('passwords_do_not_match'));
      return;
    }

    // check complexity: The password must be at least 8 characters long, contain at least one uppercase letter, and a special character such as !@#$%^&*(),`\"`\":|<>.
    if (!newPassword.match(passwordRegex)) {
      setError(t('password_requirements'));
      return;
    }

    const auth = getAuth();
    const urlParams = new URLSearchParams(window.location.search);
    const oobCode = urlParams.get('oobCode');

    setIsProcessing(true);

    confirmPasswordReset(auth, oobCode, newPassword).then(() => {
      setIsResetPasswordEmailSent(true);
      setSuccess(t('password_reset_success'));
      setIsProcessing(false);

      // Add a slight delay before navigation
      setTimeout(() => {
        if (isMobileDevice()) {
          redirectMobileLogin();
        } else {
          navigate("/login?message=password_reset_success");
        }
      }, 1500); // 1.5 second delay to show the success message
    }).catch(err => {
      console.error("Error resetting password:", err);
      setIsProcessing(false);
      switch (err.code) {
        case "auth/weak-password":
          setError(t("password_requirements"));
          break;
        case "auth/expired-action-code":
          setError(t("reset_code_expired"));
          break;
        case "auth/invalid-email":
          setError(t("invalid_email"));
          break;
        default:
          setError(t("error_resetting_password_try_again"));
      }
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <UnauthenticatedMainContainer id="PageAuthActions">
        <UnauthenticatedLeftContainer>
          <LoginCentralPic></LoginCentralPic>
        </UnauthenticatedLeftContainer>
        <UnauthenticatedRightContainer>
          <LoginLogoMobile />
          <UnauthenticatedTitle>
            {/* Conditional rendering of titles based on Mode */}
            {Mode === "verifyEmail" && (
              t("account_verification")
            )}
            {Mode === "resetPassword" && (
              t("set_new_password_title")
            )}
            {(!Mode || Mode === "UNKNOWN") && (
              t("registration_outcome")
            )}
          </UnauthenticatedTitle>
          {IsProcessing && (
            <CircularProgress size={24} color="inherit" />
          )}

          {error && (
            <ErrorContainer isModal={false}>{error}</ErrorContainer>
          )}

          {success && (
            <SuccessContainer isModal={false}>{success}</SuccessContainer>
          )}

          {(Mode === "verifyEmail" && !IsProcessing) && (
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                backgroundColor: IsProcessing
                  ? "#AAAAAA !important"
                  : undefined,
                color: IsProcessing ? "#FFFFFF !important" : undefined,
                minWidth: "100%",
                marginTop: "10px",
                marginBottom: "10px",
              }}
              onClick={() => navigate("/login")}
              disabled={IsProcessing}
            >
              {IsProcessing ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                t("login")
              )}
            </Button>
          )}

          {Mode === "resetPassword" && !isResetPasswordEmailSent && (
            <>
              <TextField
                type={showPassword ? "text" : "password"}
                margin="normal"
                fullWidth
                variant="outlined"
                label={t('enter_new_password')}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' && !IsProcessing) {
                    handlePasswordReset();
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {showPassword ? (
                        <Visibility onClick={handleTogglePassword} style={{ cursor: "pointer" }} />
                      ) : (
                        <VisibilityOff onClick={handleTogglePassword} style={{ cursor: "pointer" }} />
                      )}
                    </InputAdornment>
                  )
                }}
              />
              <TextField
                type={showPassword ? "text" : "password"}
                margin="normal"
                fullWidth
                variant="outlined"
                label={t('confirm_new_password')}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {showPassword ? (
                        <Visibility onClick={handleTogglePassword} style={{ cursor: "pointer" }} />
                      ) : (
                        <VisibilityOff onClick={handleTogglePassword} style={{ cursor: "pointer" }} />
                      )}
                    </InputAdornment>
                  )
                }}
              />

              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  backgroundColor: IsProcessing
                    ? "#AAAAAA !important"
                    : undefined,
                  color: IsProcessing ? "#FFFFFF !important" : undefined,
                  minWidth: "100%",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
                onClick={handlePasswordReset}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' && !IsProcessing) {
                    handlePasswordReset();
                  }
                }}
                disabled={IsProcessing}
              >
                {IsProcessing ? <CircularProgress size={24} color="inherit" /> : t("reset_password")}
              </Button>

            </>
          )}
        </UnauthenticatedRightContainer>
      </UnauthenticatedMainContainer>
    </ThemeProvider >
  );

}

export default Auth_action;
