import React, { useState, useEffect, useRef, useMemo } from 'react';

import { useNavigate } from 'react-router-dom';
import { Dialog, DialogActions, DialogContent, Button, CircularProgress, Box, Autocomplete, Table, TableBody, TableRow, Checkbox, FormControlLabel, Stack, Typography, TableCell } from '@mui/material';
import TextField from '@mui/material/TextField';
import { getCookieValue } from './App';
import { NumericFormat } from 'react-number-format';
import { getCurrencySymbol } from './Util_currencies';
import { fetchBankTransferFee, isBusinessAccount, fetchIbanCheck, fetchSendPayment, fetchCreateBeneficiary, fetchPaymentApprovalStatus } from './Util_API_calls';
import { sendOTPDoubleAuth, verifyOTPDoubleAuth } from './Util_OTP_doubleauth';
import { fetchBankAccountBalance } from './Util_API_calls';
import { number_to_italian_currency, italian_currency_to_number } from './Util_format';
import { t } from './Util_format';
import { ErrorContainer, SuccessContainer, ModalTitle } from '@style/styled.js';
import { AnimatedPushNotification } from './components/AnimatedPushNotification.js';

function ModalStartBankTransfer({ onClose, beneficiaries, closeModal, url_beneficiary_id, UserAccountNumberFrom, UserAccountIDFrom, tempBeneficiary, setTransfering }) {
  const [ModalStepName, setModalStepName] = useState('SELECT_BENEFICIARY');
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [isProcessing, setIsProcessing] = useState(false);
  const inputsRef = useRef([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const step2_submitButtonRef = useRef(null);
  const step4_submitButtonRef = useRef(null);
  const [doubleAuthExpiration, setDoubleAuthExpiration] = useState(null);
  const navigate = useNavigate();
  const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);
  const [transferAmount, setTransferAmount] = useState('');
  const [transferDescription, setTransferDescription] = useState('');
  const amountInputRef = useRef(null);
  const descriptionInputRef = useRef(null);
  const [bank_transfer_fee, setbank_transfer_fee] = useState(null);
  const [bank_account_balance, setbank_account_balance] = useState(null);
  const [shouldSaveBeneficiary, setSaveBeneficiary] = useState(false);
  const [popCode] = useState(null);
  const beneficiaryAddressRef = useRef(null);
  const onSaveBeneficiaryChange = (event) => {
    setSaveBeneficiary(event.target.checked);
  };
  const isFeeBeingCalculated = bank_transfer_fee === null;
  const hasInsufficientFunds = bank_transfer_fee !== null && bank_account_balance !== null && (bank_account_balance - italian_currency_to_number(transferAmount) - bank_transfer_fee) < 0;
  const [smsTimer, setSmsTimer] = useState(30);
  const [pushTimer, setPushTimer] = useState(120);
  const [authMethod, setAuthMethod] = useState(null);
  const [transferStatus, setTransferStatus] = useState({
    status: 'idle', // 'idle' | 'preparing' | 'processing' | 'completed' | 'error'
    message: ''
  });
  const [isTransferInProgress, setIsTransferInProgress] = useState(false);

  const styles = {
    step_description: {
      paddingBottom: '20px'
    },
  };

  const isBusiness = useMemo(() => isBusinessAccount(), []);

  const handleRedirectToCreateNewBeneficiary = () => {
    closeModal();
    navigate('/bank-transfers/create-beneficiary');
  };

  const set_transfer_details = async () => {
    // console.log('ok');
    if (selectedBeneficiary) {
      setErrorMessage("");
      setSuccessMessage("");
      setModalStepName('SET_DETAILS');
    } else {
      closeModal();
      navigate("/bank-transfers/new");
    }
  };

  const step3_review_transfer_details = async () => {
    if (
      selectedBeneficiary.payment_type === "SWIFT" && !selectedBeneficiary.beneficiary_address
    ) {
      try {
        const responseData = await fetchIbanCheck(selectedBeneficiary.beneficiary_iban);

        // Add specific check for IBAN format error
        if (responseData.status === "error" && responseData.details?.iban) {
          setErrorMessage(t("bad_iban_format"));
          setbank_transfer_fee(null);
          return;
        }

        beneficiaryAddressRef.current = responseData.data?.bank?.address;
      } catch (e) {
        setErrorMessage(t("error_fetching_data"));
        setSuccessMessage("");
        setbank_transfer_fee(null);
      }
    }

    // Validate the amount and description from the input fields
    const numericAmount = italian_currency_to_number(
      amountInputRef.current?.value
    );
    if (isNaN(numericAmount) || numericAmount <= 0) {
      // console.log(numericAmount);
      setErrorMessage(t("amount_is_less_than_zero"));
      setSuccessMessage("");
      amountInputRef.current?.focus();
      setTransferAmount("");
      return;
    } else {
      setTransferAmount(amountInputRef.current.value);
    }

    setTransferAmount(amountInputRef.current.value);
    const descriptionValue = descriptionInputRef.current?.value;
    if (!descriptionValue || descriptionValue.length < 5) {
      setErrorMessage(t("description_must_be_at_least_5_chars_long"));
      setSuccessMessage("");
      descriptionInputRef.current?.focus();
      setTransferDescription("");
      return;
    } else {
      setTransferDescription(descriptionValue);
    }

    setTransferDescription(descriptionValue);
    setErrorMessage("");
    setSuccessMessage("");
    setModalStepName('REVIEW_DETAILS');

    const clean_amount = numericAmount.toFixed(2);

    // calculate bank transfer fee
    try {
      const fee = await fetchBankTransferFee(
        selectedBeneficiary.payment_type === "SWIFT" ? "SWIFT" : "SEPA",
        UserAccountNumberFrom,
        selectedBeneficiary.beneficiary_iban,
        clean_amount,
        selectedBeneficiary.beneficiary_currency,
        "EXPRESS",
        selectedBeneficiary.bank_bic,
        selectedBeneficiary.bank_country_code
      );
      setbank_transfer_fee(fee);
    } catch (error) {
      // If there's an error in fetching fee or balance, show an error message and reset the state
      setErrorMessage(t("error_fetching_data"));
      setSuccessMessage("");
      setbank_transfer_fee(null);
    }

    try {
      // Fetch bank account balance
      const [balance] = await Promise.all([
        fetchBankAccountBalance(UserAccountIDFrom),
      ]);
      console.log(balance);
      // Update the state with the fetched data
      setbank_account_balance(balance);
    } catch (error) {
      // If there's an error in fetching fee or balance, show an error message and reset the state
      setErrorMessage(t("error_fetching_data"));
      setSuccessMessage("");
      setbank_account_balance(null);
    }
  }

  const handleSendOTPDoubleAuth = async (isForceSms) => {
    if (italian_currency_to_number(transferAmount) >= 1000 && selectedBeneficiary.beneficiary_currency === "EUR") {
      // set isForceSms to true if the transfer amount is greater than 1000 EUR // note: need to add other currencies in the future
      isForceSms = true;
    }
    setIsProcessing(true);
    if (doubleAuthExpiration) {
      const expirationDate = new Date(doubleAuthExpiration);
      const currentDate = new Date();
      const difference = expirationDate - currentDate;
      const minutesLeft = Math.round(difference / 60000);
      if (minutesLeft > 0) {
        handlebanktranfer();
        return;
      }
    }

    setErrorMessage('');
    setSuccessMessage('');

    try {
      const response = await sendOTPDoubleAuth('payment_create_mfa', isForceSms);
      console.log(response.delivery_method);
      switch (response.delivery_method) {
        case 'sms':
          setAuthMethod('sms');
          setModalStepName('CONFIRM_SMS_OTP');
          setSmsTimer(30);
          break;
        case 'push':
          setAuthMethod('push');
          setModalStepName('WAIT_PUSH_APPROVAL');
          setPushTimer(120);
          setSmsTimer(30);
          break;
        default:
          console.log('unexpexted delivery_method');
          break;
      }
    } catch (error) {
      let errorMsg;
      switch (error.message) {
        case "too_many_requests_try_later":
          errorMsg = t("too_many_requests_try_later");
          break;
        case "wrong_otp_code":
          errorMsg = t("wrong_otp_code");
          break;
        default:
          errorMsg = t(error.message);
          break;
      }
      setErrorMessage(errorMsg);
      setSuccessMessage('');
      setIsProcessing(false);
    } finally {
      setIsProcessing(false);
    }
  };

  const step5_handleVerifyOTPDoubleAuth = async () => {
    setTransfering(true);
    setErrorMessage('');
    setSuccessMessage('');
    setIsProcessing(true);
    const token = getCookieValue('firebaseToken');

    try {
      await verifyOTPDoubleAuth('payment_create_mfa', otp.join(''), token);
      // Change to PROCESSING_TRANSFER state first
      setModalStepName('PROCESSING_TRANSFER');
      setTransferStatus({
        status: 'preparing',
        message: t('preparing_your_transfer')
      });
      handlebanktranfer();
    } catch (error) {
      const errorMsg = error.message === "too_many_requests_try_later" ? t("too_many_requests_try_later") : t(error.message);
      setErrorMessage(errorMsg);
      setIsProcessing(false);
      setSuccessMessage('');
    } finally {
      setIsProcessing(false);
    }
  };

  const handlebanktranfer = async (isTestAction = false) => {

    if (isTransferInProgress) {
      return;
    }

    setIsTransferInProgress(true);


    if (!isTestAction) {
      setErrorMessage('');
      setSuccessMessage('');
      setIsProcessing(true);
      setModalStepName('PROCESSING_TRANSFER');
      setTransferStatus({
        status: 'preparing',
        message: t('preparing_your_transfer')
      });
    }

    try {
      // Set auth expiration
      setDoubleAuthExpiration(new Date(Date.now()));

      // Prepare transfer data
      const data = {
        payment_type: selectedBeneficiary.payment_type,
        from: UserAccountNumberFrom,
        to: selectedBeneficiary.beneficiary_iban,
        amount: italian_currency_to_number(transferAmount).toFixed(2),
        currency: selectedBeneficiary.beneficiary_currency,
        description: transferDescription,
        priority: isBusiness ? 'INSTANT' : 'express',
        beneficiary_name: isBusiness ?
          selectedBeneficiary.beneficiary_name :
          selectedBeneficiary.beneficiary_name + ' ' + selectedBeneficiary.beneficiary_surname,
        beneficiary_country: selectedBeneficiary.bank_country_code,
        beneficiary_bank_code: selectedBeneficiary.bank_bic.length === 8 ?
          `${selectedBeneficiary.bank_bic}XXX` :
          selectedBeneficiary.bank_bic
      };

      // Add optional fields if they exist
      if (popCode) {
        data.pop = popCode;
      }

      if (selectedBeneficiary.beneficiary_address || beneficiaryAddressRef.current) {
        data.beneficiary_address = selectedBeneficiary.beneficiary_address
          ? selectedBeneficiary.beneficiary_address
          : beneficiaryAddressRef.current;
      }

      if (isBusiness) {
        data.business_id = getCookieValue("business_id");
      }

      // Update status to processing before making the API call
      setTransferStatus({
        status: 'processing',
        message: t('please_wait_processing_transfer')
      });

      // Make the API call
      const response = await fetchSendPayment(data);

      // Handle API response
      if (!response.ok) {
        if (isTestAction) {
          return;
        }

        // Set longer expiration on error
        setDoubleAuthExpiration(new Date(Date.now() + 10 * 60000));

        // Handle specific error cases
        switch (response.status) {
          case 500:
            throw new Error("unexpected_error");
          case 429:
            throw new Error("pleasewait30secs");
          default:
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
      }

      setDoubleAuthExpiration(new Date(Date.now()));

      // Handle successful test action
      if (isTestAction) {
        setModalStepName('TRANSFER_SUMMARY');
        return;
      }

      // Update status for successful transfer
      setTransferStatus({
        status: 'completed',
        message: t('transfer_completed_successfully')
      });

      setSuccessMessage(t('transfer_initiated_successfully'));

      // Handle saving temporary beneficiary if needed
      if (tempBeneficiary && shouldSaveBeneficiary) {
        try {
          await fetchCreateBeneficiary(tempBeneficiary);
        } catch (error) {
          // Silently handle beneficiary saving errors
          console.error('Failed to save beneficiary:', error);
        }
      }

      // Move to summary view after successful transfer
      setModalStepName('TRANSFER_SUMMARY');

    } catch (error) {
      console.error("Transfer error:", error);

      // Special handling for undefined status when transfer actually completed
      if (error.message === "HTTP error! status: undefined") {
        setTransferStatus({
          status: 'completed',
          message: t('transfer_completed_successfully')
        });
        setSuccessMessage(t('transfer_initiated_successfully'));
        setErrorMessage('');
      } else {
        setTransferStatus({
          status: 'error',
          message: error.message === "too_many_requests_try_later"
            ? t("too_many_requests_try_later")
            : t(error.message)
        });

        const errorMsg = error.message === "too_many_requests_try_later"
          ? t("too_many_requests_try_later")
          : t(error.message);

        setErrorMessage(errorMsg);
        setSuccessMessage('');
      }

      setModalStepName('TRANSFER_SUMMARY');

    } finally {
      // Clean up processing state
      if (!isTestAction) {
        setIsProcessing(false);
      }
      setIsTransferInProgress(false);
    }
  };

  const step4_handleBackspaceOnSubmit = (e) => {
    if (e.key === 'Backspace') {
      const lastInputIndex = otp.length - 1;
      inputsRef.current[lastInputIndex].focus();
      e.preventDefault();
    }
  };


  const handleChange = (index, value) => {
    if (ModalStepName === 'SELECT_BENEFICIARY' || ModalStepName === 'REVIEW_DETAILS' || ModalStepName === 'TRANSFER_SUMMARY') return;
    if (!/^[0-9]$/.test(value)) return;
    setOtp(prevOtp => {
      const newOtp = [...prevOtp];
      newOtp[index] = value;
      return newOtp;
    });
    setTimeout(() => {
      if (index < otp.length - 1) {

        inputsRef.current[index + 1] && inputsRef.current[index + 1].focus();
      } else if (index === otp.length - 1 && value) {

        if (ModalStepName === 'SET_DETAILS') {
          step2_submitButtonRef.current && step2_submitButtonRef.current.focus();
        }
        if (ModalStepName === 'WAIT_PUSH_APPROVAL') {
          step4_submitButtonRef.current && step4_submitButtonRef.current.focus();
        }
        if (ModalStepName === 'CONFIRM_SMS_OTP') {
          step4_submitButtonRef.current && step4_submitButtonRef.current.focus();
        }
      }
    }, 10);
  };

  const handleKeyDown = (e, index) => {
    if (ModalStepName === 'SELECT_BENEFICIARY' || ModalStepName === 'REVIEW_DETAILS' || ModalStepName === 'TRANSFER_SUMMARY') return;
    if (e.key === 'Backspace') {
      e.preventDefault();
      if (otp[index] === "") {
        const prevIndex = index - 1;
        if (prevIndex >= 0) {
          setOtp((prevOtp) => {
            const newOtp = [...prevOtp];
            newOtp[prevIndex] = "";
            return newOtp;
          });
          setTimeout(() => {
            inputsRef.current[prevIndex].focus();
          }, 0);
        }
      } else {
        setOtp((prevOtp) => {
          const newOtp = [...prevOtp];
          newOtp[index] = "";
          return newOtp;
        });

        setTimeout(() => {
          inputsRef.current[index].focus();
        }, 0);
      }
    }
  };

  // Add a new useEffect hook for redirecting if no beneficiaries are present
  useEffect(() => {
    if (beneficiaries.length === 0) {
      closeModal();
      navigate('/bank-transfers/create-beneficiary');
    }
  }, [beneficiaries, closeModal, navigate]);

  useEffect(() => {
    if (ModalStepName === 'WAIT_PUSH_APPROVAL' && inputsRef.current[0]) {
      inputsRef.current[0].focus();
    }
    if (ModalStepName === 'CONFIRM_SMS_OTP' && inputsRef.current[0]) {
      inputsRef.current[0].focus();
    }
  }, [ModalStepName, doubleAuthExpiration, inputsRef]);

  useEffect(() => {
    if (url_beneficiary_id && beneficiaries.length > 0) {
      const matchingBeneficiary = beneficiaries.find(b => b.beneficiary_id === url_beneficiary_id);
      setSelectedBeneficiary(matchingBeneficiary || null);
      setModalStepName('SET_DETAILS');
    }
  }, [url_beneficiary_id, beneficiaries]);

  useEffect(() => {
    if (tempBeneficiary) {
      setSelectedBeneficiary(tempBeneficiary);
      setModalStepName('SET_DETAILS');
    }
  }, [tempBeneficiary]);

  // First useEffect for timer

  useEffect(() => {
    let timeoutId;
    if (pushTimer > 0) {
      timeoutId = setTimeout(() => setPushTimer(prev => prev - 1), 1000);
    }
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [pushTimer]);

  useEffect(() => {
    let timeoutId;
    if (smsTimer > 0) {
      timeoutId = setTimeout(() => setSmsTimer(prev => prev - 1), 1000);
    }
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [smsTimer]);

  // Second useEffect for initial setup and API polling
  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    let checkInterval;
    if (ModalStepName === 'WAIT_PUSH_APPROVAL') {
      checkInterval = setInterval(async () => {
        if (isTransferInProgress) {
          return;
        }
        try {
          const data = await fetchPaymentApprovalStatus();
          if (data && data.status === "success" && data.message === "Authorised") {
            clearInterval(checkInterval);
            if (!isTransferInProgress) {
              setModalStepName('PROCESSING_TRANSFER');
              setTransferStatus({
                status: 'preparing',
                message: t('preparing_your_transfer')
              });
              handlebanktranfer();
            }
          }
        } catch (error) {
          // Only show error if timer has expired
          if (pushTimer <= 0) {
            clearInterval(checkInterval);
            setErrorMessage(t("push_notification_expired"));
          }
          // Don't show error_checking_mfa_status while timer is active
        }
      }, 2000);
    }

    return () => {
      if (checkInterval) clearInterval(checkInterval);
    };
  }, [ModalStepName, authMethod, isTransferInProgress]);

  switch (ModalStepName) {
    case 'SELECT_BENEFICIARY':
      return (
        <>
          <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
            <ModalTitle>
              {t('set_transfer_beneficiary')}
            </ModalTitle>
            <DialogContent>
              {errorMessage && (
                <ErrorContainer isModal={false}>{errorMessage}</ErrorContainer>
              )}
              {successMessage &&
                <SuccessContainer isModal={false}>{successMessage}</SuccessContainer>
              }

              <Box style={styles.step_description}>
                {t('please_select_or_create_beneficiary_description')}
              </Box>
              <Box sx={{ width: 300 }}>
                <Autocomplete
                  id="beneficiary-autocomplete"
                  options={beneficiaries}
                  getOptionLabel={(option) => `${option.beneficiary_name} ${option.beneficiary_surname} - ${option.beneficiary_iban.slice(-4)}`}
                  getOptionSelected={(option, value) => option.beneficiary_id === value.beneficiary_id}
                  value={selectedBeneficiary}
                  onChange={(event, newValue) => {
                    setSelectedBeneficiary(newValue);
                    // console.log(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t ? t('select_beneficiary') : 'Please start typing the name'}
                      variant="outlined"
                    />
                  )}
                />
              </Box>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', gap: 2, padding: 2 }}>
              <Button
                onClick={handleRedirectToCreateNewBeneficiary}
                variant="contained"
                disabled={isProcessing}
                sx={{ minWidth: '200px' }}
              >
                {isProcessing ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  t('create_beneficiary')
                )}
              </Button>
              <Button
                onClick={set_transfer_details}
                variant="contained"
                disabled={isProcessing}
                sx={{ minWidth: '200px' }}
              >
                {isProcessing ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  t(selectedBeneficiary ? 'next_button' : 'new_bank_transfer')
                )}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    case 'SET_DETAILS':
      if (!selectedBeneficiary) return <Box>{t('no_beneficiary_selected')}</Box>;
      return (
        <>
          <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
            <ModalTitle>
              {t("set_bank_transfer_details")}
            </ModalTitle>
            <DialogContent>
              {errorMessage && (
                <ErrorContainer isModal={false}>{errorMessage}</ErrorContainer>
              )}
              {successMessage && (
                <SuccessContainer isModal={false}>{successMessage}</SuccessContainer>
              )}
              <Stack spacing={2}>
                <Typography>
                  <Box component="span" fontWeight="medium">
                    {t(isBusiness ? "business_name" : "beneficiary_name")}:{" "}
                  </Box>
                  {selectedBeneficiary.beneficiary_name}
                </Typography>
                {selectedBeneficiary.beneficiary_surname && (
                  <Typography>
                    <Box component="span" fontWeight="medium">
                      {t(isBusiness ? "name_and_surname" : "beneficiary_surname")}:{" "}
                    </Box>
                    {selectedBeneficiary.beneficiary_surname}
                  </Typography>
                )}
                {selectedBeneficiary.beneficiary_email && (
                  <Typography>
                    <Box component="span" fontWeight="medium">
                      {t("email")}:{" "}
                    </Box>
                    {selectedBeneficiary.beneficiary_email}
                  </Typography>
                )}
                <Typography>
                  <Box component="span" fontWeight="medium">
                    {t("beneficiary_iban")}:{" "}
                  </Box>
                  {selectedBeneficiary.beneficiary_iban.match(/.{1,4}/g).join(" ")}
                </Typography>
                <Typography>
                  <Box component="span" fontWeight="medium">
                    {t("beneficiary_payment_type")}:{" "}
                  </Box>
                  {selectedBeneficiary.payment_type}
                </Typography>
                <Typography>
                  <Box component="span" fontWeight="medium">
                    {t("bank_bic")}:{" "}
                  </Box>
                  {selectedBeneficiary.bank_bic}
                </Typography>
                <NumericFormat
                  value={transferAmount}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  isNumericString={true}
                  suffix={" " + getCurrencySymbol(selectedBeneficiary.beneficiary_currency)}
                  customInput={TextField}
                  label={t("amount")}
                  variant="outlined"
                  name="transfer_amount"
                  decimalScale={2}
                  fullWidth
                  inputRef={amountInputRef}
                  allowNegative={false}
                  sx={{ my: 1 }}
                />
                <TextField
                  label={t("description")}
                  variant="outlined"
                  fullWidth
                  inputRef={descriptionInputRef}
                  onKeyPress={(event) => {
                    const allowedCharacters = /^[a-zA-Z0-9 _-]*$/;
                    if (
                      event.target.value.length >= 100 &&
                      event.key !== "Backspace"
                    ) {
                      event.preventDefault();
                    } else if (
                      !allowedCharacters.test(event.key) &&
                      event.key !== "Backspace"
                    ) {
                      event.preventDefault();
                    }
                  }}
                />
              </Stack>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', gap: 2, padding: 2 }}>
              <Button
                onClick={onClose}
                variant="contained"
                sx={{ minWidth: '200px' }}
              >
                {t("cancel")}
              </Button>
              <Button
                onClick={step3_review_transfer_details}
                variant="contained"
                sx={{ minWidth: '200px' }}
              >
                {t("next_button")}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    case 'REVIEW_DETAILS':
      if (!selectedBeneficiary) return <Box>{t('no_beneficiary_selected')}</Box>;
      return (
        <>
          <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
            <ModalTitle>
              {t("review_bank_transfer_details")}
            </ModalTitle>
            <DialogContent>
              {errorMessage && (
                <ErrorContainer isModal={false}>{errorMessage}</ErrorContainer>
              )}
              {successMessage && (
                <SuccessContainer isModal={false}>{successMessage}</SuccessContainer>
              )}
              <Table style={{ width: "100%" }}>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {t(isBusiness ? "business_name" : "beneficiary_name")}:{" "}
                      {selectedBeneficiary.beneficiary_name}
                    </TableCell>
                  </TableRow>
                  {selectedBeneficiary.beneficiary_surname && <TableRow>
                    <TableCell>
                      {t(isBusiness ? "name_and_surname" : "beneficiary_surname")}:{" "}
                      {selectedBeneficiary.beneficiary_surname}
                    </TableCell>
                  </TableRow>}
                  {selectedBeneficiary.beneficiary_email && (
                    <TableRow>
                      <TableCell>
                        {t("Email")}: {selectedBeneficiary.beneficiary_email}
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell>
                      {t("beneficiary_iban")}:{" "}
                      {selectedBeneficiary.beneficiary_iban
                        .match(/.{1,4}/g)
                        .join(" ")}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {t("beneficiary_payment_type")}:{" "}
                      {selectedBeneficiary.payment_type}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {t("transfer_amount")}: {transferAmount}
                    </TableCell>{" "}
                    {/* Display the saved transfer amount */}
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {t("transfer_description")}: {transferDescription}
                    </TableCell>{" "}
                    {/* Display the saved transfer description */}
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <span>{t("bank_transfer_fee")}: </span>
                      {bank_transfer_fee === null ? (
                        <CircularProgress
                          style={{ marginLeft: "10px", alignSelf: "center" }}
                          size={14}
                        />
                      ) : (
                        <span>
                          {number_to_italian_currency(bank_transfer_fee)}
                        </span>
                      )}
                    </TableCell>{" "}
                    {/* Display the saved transfer description */}
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <span>{t("available_balance")}: </span>
                      {bank_account_balance === null ? (
                        <CircularProgress
                          style={{ marginLeft: "10px", alignSelf: "center" }}
                          size={14}
                        />
                      ) : (
                        <span>
                          {" "}
                          {number_to_italian_currency(bank_account_balance)}
                        </span>
                      )}
                    </TableCell>{" "}
                    {/* Display the saved transfer description */}
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <span>{t("residual_balance_post_transfer")}: </span>
                      {bank_account_balance === null ? (
                        <CircularProgress
                          style={{ marginLeft: "10px", alignSelf: "center" }}
                          size={14}
                        />
                      ) : (
                        <span
                          style={{
                            color:
                              bank_account_balance -
                                italian_currency_to_number(transferAmount) -
                                bank_transfer_fee <
                                0
                                ? "red"
                                : "inherit",
                          }}
                        >
                          {number_to_italian_currency(
                            bank_account_balance -
                            italian_currency_to_number(transferAmount) -
                            bank_transfer_fee
                          )}
                        </span>
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </DialogContent>
            <DialogActions sx={{
              padding: 2,
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              gap: 2
            }}>
              {tempBeneficiary && (
                <Box sx={{ width: '100%' }}>
                  <FormControlLabel
                    control={<Checkbox />}
                    label={t("Save beneficiary")}
                    checked={shouldSaveBeneficiary}
                    onChange={onSaveBeneficiaryChange}
                  />
                </Box>
              )}
              <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: 2,
                width: '100%'
              }}>
                <Button
                  onClick={set_transfer_details}
                  variant="contained"
                  disabled={isProcessing}
                  sx={{ minWidth: '200px' }}
                >
                  {t("edit_button")}
                </Button>
                <Button
                  onClick={
                    doubleAuthExpiration &&
                      new Date(doubleAuthExpiration) > new Date()
                      ? handlebanktranfer
                      : () => handleSendOTPDoubleAuth(false)
                  }
                  variant="contained"
                  disabled={
                    isFeeBeingCalculated || hasInsufficientFunds || isProcessing
                  }
                  sx={{ minWidth: '200px' }}
                >
                  {isFeeBeingCalculated ? (
                    <>
                      <CircularProgress size={20} color="inherit" />
                    </>
                  ) : isProcessing ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : hasInsufficientFunds ? (
                    t("not_enough_funds")
                  ) : doubleAuthExpiration &&
                    new Date(doubleAuthExpiration) > new Date() ? (
                    t("send_money")
                  ) : (
                    t("send_verification_request")
                  )}
                </Button>
              </Box>
            </DialogActions>
          </Dialog>
        </>
      );
    case 'WAIT_PUSH_APPROVAL':
      return (
        <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
          <ModalTitle>
            {t("waiting_to_approve_from_mobile")}
          </ModalTitle>
          <DialogContent>
            {errorMessage && (
              <ErrorContainer isModal={false}>{errorMessage}</ErrorContainer>
            )}
            {pushTimer > 0 ? (
              <AnimatedPushNotification />
            ) : (
              <ErrorContainer isModal={false}>{t("push_notification_expired")}</ErrorContainer>
            )}
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 2,
              mt: 2
            }}>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2
              }}>
                <Typography
                  variant="body2"
                  component="button"
                  disabled={isProcessing}
                  onClick={() => handleSendOTPDoubleAuth(false)}
                  sx={{
                    textDecoration: 'none',
                    cursor: 'pointer',
                    border: 'none',
                    background: 'none',
                    color: '#666',
                    '&:hover': { color: '#000' }
                  }}
                >
                  {t("send_new_push")}
                </Typography>
                <Typography variant="body2" color="text.secondary">|</Typography>
                <Typography
                  variant="body2"
                  component="button"
                  disabled={isProcessing || smsTimer > 0}
                  onClick={() => handleSendOTPDoubleAuth(true)}
                  sx={{
                    textDecoration: 'none',
                    cursor: smsTimer > 0 ? 'default' : 'pointer',
                    border: 'none',
                    background: 'none',
                    color: smsTimer > 0 ? '#999' : '#666',
                    '&:hover': { color: smsTimer > 0 ? '#999' : '#000' }
                  }}
                >
                  {t("send_sms")}
                </Typography>
              </Box>
              {smsTimer > 0 && (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography variant="body2" color="text.secondary">
                    {t("sms_option_available_in")} {smsTimer}s
                  </Typography>
                </Box>
              )}
            </Box>
          </DialogContent>
        </Dialog>
      );
    case 'CONFIRM_SMS_OTP':
      return (
        <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
          <ModalTitle>
            {t('confirm_code')}
          </ModalTitle>
          <DialogContent>
            {successMessage && (
              <SuccessContainer isModal={false}>{successMessage}</SuccessContainer>
            )}
            {errorMessage && (
              <ErrorContainer isModal={false}>{errorMessage}</ErrorContainer>
            )}
            <Box style={{
              ...styles.step_description,
              textAlign: 'center',
              width: '100%'
            }}>
              {t("please_enter_6_digit_otp_received_via_sms")}
            </Box>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                gap: '10px'
              }}
            >
              {otp.map((digit, index) => (
                <TextField
                  key={index}
                  variant="outlined"
                  inputProps={{
                    maxLength: 1,
                    style: { width: "40px", textAlign: "center" },
                  }}
                  inputRef={(el) => (inputsRef.current[index] = el)}
                  value={digit}
                  onChange={(e) => handleChange(index, e.target.value)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                />
              ))}
            </Box>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center', gap: 2, padding: 2 }}>
            <Button
              onClick={() => handleSendOTPDoubleAuth(true)}
              variant="contained"
              disabled={isProcessing}
              sx={{ minWidth: '200px' }}
            >
              {t('resend_sms')}
            </Button>
            <Button
              ref={step4_submitButtonRef}
              onKeyDown={step4_handleBackspaceOnSubmit}
              onClick={step5_handleVerifyOTPDoubleAuth}
              variant="contained"
              disabled={isProcessing}
              sx={{ minWidth: '200px' }}
            >
              {isProcessing ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                t('verify_otp_and_send_money')
              )}
            </Button>
          </DialogActions>
        </Dialog>
      );
    case 'PROCESSING_TRANSFER':
      return (
        <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
          <ModalTitle>
            {t('processing_bank_transfer')}
          </ModalTitle>
          <DialogContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', py: 3 }}>
              <CircularProgress size={48} sx={{ mb: 2 }} />
              <Typography variant="h6" gutterBottom>
                {transferStatus.message}
              </Typography>
              <Typography variant="body2" color="text.secondary" align="center">
                {t('transfer_in_progress_do_not_close_this_window')}
              </Typography>
            </Box>
          </DialogContent>
        </Dialog>
      );
    case 'TRANSFER_SUMMARY':
      return (
        <>
          <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
            <ModalTitle>
              {t('bank_transfer_status')}
            </ModalTitle>
            <DialogContent>
              {errorMessage && (
                <ErrorContainer isModal={false}>{errorMessage}</ErrorContainer>
              )}
              {successMessage && (
                <SuccessContainer isModal={false}>{successMessage}</SuccessContainer>
              )}
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', padding: 2 }}>
              <Button
                ref={step4_submitButtonRef}
                onClick={closeModal}
                variant="contained"
                disabled={isProcessing}
                sx={{ minWidth: '200px' }}
              >
                {isProcessing ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  t('close')
                )}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    default:
      return (
        <>
          error
        </>
      )
  }
}

export default ModalStartBankTransfer;