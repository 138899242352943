import React, { Component } from 'react';
import QrScanner from 'react-qr-scanner';

class QRScannerComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      delay: 100
    };
  }

  handleScan = (data) => {
    if (data) {
      this.props.onCodeDetected(data);
    }
  }

  handleError = (err) => {
    console.error(err);
  }

  render() {
    const previewStyle = {
      height: 240,
      width: 320,
      transform: 'scaleX(-1)'
    };

    return (
      <QrScanner
        delay={this.state.delay}
        style={previewStyle}
        onError={this.handleError}
        onScan={this.handleScan}
        facingMode={"environment"} // or "user" for the front camera
      />
    );
  }
}

export default QRScannerComponent;
