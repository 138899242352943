import React, { useState, useEffect } from 'react';
import { IconButton, InputAdornment, Modal, Typography, Button, Link, CircularProgress, ThemeProvider, Box, TextField } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate, useLocation } from 'react-router-dom';
import { getCookieValue, setCookieValue, deleteCookie, deleteSpecificCookie } from './App';
import { t } from './Util_format';
import { useIntercom } from 'react-use-intercom';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { get_api_url, getBusinessAccounts, isBusinessAccount, isUserPassedKyc } from './Util_API_calls';
import config from './config/env.json';
import { theme, LoginCentralPic, UnauthenticatedMainContainer, UnauthenticatedLeftContainer, UnauthenticatedRightContainer, ForgotPassword, LoginLogoMobile, TermsModal, ErrorContainer, SuccessContainer, UnauthenticatedLink, UnauthenticatedTitle } from '@style/styled.js';
import flag_it from '@images/flag_it.svg';
import flag_en from '@images/flag_en.svg';
import flag_fr from '@images/flag_fr.svg';
import flag_cn from '@images/flag_cn.svg';

function Login() {
  const { shutdown } = useIntercom();
  const [openType, setOpenType] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null); // To handle login errors
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const language = getCookieValue('language') || 'it';  // Default to 'it' if the cookie is not set
  const [successMessage, setSuccessMessage] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const action = searchParams.get('action');
  const cleanCookieAction = action === 'clean-cookie';

  const handleOpen = (type) => setOpenType(type);
  const handleClose = () => setOpenType(null);
  const handleTogglePassword = () => setShowPassword(!showPassword);

  const [termsHtmlContent, setTermsHtmlContent] = useState(""); // <-- Add this line for the HTML content
  const [policyHtmlContent, setPolicyHtmlContent] = useState("");

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleLogin(); // Call the login function when Enter key is pressed
    }
  };

  const handleLogin = async () => {
    setIsProcessing(true); // <-- Start the processing here
    setSuccessMessage();
    setError();
    const auth = getAuth();

    // Check if name and surname are present
    if (!email.trim() || !password.trim()) {
      setError(t("please_insert_email_and_password"));
      setIsProcessing(false);
      return;
    }

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      if (!userCredential.user.emailVerified) {
        navigate('/auth/resend-registration-email?email=' + email);
        return;
      }

      setCookieValue('user_logged_in', true);

      // 1. Obtain the token
      const token = await userCredential.user.getIdToken();

      // 2. Save the token in a session cookie
      document.cookie = `firebaseToken=${token}; path=/; samesite=strict`;

      // ensure input email is valid
      if (!email.includes('@') || !email.includes('.')) {
        setError(t("please_insert_valid_email"));
        setIsProcessing(false);
        return;
      }

      // 4. Call the API using the token
      await get_api_url();
      const isPassedKyc = isUserPassedKyc();
      let businessAccount;
      if (!isPassedKyc) {
        if (config.ENABLE_BUSINESS !== "true") {
          setCookieValue('kyc_completed', false);
          navigate("/identification");
          return;
        }
        const businessAccounts = getBusinessAccounts();
        businessAccount = businessAccounts.find((e) => e.statusId === 100);
      }
      if (!isPassedKyc && businessAccount === undefined) {
        setCookieValue('kyc_completed', false);
        navigate("/new_account");
        return;
      }

      let apiUrl;
      const body = {
        device_type: 'desktop',
        delivery_method: 'auto'
      };
      if (isPassedKyc) {
        apiUrl = `${config.API_DOMAIN_RETAIL}/api/auth/login-mfa/send-verification`;
      } else {
        apiUrl = `${config.API_DOMAIN_BUSINESS}/api/auth/login-mfa/send-verification`;
        body.business_id = businessAccount.business_id;
      }

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(body)
      });

      const responseData = await response.json();
      if (!response.ok) { // If the response is not okay
        if (response.status === 500) { // Check if status code is 429
          throw new Error(t("unknown_api_error"));
        }
        if (response.status === 503) { // Check if status code is 429
          deleteSpecificCookie('firebaseToken');
          navigate("/maintenance");
        }
        if (responseData.fdt_error_code) {
          throw new Error(t(responseData.fdt_error_code));
        } else {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      }

      // alert the response data
      setCookieValue('kyc_completed', true);

      setEmail('');
      setPassword('');

      // set the 1h expiration time for the cookie (regardless if the user refreshes the page or not)
      setCookieValue('firebase_session_expiration_time', new Date(new Date().getTime() + (config.SESSION_MAX_LIFETIME_IN_MINUTES * 60 * 1000)).toUTCString());

      // set the 1h expiration time for the 5 minutes of inactivity
      setCookieValue('inactivity_session_expiration_time', new Date(new Date().getTime() + (5 * 60 * 1000)).toUTCString());

      setCookieValue('otp_delivery_method', responseData.delivery_method);
      if (responseData.delivery_method === "push") {
        navigate("/waiting_push");
      } else {
        navigate("/login_otp");
      }

    } catch (error) {
      console.error("Login error:", error);
      switch (error.message) {
        case 'Firebase: Error (auth/invalid-login-credentials).':
          setError(t("invalid_username_or_password"));
          break;
        case 'Firebase: Error (auth/invalid-email).':
          setError(t("invalid_login_email"));
          break;
        case 'Firebase: Error (auth/user-disabled).':
          setError(t("user_disabled"));
          break;
        case 'Firebase: Error (auth/user-not-found).':
          setError(t("user_not_found"));
          break;
        case 'Firebase: Error (auth/wrong-password).':
          setError(t("invalid_username_or_password"));
          break;
        case 'Firebase: Error (auth/too-many-requests).':
          setError(t("too_many_requests"));
          break;
        case 'Firebase: Error (auth/invalid-credential).':
          setError(t("invalid_credential"));
          break;
        default:
          deleteSpecificCookie('firebaseToken');
          setError(error.message || "An error occurred.");
          break;
      }
    } finally {
      setIsProcessing(false);  // End the processing regardless of success or error
    }
  };

  useEffect(() => {
    // Check for the 'action=clean-cookie' in the URL

    const password_update_successMessage = new URLSearchParams(window.location.search).get('message') === 'password_update_success';
    if (password_update_successMessage) {
      // If the message is to clean the cookie, delete it
      deleteCookie();
      // Remove 'message' parameter from the URL
      urlParams.delete('message');
      window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
      // put language back in the cookie
      document.cookie = `language=${language}; path=/; samesite=strict`;
      setSuccessMessage(t("password_update_success"));
    }

    if (cleanCookieAction) {
      shutdown();
      // If the action is to clean the cookie, delete it
      deleteCookie();

      // Remove 'action' parameter from the URL
      searchParams.delete('action');
      navigate(`${location.pathname}?${searchParams}`, { replace: true });

      // Put language back in the cookie (assuming you have defined language)
      document.cookie = `language=${language}; path=/; samesite=strict`;
    } else {
      // Otherwise, check for the existence of the firebaseToken
      const token = getCookieValue('firebaseToken');
      const loginOtpCompleted = getCookieValue('login_otp_completed');

      if (token) {
        if (loginOtpCompleted === 'true') {
          navigate("/home");
        } else {
          const isPushMessage = getCookieValue('otp_delivery_method') === "push";
          if (isPushMessage) {
            navigate("/waiting_push");
          } else {
            navigate("/login_otp");
          }
        }
      }
    }

    const userNotFound = new URLSearchParams(window.location.search).get('reason') === 'user_not_found';
    if (userNotFound) {
      deleteCookie();
      setError(t("user_not_found_error"));
      urlParams.delete('reason');
      window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
      document.cookie = `language=${language}; path=/; samesite=strict`;
    }

    const error_too_many_attempts = new URLSearchParams(window.location.search).get('reason') === 'too_many_attempts';
    if (error_too_many_attempts) {
      setError(t("please_login_again_too_many_failed_attempts"));
      urlParams.delete('reason');
      window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
    }

    const error_session_expired = new URLSearchParams(window.location.search).get('reason') === 'session_expired';
    if (error_session_expired) {
      setError(t("please_login_again_session_expired"));
      urlParams.delete('reason');
      window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
    }

    const error_account_banned = new URLSearchParams(window.location.search).get('reason') === 'account-banned';
    if (error_account_banned) {
      setError(t("error_account_is_banned"));
      urlParams.delete('reason');
      window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
    }

    const success_please_login_with_new_email_address = new URLSearchParams(window.location.search).get('reason') === 'please_login_with_new_email_address';
    if (success_please_login_with_new_email_address) {
      // If the message is to clean the cookie, delete it
      deleteCookie();
      // Remove 'message' parameter from the URL
      urlParams.delete('message');
      window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
      // put language back in the cookie
      document.cookie = `language=${language}; path=/; samesite=strict`;
      setSuccessMessage(t("success_please_login_with_new_email_address"));
    }

    const password_reset_successMessage = new URLSearchParams(window.location.search).get('message') === 'password_reset_success';
    if (password_reset_successMessage) {
      // If the message is to clean the cookie, delete it
      deleteCookie();
      // Remove 'message' parameter from the URL
      urlParams.delete('message');
      window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
      // put language back in the cookie
      document.cookie = `language=${language}; path=/; samesite=strict`;
      setSuccessMessage(t("password_reset_success"));
    }

    const registration_completed_check_your_emailMessage = new URLSearchParams(window.location.search).get('message') === 'registration_completed_check_your_email';
    if (registration_completed_check_your_emailMessage) {
      // If the message is to clean the cookie, delete it
      deleteCookie();
      // Remove 'message' parameter from the URL
      urlParams.delete('message');
      window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
      // put language back in the cookie
      document.cookie = `language=${language}; path=/; samesite=strict`;
      setSuccessMessage(t("registration_completed_check_your_email"));
    }

  }, [navigate]);

  const downloadPdf = (fileName) => {
    const language = getCookieValue("language") === 'en' ? 'en' : 'it';
    const terms_url = openType === 'terms' ? `./docs/termini-${language}${isBusinessAccount() ? "-business" : ""}.pdf` : `./docs/privacy_policy-${language}.pdf`;
    const downloadLink = document.createElement('a');
    downloadLink.href = terms_url; // Replace with your actual file path
    downloadLink.download = `${terms_url}.pdf`;
    downloadLink.click();
  };

  useEffect(() => {
    const language = getCookieValue("language") === "en" ? "en" : "it";
    fetch(`./docs/termini-${language}${isBusinessAccount() ? "-business" : ""}.html`) // Adjust the path if necessary
      .then((response) => response.text())
      .then((content) => {
        setTermsHtmlContent(content);
      });
    fetch(`./docs/privacy_policy-${language}.html`) // Adjust the path if necessary
      .then((response) => response.text())
      .then((content) => {
        setPolicyHtmlContent(content);
      });
  }, [navigate]);

  return (
    <ThemeProvider theme={theme}>
      <UnauthenticatedMainContainer id="PageLogin">
        <UnauthenticatedLeftContainer>
          <LoginCentralPic></LoginCentralPic>
        </UnauthenticatedLeftContainer>

        <UnauthenticatedRightContainer>
          <LoginLogoMobile />
          <UnauthenticatedTitle>
            {t("login_page_title")}
          </UnauthenticatedTitle>
          <Typography variant="body2" gutterBottom >
            {t("new_user")}{" "}
            <UnauthenticatedLink href="/register"> {t("create_new_account")}</UnauthenticatedLink>
          </Typography>

          <TextField
            label={t("email_address")}
            variant="outlined"
            fullWidth
            margin="normal"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={handleKeyDown}
          />

          <TextField
            label={t("password")}
            variant="outlined"
            fullWidth
            margin="normal"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={handleKeyDown}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {showPassword ? (
                    <Visibility
                      onClick={handleTogglePassword}
                      style={{ cursor: "pointer" }}
                    />
                  ) : (
                    <VisibilityOff
                      onClick={handleTogglePassword}
                      style={{ cursor: "pointer" }}
                    />
                  )}
                </InputAdornment>
              ),
            }}
          />

          {error && (
            <ErrorContainer isModal={false}>{error}</ErrorContainer>
          )}
          {successMessage && (
            <SuccessContainer isModal={false}>{successMessage}</SuccessContainer>
          )}

          <ForgotPassword>
            {t("forgot_password")}
          </ForgotPassword>

          <Button


            sx={{
              textTransform: "none",
              backgroundColor: isProcessing
                ? "#AAAAAA !important"
                : undefined,
              color: isProcessing ? "#FFFFFF !important" : undefined,
              minWidth: "100%",
              marginTop: "10px",
              marginBottom: "10px",
            }}
            onClick={handleLogin}
            disabled={isProcessing}
          >
            {isProcessing ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              t("login")
            )}
          </Button>

          <Box sx={{ display: 'flex', gap: 1 }}>
            <IconButton
              onClick={() => {
                document.cookie = `language=it; path=/; samesite=strict`;
                window.location.reload();
              }}
              sx={{ p: 0.5 }}
              title="Sito in Italiano"
            >
              <Box
                component="img"
                src={flag_it}
                alt="Italian"
                sx={{ height: '20px', width: '40px' }}
              />
            </IconButton>

            <IconButton
              onClick={() => {
                document.cookie = `language=en; path=/; samesite=strict`;
                window.location.reload();
              }}
              sx={{ p: 0.5 }}
              title="Website in English"
            >
              <Box
                component="img"
                src={flag_en}
                alt="English"
                sx={{ height: '20px', width: '40px' }}
              />
            </IconButton>

            {config.FDT_BRAND_CODE === 'ipm' && (
              <>
                <IconButton
                  onClick={() => {
                    document.cookie = `language=fr; path=/; samesite=strict`;
                    window.location.reload();
                  }}
                  className="p-2"
                  title="Site en Français"
                >
                  <Box
                    component="img"
                    src={flag_fr}
                    alt="French"
                    sx={{ height: '20px', width: '40px' }}
                  />
                </IconButton>

                <IconButton
                  onClick={() => {
                    document.cookie = `language=cn; path=/; samesite=strict`;
                    window.location.reload();
                  }}
                  className="p-2"
                  title="Site en Français"
                >
                  <Box
                    component="img"
                    src={flag_cn}
                    alt="French"
                    sx={{ height: '20px', width: '40px' }}
                  />
                </IconButton>
              </>
            )}
          </Box>

          <Typography variant="body2" >
            <Link href="#" onClick={() => handleOpen('terms')}>
              {t("terms_and_conditions")}
            </Link>   |   <Link href="#" onClick={() => handleOpen('privacy')}>
              {t("privacy_policy")}
            </Link>
          </Typography>

          <Modal
            open={openType != null}
            onClose={handleClose}
          >
            <TermsModal>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  variant="text"
                  startIcon={<FileDownloadIcon />}
                  onClick={() => downloadPdf()}
                >
                  {t(openType === 'terms' ? "terms_and_conditions" : 'privacy_policy')}
                </Button>
              </Box>

              <Box dangerouslySetInnerHTML={{ __html: openType === 'terms' ? termsHtmlContent : policyHtmlContent }} />
              <Button

                onClick={handleClose}
              >
                {t("close")}
              </Button>

            </TermsModal>
          </Modal>
        </UnauthenticatedRightContainer>
      </UnauthenticatedMainContainer>

    </ThemeProvider>
  );
}

export default Login;