import { Box, ThemeProvider, Typography, Link, Button } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCookieValue } from "./App";
import BusinessIntroduction from "./business_introduction";
import { getBusinessAccounts } from "./Util_API_calls";
import { t } from "./Util_format";
import { 
  theme, 
  LoginCentralPic, 
  UnauthenticatedMainContainer, 
  UnauthenticatedLeftContainer, 
  UnauthenticatedRightContainer, 
  LoginLogoMobile 
} from '@style/styled.js';
import config from './config/env.json';

function New_account() {
  const [isShowBusinessIntroduction, setShowBusinessIntroduction] = useState(false);
  const navigate = useNavigate();
  const initiatedKybAccounts = useMemo(
    () => getBusinessAccounts().filter((e) => e.statusId !== 100),
    []
  );

  useEffect(() => {
    if (config.ENABLE_BUSINESS !== "true" && getCookieValue('kyc_completed') !== "true") {
      navigate("/identification");
      return;
    }
    const type = window.location.pathname.split("/").pop();
    if (type === "business") {
      setShowBusinessIntroduction(true);
    } else if (getCookieValue("login_otp_completed") === "true") {
      navigate("/home");
    } else if (getCookieValue("user_logged_in") !== "true") {
      navigate("/login");
    }
  }, [navigate]);

  const buttonStyle = {
    minWidth: 250,
    marginBottom: 2
  };

  const showRetailOption = config.ENABLE_RETAIL === "true";
  const showBusinessOption = config.ENABLE_BUSINESS === "true";

  return (
    <ThemeProvider theme={theme}>
      <Box id="new_account">
        <UnauthenticatedMainContainer>
          <UnauthenticatedLeftContainer>
            <LoginCentralPic></LoginCentralPic>
          </UnauthenticatedLeftContainer>
          {!isShowBusinessIntroduction ? (
            <UnauthenticatedRightContainer>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 2
                }}
              >
                <LoginLogoMobile />
                {t("which_account_to_open")}
              </Typography>
              <Box sx={{ mt: 3 }} />
              {showBusinessOption && (
                <Button
                  variant="contained"
                  sx={buttonStyle}
                  onClick={() => {
                    setShowBusinessIntroduction(true);
                  }}
                >
                  {t("business_account")}
                </Button>
              )}
              {showRetailOption && (
                <Button
                  variant="contained"
                  sx={buttonStyle}
                  onClick={() => {
                    navigate("/kyc");
                  }}
                >
                  {t("personal_account")}
                </Button>
              )}

              {initiatedKybAccounts.length > 0 && (
                <Typography sx={{ mx: 1 }}>
                  {t("pick_from_where_you_left")}
                </Typography>
              )}
              {initiatedKybAccounts.map((e) => (
                <Button
                  key={e.business_id}
                  id={e.business_id}
                  variant="contained"
                  sx={buttonStyle}
                  onClick={() => {
                    navigate(`/kyb/${e.business_id}`);
                  }}
                >
                  {`${t("kyb_started")} ${e.label}`}
                </Button>
              ))}
              <Link
                href="/login?action=clean-cookie"
                variant="body2"
              >
                {t("logout")}
              </Link>
            </UnauthenticatedRightContainer>
          ) : (
            <UnauthenticatedRightContainer>
              <BusinessIntroduction />
            </UnauthenticatedRightContainer>
          )}
        </UnauthenticatedMainContainer>
      </Box>
    </ThemeProvider>
  );
}

export default New_account;