import { faCreditCard, faMobileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, Box, Button, Card, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, MenuItem, Select, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { Autocomplete, LoadScript } from "@react-google-maps/api";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { getCookieValue, setCookieValue } from "./App";
import { fetchBankAccountBalance, isBusinessAccount, fetchCardOrder, fetchCheckCreateCardMfaApprovalStatus } from "./Util_API_calls";
import { t } from "./Util_format";
import { sendOTPDoubleAuth, verifyOTPDoubleAuth } from "./Util_OTP_doubleauth";
import { ErrorContainer, SuccessContainer, ModalTitle } from '@style/styled.js';
import AppleGooglePayLogos from '@images/AppleGooglePayLogos.svg';
import cardb from '@images/cardb.webp';
import cardw from '@images/cardw.webp';
import config from './config/env.json';
import { AnimatedPushNotification } from './components/AnimatedPushNotification.js';

function ModalStartCardOrder({ onClose, closeModal, UserAccountIDFrom, card_accounts }) {
  const ownedVirtualBlueCount = card_accounts.filter(
    (card) =>
      card.card_type.toLowerCase() === "virtual" &&
      card.card_color.toLowerCase() === "blue"
  ).length;
  const ownedVirtualWhiteCount = card_accounts.filter(
    (card) =>
      card.card_type.toLowerCase() === "virtual" &&
      card.card_color.toLowerCase() === "white"
  ).length;
  const ownedPlasticBlueCount = card_accounts.filter(
    (card) =>
      card.card_type.toLowerCase() === "plastic" &&
      card.card_color.toLowerCase() === "blue"
  ).length;
  const ownedPlasticWhiteCount = card_accounts.filter(
    (card) =>
      card.card_type.toLowerCase() === "plastic" &&
      card.card_color.toLowerCase() === "white"
  ).length;
  const hasBlueCard = ownedVirtualBlueCount + ownedPlasticBlueCount > 0;

  const [ModalStepName, setModalStepName] = useState('SELECT_CARD_TYPE');
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [isProcessing, setIsProcessing] = useState(false);
  const inputsRef = useRef([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [doubleAuthOrderCardExp, setDoubleAuthOrderCardExp] = useState(null);
  const [bank_account_balance, setbank_account_balance] = useState(null);
  const [selectedCardToBuy, setselectedCardToBuy] = useState({
    type: null,
    color: null,
    cost: null,
  });
  const hasInsufficientFunds =
    bank_account_balance !== null &&
    bank_account_balance - selectedCardToBuy.cost < 0;
  const [autocomplete, setAutocomplete] = useState(null);
  const [cardTypeConfirmed, setCardTypeConfirmed] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const libraries = ["places"]; //
  const [smsTimer, setSmsTimer] = useState(30);
  const [pushTimer, setPushTimer] = useState(120);
  const [authMethod, setAuthMethod] = useState(null);
  const [transferStatus, setTransferStatus] = useState({
    status: 'idle', // 'idle' | 'preparing' | 'processing' | 'completed' | 'error'
    message: ''
  });
  const [isTransferInProgress, setIsTransferInProgress] = useState(false);
  const step4_submitButtonRef = useRef(null);
  const CardTypeTag = styled(Card)(({ type }) => ({
    margin: 8,
    padding: 30,
    height: "320px",
    width: "250px",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    alignItems: "stretch",
    backgroundColor: selectedCardToBuy.type === type ? "#e0f1f9" : "white",
    border:
      selectedCardToBuy.type === type
        ? "1px solid #2f2f2f"
        : "1px solid #f0f0f0",
    cursor: "pointer",
    transition: "background-color 0.3s ease", // Fade-in effect

    "&:hover": {
      backgroundColor: "#e0f1f9",
      border: "1px solid #2f2f2f",
    },
  }));

  const CardColorTag = styled(Card)(({ color }) => ({
    margin: 8,
    padding: 30,
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    alignItems: "stretch",

    height: "320px",

    width: "250px",
    backgroundColor: selectedCardToBuy.color === color ? "#e0f1f9" : "white",
    border:
      selectedCardToBuy.color === color
        ? "1px solid #2f2f2f"
        : "1px solid #f0f0f0",
    cursor: "pointer",
    transition: "background-color 0.3s ease", // Fade-in effect

    "&:hover": {
      backgroundColor: "#e0f1f9",
      border: "1px solid #2f2f2f",
    },
  }));

  const styles = {
    step_description: {
      paddingBottom: "20px",
    },
    card_title: {
      font: "Poppins",
      color: "#333333",
      textAlign: "left",
    },
    card_pic: {
      marginRight: "20px",
      color: "#333333",
    },
  };

  // This function is triggered when a card type is selected
  const handleCardTypeSelection = (cardType) => {
    setErrorMessage("");
    if (
      cardType === "plastic" &&
      ownedPlasticBlueCount >= 1 &&
      ownedPlasticWhiteCount >= 1
    ) {
      setErrorMessage(t("you_already_own_a_one_plastic_card_of_each_color"));
      setselectedCardToBuy({ type: null }); // Update both type and cost together
      return;
    }
    if (
      cardType === "virtual" &&
      ownedVirtualBlueCount >= 7 &&
      ownedVirtualWhiteCount >= 7
    ) {
      setErrorMessage(t("you_reached_the_limit_on_virtual_card_of_each_color"));
      setselectedCardToBuy({ type: null }); // Update both type and cost together
      return;
    }
    setselectedCardToBuy({ type: cardType }); // Update both type and cost together
  };

  const handleCardColorSelection = (cardColor) => {
    setErrorMessage("");

    if (
      selectedCardToBuy.type === "plastic" &&
      cardColor === "white" &&
      ownedPlasticWhiteCount >= 2
    ) {
      setErrorMessage(t("error_message_max_order_plastic_card"));
      setselectedCardToBuy((prevType) => ({
        ...prevType,
        type: selectedCardToBuy.type,
        color: null,
        cost: null,
      }));
      return;
    }
    if (
      selectedCardToBuy.type === "plastic" &&
      cardColor === "blue" &&
      ownedPlasticBlueCount >= 2
    ) {
      setErrorMessage(t("error_message_max_order_plastic_card"));
      setselectedCardToBuy((prevType) => ({
        ...prevType,
        type: selectedCardToBuy.type,
        color: null,
        cost: null,
      }));
      return;
    }

    if (
      selectedCardToBuy.type === "virtual" &&
      cardColor === "white" &&
      ownedVirtualWhiteCount >= 7
    ) {
      setErrorMessage(t("you_reached_the_limit_on_virtual_card_of_this_color"));
      setselectedCardToBuy((prevType) => ({
        ...prevType,
        type: selectedCardToBuy.type,
        color: null,
        cost: null,
      }));
      return;
    }
    if (
      selectedCardToBuy.type === "virtual" &&
      cardColor === "blue" &&
      ownedPlasticBlueCount >= 7
    ) {
      setErrorMessage(t("you_reached_the_limit_on_virtual_card_of_this_color"));
      setselectedCardToBuy((prevType) => ({
        ...prevType,
        type: selectedCardToBuy.type,
        color: null,
        cost: null,
      }));
      return;
    }

    let cost;
    switch (cardColor) {
      case "white":
        cost =
          selectedCardToBuy.type === "virtual"
            ? config.CARD_ORDER_FEES_VIRTUAL_WHITE
            : config.CARD_ORDER_FEES_PHYSICAL_WHITE;
        break;
      case "blue":
        cost =
          selectedCardToBuy.type === "virtual"
            ? config.CARD_ORDER_FEES_VIRTUAL_BLUE
            : config.CARD_ORDER_FEES_PHYSICAL_BLUE;
        break;
      default:
        cost = 0;
        break;
    }
    // alert(selectedCardToBuy.type);
    setselectedCardToBuy((prevType) => ({
      ...prevType,
      type: selectedCardToBuy.type,
      color: cardColor,
      cost: cost,
    }));
  };

  const restart_Order = () => {
    setModalStepName('SELECT_CARD_TYPE');
  };

  const step2_review_order_details = async () => {
    setErrorMessage("");
    setSuccessMessage("");
    if (acceptTerms === false) {
      setErrorMessage(t("please_accept_terms_order_card"));
      return;
    }
    setIsProcessing(true);
    const [balance] = await Promise.all([
      fetchBankAccountBalance(UserAccountIDFrom),
    ]);
    setIsProcessing(false);
    // Update the state with the fetched data
    setbank_account_balance(balance);
    setModalStepName('REVIEW_ORDER_DETAILS');
  };

  const handleChange = (index, value) => {
    if (!/^[0-9]$/.test(value)) return;
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Check if this is the last digit and all digits are filled
    const isLastInput = index === otp.length - 1;
    const allDigitsFilled = newOtp.every(digit => digit !== "");

    if (isLastInput && allDigitsFilled) {
      // Small delay to ensure state is updated before focusing
      setTimeout(() => {
        if (step4_submitButtonRef.current) {
          step4_submitButtonRef.current.focus();
        }
      }, 50);
    } else if (value !== "" && index < otp.length - 1 && inputsRef.current[index + 1]) {
      inputsRef.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      if (otp[index] === "") {
        // Move to previous input if current is empty
        if (index > 0 && inputsRef.current[index - 1]) {
          setOtp(prevOtp => {
            const newOtp = [...prevOtp];
            newOtp[index - 1] = "";
            return newOtp;
          });
          inputsRef.current[index - 1].focus();
        }
      } else {
        // Clear current input
        setOtp(prevOtp => {
          const newOtp = [...prevOtp];
          newOtp[index] = "";
          return newOtp;
        });
      }
    }
  };

  const handleChangeAddress = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleCardTypeConfirmed = () => {
    setCardTypeConfirmed(true);
  };

  const handleCardTypeConfirmedBack = () => {
    setErrorMessage("");
    setCardTypeConfirmed(false);
  };

  const handleAcceptTermsChange = (e) => {
    setErrorMessage("");
    setAcceptTerms(e.target.checked);
  };

  const placeChangedHandler = useCallback(() => {
    const twoToThreeLetterCountryCodes = {
      AT: "AUT", // Austria
      BE: "BEL", // Belgium
      BG: "BGR", // Bulgaria
      HR: "HRV", // Croatia
      CY: "CYP", // Cyprus
      CZ: "CZE", // Czech Republic
      DK: "DNK", // Denmark
      EE: "EST", // Estonia
      FI: "FIN", // Finland
      FR: "FRA", // France
      DE: "DEU", // Germany
      GR: "GRC", // Greece
      HU: "HUN", // Hungary
      IE: "IRL", // Ireland
      IT: "ITA", // Italy
      LV: "LVA", // Latvia
      LT: "LTU", // Lithuania
      LU: "LUX", // Luxembourg
      MT: "MLT", // Malta
      NL: "NLD", // Netherlands
      PL: "POL", // Poland
      PT: "PRT", // Portugal
      RO: "ROU", // Romania
      SK: "SVK", // Slovakia
      SI: "SVN", // Slovenia
      ES: "ESP", // Spain
      SE: "SWE", // Sweden
      GB: "GBR", // United Kingdom
    };

    const convertToThreeLetterCode = (twoLetterCode) => {
      return twoToThreeLetterCountryCodes[twoLetterCode] || twoLetterCode;
    };

    const place = autocomplete.getPlace();
    if (place && place.address_components) {
      let streetName = "";
      let addressObject = {
        addr_line_1: "",
        zip_code: "",
        city: "",
        state: "",
        country: "",
      };

      let streetNumber = "";
      let route = "";

      place.address_components.forEach((component) => {
        const types = component.types;
        if (types.includes("street_number")) {
          streetNumber = component.long_name;
        } else if (types.includes("route")) {
          route = component.long_name;
        } else if (types.includes("postal_code")) {
          addressObject.zip_code = component.long_name;
        } else if (
          types.includes("locality") ||
          types.includes("administrative_area_level_2")
        ) {
          addressObject.city = component.long_name;
        } else if (types.includes("administrative_area_level_1")) {
          addressObject.state = component.long_name;
        } else if (types.includes("country")) {
          addressObject.country = component.long_name;
        }
      });

      addressObject.addr_line_1 = `${route}, ${streetNumber}`.trim();

      place.address_components.forEach((component) => {
        const types = component.types;

        if (types.includes("route")) {
          streetName = component.long_name;
        }
        if (types.includes("street_number")) {
          streetNumber = component.long_name;
        }

        if (types.includes("postal_code")) {
          addressObject.zip_code = component.long_name;
        }
        if (
          types.includes("locality") ||
          types.includes("administrative_area_level_2")
        ) {
          addressObject.city = component.long_name;
        }
        if (types.includes("administrative_area_level_1")) {
          addressObject.state = component.long_name;
        }
        if (types.includes("country")) {
          addressObject.country = convertToThreeLetterCode(
            component.short_name
          );
        }
      });

      addressObject.addr_line_1 = `${streetName} ${streetNumber}`.trim();
      setFormValues((prevValues) => ({ ...prevValues, ...addressObject }));
    }
  }, [autocomplete]);

  // Initialize Autocomplete
  useEffect(() => {
    // Ensure that this effect only runs when ModalStepName is 2
    if (ModalStepName !== 'REVIEW_ORDER_DETAILS') {
      return;
    }

    // Check if autocomplete is defined
    if (!autocomplete) {
      console.error("Autocomplete is not defined");
      return;
    }

    // Add the listener
    const listener = autocomplete.addListener(
      "place_changed",
      placeChangedHandler
    );

    // Clean up
    return () => {
      // Safely remove the listener
      if (listener && window.google && window.google.maps) {
        window.google.maps.event.removeListener(listener);
      }
    };
  }, [autocomplete, ModalStepName, placeChangedHandler]); // Include ModalStepName in the dependency array

  useEffect(() => {
    if (ModalStepName === 'CONFIRM_SMS_OTP') {
      // Reset OTP and focus on first input when modal opens
      setOtp(Array(6).fill(""));
      if (inputsRef.current[0]) {
        setTimeout(() => {
          inputsRef.current[0].focus();
        }, 50);
      }
    }
  }, [ModalStepName]);

  const [formValues, setFormValues] = useState({
    addr_line_1: "",
    zip_code: "",
    city: "",
    state: "",
    country: "ITA",
  });

  // Remove handleSendOTPDoubleAuth and keep only the consolidated version
  const handleSendOTPDoubleAuth = async (isForceSms) => {
    setIsProcessing(true);
    if (doubleAuthOrderCardExp) {
      const expirationDate = new Date(doubleAuthOrderCardExp);
      const currentDate = new Date();
      const difference = expirationDate - currentDate;
      const minutesLeft = Math.round(difference / 60000);
      if (minutesLeft > 0) {
        handleCardOrder();
        return;
      }
    }

    setErrorMessage('');
    setSuccessMessage('');

    try {
      const response = await sendOTPDoubleAuth('card_create_mfa', isForceSms);

      // Set the correct modal step based on delivery method
      switch (response.delivery_method) {
        case 'sms':
          setAuthMethod('sms');
          setModalStepName('CONFIRM_SMS_OTP');
          setSmsTimer(30);
          break;
        case 'push':
          setAuthMethod('push');
          setModalStepName('WAIT_PUSH_APPROVAL');
          setPushTimer(120);
          setSmsTimer(30);
          break;
        default:
          console.log('Unknown delivery method');
          break;
      }
    } catch (error) {
      const errorMsg = error.message === "too_many_requests_try_later"
        ? t("too_many_requests_try_later")
        : t(error.message);
      setErrorMessage(errorMsg);
      setSuccessMessage('');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleBackspaceOnSubmit = (e) => {
    if (e.key === 'Backspace') {
      const lastInputIndex = otp.length - 1;
      if (inputsRef.current[lastInputIndex]) {
        inputsRef.current[lastInputIndex].focus();
      }
      e.preventDefault();
    }
  };


  const handleVerifyOTPDoubleAuth = async () => {
    setErrorMessage('');
    setSuccessMessage('');
    setIsProcessing(true);
    const token = getCookieValue('firebaseToken');

    try {
      await verifyOTPDoubleAuth('card_create_mfa', otp.join(''), token);
      const expirationDate = new Date(Date.now());
      setDoubleAuthOrderCardExp(expirationDate);
      setModalStepName('PROCESSING_ORDER');
      setTransferStatus({
        status: 'preparing',
        message: t('preparing_your_order')
      });
      handleCardOrder();
    } catch (error) {
      const errorMsg = error.message === "too_many_requests_try_later" ? t("too_many_requests_try_later") : t(error.message);
      setErrorMessage(errorMsg);
      setSuccessMessage('');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleCardOrder = async () => {
    if (isTransferInProgress) return;
    setIsTransferInProgress(true);

    try {
      setDoubleAuthOrderCardExp(new Date(Date.now()));
      setErrorMessage('');
      setSuccessMessage('');
      setIsProcessing(true);
      setModalStepName('PROCESSING_ORDER');
      setTransferStatus({
        status: 'preparing',
        message: t('order_in_progress_do_not_close_this_window')
      });

      const addressPayload = selectedCardToBuy.type === "virtual"
        ? {
          shipping_addr_line_1: "virtual Address 123",
          shipping_city: "virtual City",
          shipping_state: "virtual State",
          shipping_zip: "999999",
          shipping_country: "ITA",
        }
        : {
          shipping_addr_line_1: "Customer Registered Address 123",
          shipping_city: "Customer City",
          shipping_state: "Customer State",
          shipping_zip: "999999",
          shipping_country: "ITA",
        };

      const data = await fetchCardOrder(
        selectedCardToBuy.type.toLowerCase(),
        selectedCardToBuy.color.toLowerCase(),
        'EUR',
        addressPayload
      );

      setTransferStatus({
        status: 'completed',
        message: t('order_initiated_successfully')
      });
      setSuccessMessage(t('order_initiated_successfully'));
      setModalStepName('ORDER_COMPLETED');

    } catch (error) {
      console.error('Card order error:', error);
      setTransferStatus({
        status: 'error',
        message: error.message === "too_many_requests_try_later"
          ? t("too_many_requests_try_later")
          : t(error.message)
      });
      setErrorMessage(
        error.message === "too_many_requests_try_later"
          ? t("too_many_requests_try_later")
          : t(error.message)
      );
      setSuccessMessage('');
      setModalStepName('ORDER_COMPLETED');
    } finally {
      setIsProcessing(false);
      setIsTransferInProgress(false);
    }
  };

  useEffect(() => {
    let checkInterval;

    if (ModalStepName === 'WAIT_PUSH_APPROVAL') {
      let isIntervalProcessing = false; // Prevent overlapping checks
      let localDoubleAuthOrderCardExp = null; // Use local tracking for immediate access

      checkInterval = setInterval(async () => {
        if (isTransferInProgress || isIntervalProcessing) {
          return;
        }
        isIntervalProcessing = true;
        try {
          const data = await fetchCheckCreateCardMfaApprovalStatus();
          console.log('API Response:', data);

          if (data?.status === "success" && data?.message === "Authorised") {
            clearInterval(checkInterval); // Stop the interval
            console.log('Approval successful, clearing interval');

            const expirationDate = new Date(Date.now() + 5 * 60 * 1000); // Set expiration 5 minutes in the future
            localDoubleAuthOrderCardExp = expirationDate; // Update the local variable
            setDoubleAuthOrderCardExp(expirationDate); // Update state for other components
            setModalStepName('PROCESSING_ORDER');
            setTransferStatus({
              status: 'preparing',
              message: t('preparing_your_order'),
            });

            // Pass the local variable to handleCardOrder
            handleCardOrder(false, localDoubleAuthOrderCardExp);
          }
        } catch (error) {
          if (pushTimer <= 0) {
            clearInterval(checkInterval);
            setErrorMessage(t("push_notification_expired"));
          }
          // Stop the interval on error
        } finally {
          isIntervalProcessing = false; // Reset the flag
        }
      }, 2000);
    }

    return () => {
      if (checkInterval) clearInterval(checkInterval);
    };
  }, [ModalStepName, authMethod, isTransferInProgress]);

  // First useEffect for timer
  useEffect(() => {
    let timeoutId;
    if (pushTimer > 0) {
      timeoutId = setTimeout(() => setPushTimer(prev => prev - 1), 1000);
    }
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [pushTimer]);

  useEffect(() => {
    let timeoutId;
    if (smsTimer > 0) {
      timeoutId = setTimeout(() => setSmsTimer(prev => prev - 1), 1000);
    }
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [smsTimer]);

  const euCountries = [
    { name: "Austria", code: "AUT" },
    { name: "Belgium", code: "BEL" },
    { name: "Bulgaria", code: "BGR" },
    { name: "Croatia", code: "HRV" },
    { name: "Cyprus", code: "CYP" },
    { name: "Czech Republic", code: "CZE" },
    { name: "Denmark", code: "DNK" },
    { name: "Estonia", code: "EST" },
    { name: "Finland", code: "FIN" },
    { name: "France", code: "FRA" },
    { name: "Germany", code: "DEU" },
    { name: "Greece", code: "GRC" },
    { name: "Hungary", code: "HUN" },
    { name: "Ireland", code: "IRL" },
    { name: "Italy", code: "ITA" },
    { name: "Latvia", code: "LVA" },
    { name: "Lithuania", code: "LTU" },
    { name: "Luxembourg", code: "LUX" },
    { name: "Malta", code: "MLT" },
    { name: "Netherlands", code: "NLD" },
    { name: "Poland", code: "POL" },
    { name: "Portugal", code: "PRT" },
    { name: "Romania", code: "ROU" },
    { name: "Slovakia", code: "SVK" },
    { name: "Slovenia", code: "SVN" },
    { name: "Spain", code: "ESP" },
    { name: "Sweden", code: "SWE" },
    { name: "United Kingdom", code: "GBR" },
  ];

  switch (ModalStepName) {
    case 'SELECT_CARD_TYPE':
      return (
        <>
          <Dialog
            open={true}
            onClose={isProcessing ? undefined : onClose}
            maxWidth="sm"
            fullWidth
          >
            {!cardTypeConfirmed && (
              <>
                <ModalTitle>
                  {t("choose_card_type")}
                </ModalTitle>
                <DialogContent>
                  {errorMessage && (
                    <ErrorContainer isModal={false}>{errorMessage}</ErrorContainer>
                  )}
                  <Box style={styles.step_description}>
                    {t("choose_card_type_description")}
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CardTypeTag
                      type="virtual"
                      onClick={() => handleCardTypeSelection("virtual")}
                    >
                      <Typography
                        style={{
                          ...styles.card_title,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {t("BAAS_CARD_VIRTUAL_title")}{" "}
                        <FontAwesomeIcon
                          icon={faMobileAlt}
                          size="2x"
                          style={{ ...styles.card_pic, marginLeft: "1rem" }}
                        />
                      </Typography>
                      <Typography sx={{ flexGrow: 1, marginTop: "24px" }}>
                        {t("BAAS_CARD_VIRTUAL_WHITE_description")}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '12px',
                          mt: 1,
                          color: 'text.secondary',
                          fontStyle: 'italic'
                        }}
                      >
                        {t("virtual_blue_card_note")}
                      </Typography>

                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "1rem 0",
                        }}
                      >
                        <img
                          src={AppleGooglePayLogos}
                          alt="Apple Pay and Google Pay are supported"
                          title="Apple Pay and Google Pay are supported"
                          width="100px"
                        />
                      </Box>

                    </CardTypeTag>
                    <CardTypeTag
                      type="plastic"
                      onClick={() => handleCardTypeSelection("plastic")}
                    >
                      <Typography
                        style={{
                          ...styles.card_title,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {t("BAAS_CARD_PLASTIC_title")}{" "}
                        <FontAwesomeIcon
                          icon={faCreditCard}
                          size="2x"
                          style={{ ...styles.card_pic, marginLeft: "1rem" }}
                        />
                      </Typography>
                      <Typography sx={{ flexGrow: 1, marginTop: "24px" }}>
                        {t("BAAS_CARD_PLASTIC_description")}
                      </Typography>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "1rem 0",
                        }}
                      >
                        <img
                          src={AppleGooglePayLogos}
                          alt="Apple Pay and Google Pay are supported"
                          title="Apple Pay and Google Pay are supported"
                          width="100px"
                        />
                      </Box>
                    </CardTypeTag>
                  </Box>
                </DialogContent>
                <DialogActions>
                  {/* CLOSE BUTTON */}
                  <Button
                    onClick={onClose}

                    variant="contained"
                    sx={{ width: "100px" }}
                  >
                    {t("close")}
                  </Button>
                  <Button
                    onClick={handleCardTypeConfirmed}
                    variant="contained"
                    sx={{ minWidth: '200px' }}
                    disabled={selectedCardToBuy.type === null}
                  >
                    {isProcessing ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      t("next_button")
                    )}
                  </Button>
                </DialogActions>
              </>
            )}
            {cardTypeConfirmed && (
              <>
                <ModalTitle>
                  {t("choose_card_color_" + selectedCardToBuy.type)}
                </ModalTitle>
                <DialogContent>
                  {errorMessage && (
                    <ErrorContainer isModal={false}>{errorMessage}</ErrorContainer>
                  )}
                  {successMessage && (
                    <SuccessContainer isModal={false}>{successMessage}</SuccessContainer>
                  )}
                <Box style={styles.step_description}>
  <div>
    {t("card_selection_title_new")}
  </div>
  <div>
    {t("white_card_intro_new")} <strong>{t("white_card_limit_new")}</strong> {t("white_card_withdrawal_new")}
  </div>
  <div>
    {t("blue_card_intro_new")} <strong>{t("blue_card_limit_new")}</strong> {t("blue_card_withdrawal_new")} {t("blue_card_fee_new")}
  </div>
  <div>
    {t("cards_per_plan_new")}
  </div>
  <div>
    {t("shipping_info_new")}
  </div>
  <div>
    {t("digital_wallet_info_new")}
  </div>
</Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CardColorTag
                      color="white"
                      onClick={() => handleCardColorSelection("white")}
                    >
                      <Typography
                        style={{
                          ...styles.card_title,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={cardw}
                          alt="cardw"
                          style={{
                            width: "160px",
                            height: "auto",
                          }}
                        />
                      </Typography>
                      <Typography sx={{ flexGrow: 1, marginTop: "24px" }}>
                        {selectedCardToBuy.type === "virtual"
                          ? t("BAAS_CARD_VIRTUAL_WHITE_description")
                          : t(hasBlueCard
                            ? "BAAS_CARD_WHITE_active_description"
                            : "BAAS_CARD_WHITE_inactive_description")}
                      </Typography>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "1rem 0",
                        }}
                      >
                        <img
                          src={AppleGooglePayLogos}
                          alt="Apple Pay and Google Pay are supported"
                          title="Apple Pay and Google Pay are supported"
                          width="100px"
                        />
                      </Box>
                    </CardColorTag>
                    <CardColorTag
                      color="blue"
                      onClick={() => handleCardColorSelection("blue")}
                    >
                      <Typography
                        style={{
                          ...styles.card_title,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={cardb}
                          alt="cardb"
                          style={{
                            width: "160px",
                            height: "auto",
                          }}
                        />
                      </Typography>
                      <Typography sx={{ flexGrow: 1, marginTop: "24px" }}>
                        {t(
                          hasBlueCard
                            ? "BAAS_CARD_BLUE_active_description"
                            : "BAAS_CARD_BLUE_inactive_description"
                        )}
                      </Typography>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "1rem 0",
                        }}
                      >
                        <img
                          src={AppleGooglePayLogos}
                          alt="Apple Pay and Google Pay are supported"
                          title="Apple Pay and Google Pay are supported"
                          width="100px"
                        />
                      </Box>
                    </CardColorTag>
                  </Box>
                </DialogContent>
                <DialogActions>
                  {/* TERMS AND CONDITIONS CHECKBOX */}
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      flexGrow: 1,
                      marginRight: "4px",
                    }}
                  >
                    <Checkbox
                      checked={acceptTerms}
                      onChange={handleAcceptTermsChange}

                    />
                    <Typography variant="body2" >
                      <Link
                        href={`${window.location.origin}/docs/termini-${getCookieValue("language") === "en" ? "en" : "it"
                          }${isBusinessAccount() ? "-business" : ""}.html`}
                        onClick={() => { }}
                        without
                        rel="noreferrer"
                        target="_blank"
                      >
                        {t("accept_terms_order_card")}
                      </Link>
                    </Typography>
                  </Box>

                  {/* BACK BUTTON */}
                  <Button
                    onClick={handleCardTypeConfirmedBack}

                    variant="contained"
                    sx={{ width: "100px" }}
                    disabled={isProcessing}
                  >
                    {t("back_button")}
                  </Button>
                  {selectedCardToBuy.type && (
                    <Button
                      onClick={step2_review_order_details}
                      variant="contained"

                      disabled={
                        isProcessing || !selectedCardToBuy.color || !acceptTerms
                      }
                      sx={{ width: "100px" }}
                    >
                      {isProcessing ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        t("next_button")
                      )}
                    </Button>
                  )}
                </DialogActions>
              </>
            )}
          </Dialog>
        </>
      );
    case 'REVIEW_ORDER_DETAILS':
      return (
        <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
          <ModalTitle>
            {t("review_order_details")}
          </ModalTitle>
          <DialogContent>
            {errorMessage && (
              <ErrorContainer isModal={false}>{errorMessage}</ErrorContainer>
            )}
            {successMessage && (
              <SuccessContainer isModal={false}>{successMessage}</SuccessContainer>
            )}
            <Table style={{ width: "100%" }}>
              <TableBody>
                <TableRow>
                  <TableCell >
                    <Box style={{ paddingBottom: "16px" }}>
                      {" "}
                      <span>{t("selected_card_type")}: </span>
                      <span>
                        {t("card_" + selectedCardToBuy.type)}&nbsp;
                        {t("card_" + selectedCardToBuy.color)}
                      </span>
                    </Box>
                    <img
                      src={
                        selectedCardToBuy.color === "white" ? cardw : cardb
                      }
                      alt={`${selectedCardToBuy.color === "white"
                        ? "cardw"
                        : "cardb"
                        }`}
                      style={{
                        width: "160px",
                        height: "auto",
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell >
                    <span>{t("card_cost")}: </span>
                    <span>
                      {t(
                        selectedCardToBuy.type === "plastic"
                          ? "include_shipping"
                          : "included_in_your_plan"
                      )}
                    </span>
                  </TableCell>
                </TableRow>
                {/* <TableRow>
                    <TableCell >
                      <span>{t('available_balance_bank_account')}: </span>
                      <span> {number_to_italian_currency(bank_account_balance)}</span>
                    </TableCell>
                  </TableRow> */}
              </TableBody>
            </Table>
          </DialogContent>

          {selectedCardToBuy.type === "plastic-disabled-due-to-unlimit-ssues" && (
            <>
              <ModalTitle>
                {t("delivery_address")}
              </ModalTitle>
              <DialogContent>
                <LoadScript
                  googleMapsApiKey={config.GOOGLE_MAPS_API_KEY_WEB}
                  libraries={libraries}
                  language={"it"}
                >
                  <Autocomplete onLoad={setAutocomplete}>
                    <TextField
                      margin="normal"
                      name="search_address"
                      label={t("search_address")}
                      fullWidth
                      variant="outlined"
                      autoComplete="off"
                    // Removed onChange handler
                    />
                  </Autocomplete>
                  {/* ... other form fields */}
                  <TextField
                    margin="normal"
                    name="addr_line_1"
                    label={t("address_line_1")}
                    fullWidth
                    variant="outlined"
                    value={formValues.addr_line_1}
                    onChange={handleChangeAddress}
                  />
                  <TextField
                    margin="normal"
                    name="zip_code"
                    label={t("zip_code")}
                    fullWidth
                    variant="outlined"
                    value={formValues.zip_code}
                    onChange={handleChangeAddress}
                  />
                  <TextField
                    margin="normal"
                    name="city"
                    label={t("city")}
                    fullWidth
                    variant="outlined"
                    value={formValues.city}
                    onChange={handleChangeAddress}
                  />
                  <TextField
                    margin="normal"
                    name="state"
                    label={t("state")}
                    fullWidth
                    variant="outlined"
                    value={formValues.state}
                    onChange={handleChangeAddress}
                  />
                  <Select
                    style={{ width: "100%", marginTop: "15px" }}
                    id="country-select"
                    label={t("country")}
                    value={formValues.country}
                    onChange={handleChangeAddress}
                    name="country"
                  >
                    {euCountries.map((option) => (
                      <MenuItem key={option.code} value={option.code}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </LoadScript>
              </DialogContent>
            </>
          )}

          <DialogActions>
            <Button
              onClick={() => restart_Order()} // Corrected function call
              variant="contained"

              disabled={isProcessing}
            >
              {t("back_button")}
            </Button>
            <Button
              onClick={() => handleSendOTPDoubleAuth(false)}
              variant="contained"
              sx={{ minWidth: '200px' }}
              disabled={hasInsufficientFunds || isProcessing}
            >
              {hasInsufficientFunds ? (
                t("not_enough_funds")
              ) : isProcessing ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                t("send_verification_request")
              )}
            </Button>
          </DialogActions>
        </Dialog>
      );
    case 'WAIT_PUSH_APPROVAL':
      return (
        <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
          <ModalTitle>
            {t("waiting_to_approve_from_mobile")}
          </ModalTitle>
          <DialogContent>
            {errorMessage && (
              <ErrorContainer isModal={false}>{errorMessage}</ErrorContainer>
            )}
            {pushTimer > 0 ? (
              <AnimatedPushNotification />
            ) : (
              <ErrorContainer isModal={false}>{t("push_notification_expired")}</ErrorContainer>
            )}
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 2,
              mt: 2
            }}>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2
              }}>
                <Typography
                  variant="body2"
                  component="button"
                  disabled={isProcessing}
                  onClick={() => handleSendOTPDoubleAuth(false)}
                  sx={{
                    textDecoration: 'none',
                    cursor: 'pointer',
                    border: 'none',
                    background: 'none',
                    color: '#666',
                    '&:hover': { color: '#000' }
                  }}
                >
                  {t("send_new_push")}
                </Typography>
                <Typography variant="body2" color="text.secondary">|</Typography>
                <Typography
                  variant="body2"
                  component="button"
                  disabled={isProcessing || smsTimer > 0}
                  onClick={() => handleSendOTPDoubleAuth(true)}
                  sx={{
                    textDecoration: 'none',
                    cursor: smsTimer > 0 ? 'default' : 'pointer',
                    border: 'none',
                    background: 'none',
                    color: smsTimer > 0 ? '#999' : '#666',
                    '&:hover': { color: smsTimer > 0 ? '#999' : '#000' }
                  }}
                >
                  {t("send_sms")}
                </Typography>
              </Box>
              {smsTimer > 0 && (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography variant="body2" color="text.secondary">
                    {t("sms_option_available_in")} {smsTimer}s
                  </Typography>
                </Box>
              )}
            </Box>
          </DialogContent>
        </Dialog>
      );
    case 'CONFIRM_SMS_OTP':
      return (
        <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
          <ModalTitle>
            {t('confirm_code')}
          </ModalTitle>
          <DialogContent>
            {successMessage && (
              <SuccessContainer isModal={false}>{successMessage}</SuccessContainer>
            )}
            {errorMessage && (
              <ErrorContainer isModal={false}>{errorMessage}</ErrorContainer>
            )}
            <Box style={{
              ...styles.step_description,
              textAlign: 'center',
              width: '100%'
            }}>
              {t("please_enter_6_digit_otp_received_via_sms")}
            </Box>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                gap: '10px'
              }}
            >
              {otp.map((digit, index) => (
                <TextField
                  key={index}
                  variant="outlined"
                  inputProps={{
                    maxLength: 1,
                    style: { width: "40px", textAlign: "center" },
                  }}
                  inputRef={(el) => (inputsRef.current[index] = el)}
                  value={digit}
                  onChange={(e) => handleChange(index, e.target.value)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                />
              ))}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => handleSendOTPDoubleAuth(true)}
              variant="contained"

              disabled={isProcessing}
              sx={{ minWidth: '200px' }}
            >
              {t('resend_sms')}
            </Button>
            <Button
              ref={step4_submitButtonRef}
              onKeyDown={handleBackspaceOnSubmit}
              onClick={handleVerifyOTPDoubleAuth}
              variant="contained"

              disabled={!otp.every(digit => digit !== "") || isProcessing}
              sx={{ minWidth: '200px' }}
            >
              {isProcessing ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                t('verify_otp_and_send_card')
              )}
            </Button>
          </DialogActions>
        </Dialog>
      );
    case 'PROCESSING_ORDER':
      return (
        <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
          <ModalTitle>
            {t('processing_order')}
          </ModalTitle>
          <DialogContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', py: 3 }}>
              <CircularProgress size={48} sx={{ mb: 2 }} />
              <Typography variant="h6" gutterBottom>
                {transferStatus.message}
              </Typography>
              <Typography variant="body2" color="text.secondary" align="center">
                {t('order_in_progress_do_not_close_this_window')}
              </Typography>
            </Box>
          </DialogContent>
        </Dialog>
      );
    case 'ORDER_COMPLETED':
      return (
        <>
          <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
            <ModalTitle>
              {t('order_status')}
            </ModalTitle>
            <DialogContent>
              {errorMessage && (
                <ErrorContainer isModal={false}>{errorMessage}</ErrorContainer>
              )}
              {successMessage && (
                <SuccessContainer isModal={false}>{successMessage}</SuccessContainer>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={closeModal}
                variant="contained"

                disabled={isProcessing}
                sx={{ minWidth: '200px' }}
              >
                {isProcessing ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  t('close')
                )}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    default:
      return null;
  }

}
export default ModalStartCardOrder;