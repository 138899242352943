import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, InputAdornment, Button, Dialog, DialogTitle, DialogContent, DialogActions, Button as MuiButton, Typography, TextField  } from '@mui/material';
import { t } from './Util_format'; // Import translation function if needed
import SearchIcon from '@mui/icons-material/Search';

function ModalServicesListVendors({ isOpen, onClose, productType, catalog }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedVendor, setSelectedVendor] = useState('');
  const navigate = useNavigate();
  const location = useLocation(); // Hook to access the location object

  useEffect(() => {
    // Extract vendor from URL, e.g., /services/mobile/nokia
    const pathSegments = location.pathname.split('/');
    if (pathSegments.length > 3) {
      setSelectedVendor(pathSegments[3].replace(/_/g, ' ')); // Replace underscores with spaces and set it
    }
  }, [location]);

  // Filter catalog entries based on productType and potentially a selected vendor
  const filteredCatalog = catalog.filter(entry => {
    return entry.product_type === productType && (!selectedVendor || entry.supplier.toLowerCase() === selectedVendor.toLowerCase());
  });

  // Apply search term filter
  const filteredBySearchTerm = searchTerm.trim() === ''
    ? filteredCatalog
    : filteredCatalog.filter(entry =>
      entry.product_label && entry.product_label.toLowerCase().includes(searchTerm.toLowerCase())
    );

  // Extract unique suppliers from filtered catalog
  const uniqueSuppliers = Array.from(new Set(filteredBySearchTerm.map(entry => entry.supplier)));

  // Find the first EAN for each supplier
  const firstEANsBySupplier = {};
  filteredBySearchTerm.forEach(entry => {
    if (!firstEANsBySupplier[entry.supplier]) {
      firstEANsBySupplier[entry.supplier] = entry.ean;
    }
  });

  // Helper function to generate image path from EAN
  const getImagePathByEAN = (ean) => {
    const formattedEAN = String(ean).padStart(13, '0');
    return `/pics/epay/${formattedEAN}.webp`;
  };
  

  // Function to handle button click
  const handleButtonClick = (supplier) => {
    const currentCategory = productType;
    const formattedSupplier = supplier.replace(/ /g, '_').toLowerCase(); // Replace spaces with underscores and make lowercase
    navigate(`/services/${currentCategory}/${formattedSupplier}`); // Navigate to the desired URL
  };

  return (
    <Dialog open={isOpen} onClose={onClose} aria-labelledby="category-products-dialog-title">
      <DialogTitle id="category-products-dialog-title">{t("category_" + productType)}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          {t('services_' + productType + '_note_1')}
        </Typography><br></br>
        <TextField
          variant="outlined"
          fullWidth
          label={t('search_filter')}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ marginBottom: '20px' }}
          autoComplete="off"

          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Box style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '10px', marginBottom: '20px' }}>
          {uniqueSuppliers.map(supplier => (
            <Button
              key={supplier}
              style={{
                width: '120px',
                height: '120px',
                borderRadius: '25px',
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'white',
                border: '1px solid #cccccc',
                cursor: 'pointer'
              }}
              variant="contained"
              onClick={() => handleButtonClick(supplier)} // Attach onClick handler
            >
              <img
                src={getImagePathByEAN(firstEANsBySupplier[supplier])}
                alt={`${supplier} logo`}
                style={{ width: '80px', height: 'auto' }}
              />
            </Button>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <MuiButton onClick={onClose}  variant="contained">
          Close
        </MuiButton>
      </DialogActions>
    </Dialog>
  );
}

export default ModalServicesListVendors;
