import React, { useState, useEffect, } from 'react';
import { InputAdornment, IconButton, Grid2, Card, CardContent, Typography, Select, MenuItem, InputLabel, FormControl, ListItemIcon, ListItemText, Box, ThemeProvider, Table, TableBody, TableCell, TextField, TableHead, TableRow, } from '@mui/material';
import ListIcon from '@mui/icons-material/List';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { getCookieValue } from './App';
import { format } from 'date-fns';
import CircularProgress from '@mui/material/CircularProgress';
import { it } from 'date-fns/locale';
import { fetchPageData, fetchCardTransactions, fetchBankAccountTransactions } from './Util_API_calls'; // Adjust the path as necessary
import { t } from './Util_format';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { startOfMonth, endOfMonth } from 'date-fns';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import enGB from 'date-fns/locale/en-GB';
import { number_to_italian_currency } from './Util_format';
import ModalTransactionDetails from './ModalTransactionDetails';
import { useNavigate } from 'react-router-dom';
import { generatePDF } from './Util_generatePDF';
import { isCreditTransaction, isNOOPTransaction } from './Util_app';
import pdf_icon from '@images/pdf_icon.svg';
import { PageTitle, theme, FDTTableFooter, CurrencyTableCell } from '@style/styled.js';
import SearchIcon from '@mui/icons-material/Search';
import { transactionTypeKey } from './Util_app';
import { FDTTableSortLabel } from '@style/styled.js';

function TransactionsTable({ transactions, onRowClick, dateRange, searchTerm, account }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'desc' });

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const renderSortLabel = (key, label) => (
    <FDTTableSortLabel
      active={sortConfig.key === key}
      direction={sortConfig.key === key ? sortConfig.direction : 'asc'}
      onClick={() => requestSort(key)}
    >
      {label}
    </FDTTableSortLabel>
  );

  // Check if an account is selected and transactions is an array and not empty
  if (!account) {
    return (
      <Typography variant="body1" style={{ marginTop: '20px' }}>
        {t('please_select_the_account')}
      </Typography>
    );
  } else if (!Array.isArray(transactions) || transactions.length === 0) {
    return (
      <Typography variant="body1" style={{ marginTop: '40px' }}>
        {t('no_transactions_to_show')}
      </Typography>
    );
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime()) ? format(date, 'dd/MM/yyyy hh:mm:ss', { locale: it }) : 'Invalid Date';
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedTransactions = transactions.sort((a, b) => {
    const dateA = new Date(a.transaction_date_time);
    const dateB = new Date(b.transaction_date_time);
    return dateB - dateA; // This will sort in descending order
  });

  const filteredTransactions = sortedTransactions.filter(transaction => {
    // Determine the correct date field based on the transaction type
    const transactionDateField = transaction.card_id === undefined ? 'transaction_date_time' : 'transaction_date_time';
    const transactionDate = new Date(transaction[transactionDateField]);

    if (transactionDate < dateRange.from || transactionDate > dateRange.to) {
      return false;
    }

    // Determine the correct description field based on the transaction type
    const transactionDescription = transaction.card_id === undefined
      ? transaction.description?.toLowerCase() ?? ''
      : transaction.merchant_name ? (t(transaction.transaction_type) + ' @ ' + transaction.merchant_name).toLowerCase() : t(transaction.transaction_type).toLowerCase();

    return transactionDescription.includes(searchTerm.toLowerCase());
  });

  const displayedTransactions = filteredTransactions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <>
      <Table style={{ marginTop: '20px' }}>
        <TableHead>
          <TableRow>
            <TableCell>{renderSortLabel('date', t('date'))}</TableCell>
            <TableCell>{renderSortLabel('operation', t('operations'))}</TableCell>
            <TableCell>{renderSortLabel('description', t('description'))}</TableCell>
            <TableCell align="right">{renderSortLabel('amount', t('amount'))}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {displayedTransactions.map((transaction) => {
            // Determine the correct date field for display
            const transactionDateField = transaction.card_id === undefined ? 'transaction_date_time' : 'transaction_created_at';

            const beneficiaryInfo = transaction.beneficiary_name && transaction.beneficiary_surname
              ? ` - ${isCreditTransaction(transaction)
                ? t("received_from")
                : t("sent_to")} ${transaction.beneficiary_name} ${transaction.beneficiary_surname}`
              : "";

            return (
              <TableRow
                key={transaction.transaction_id}
                onClick={() => onRowClick(transaction)}
              >
                <TableCell style={{ cursor: "pointer" }}>
                  {formatDate(transaction[transactionDateField])}
                </TableCell>
                <TableCell style={{ cursor: "pointer" }}>
                  {t(transactionTypeKey(transaction)) + beneficiaryInfo}
                  {transaction.status === "PENDING" && (
                    <AccessTimeIcon
                      style={{ marginLeft: "5px", color: "#CCCCCC" }}
                    />
                  )}
                </TableCell>
                <TableCell style={{ cursor: "pointer" }}>
                  {transaction.description || transaction.merchant_name || ''}
                </TableCell>
                {transaction.status === "REJECTED" ||
                  isNOOPTransaction(transaction) ||
                  transaction.transaction_financial_status === "ON_HOLD" ? (
                  <TableCell align="right">
                    {transaction.status === "REJECTED"
                      ? t("cancelled")
                      : number_to_italian_currency(transaction.amount)}
                  </TableCell>
                ) : (
                  <CurrencyTableCell amount={number_to_italian_currency(transaction.amount)} isCredit={isCreditTransaction(transaction)} />
                )}
              </TableRow>
            );
          })}
        </TableBody>

      </Table>
      <FDTTableFooter
        style={{
          marginTop: '20px'
        }}
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredTransactions.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={t('rows_per_page')}
        labelDisplayedRows={
          ({ from, to, count }) => {
            return '' + from + '-' + to + ` ${t('of')} ` + count
          }
        }
      />
    </>
  );
}

function PageStatements({ onDataChange, ...props }) {
  const [account, setAccount] = React.useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [pageData, setPageData] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const navigate = useNavigate();
  const [sortedTransactions] = useState([]);
  const [accountType, setAccountType] = useState(null);
  const [selectedMonthYear, setSelectedMonthYear] = useState(dayjs().format('YYYY-MM'));

  // Other state and variables
  const [dateRange, setDateRange] = useState({
    from: startOfMonth(new Date()),
    to: endOfMonth(new Date()),
  });

  const handleShowTransaction = (transaction) => {
    setSelectedTransaction(transaction);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedTransaction(null);
  };

  const [userAccounts, setUserAccounts] = useState([]);

  const generateMonthYearOptions = () => {
    let options = [];
    let current = dayjs().startOf('month');
    for (let i = 0; i < 12; i++) { // Assuming last 12 months
      options.push(current.format('YYYY-MM'));
      current = current.subtract(1, 'month');
    }
    return options;
  };

  // Inside the get_page_data function, after fetching the data
  const processAccountsData = (userData) => {
    const cardAccounts = userData.user_accounts.card_accounts.map(account => ({
      name: `${t('card_label')} **** **** **** ${account.card_last_four_digits}`, // Masked PAN
      card_id: `${account.card_id}`,
      type: 'card',
      info: account.card_last_four_digits
    }));

    const currentAccounts = userData.user_accounts.current_accounts.map(account => ({
      name: `${t('bank_accounts')} ${account.account_number}`,
      account_id: `${account.account_id}`,
      type: 'account',
      info: account.account_number
    }));


    const allAccounts = [...currentAccounts, ...cardAccounts];
    setUserAccounts(allAccounts);
  };

  useEffect(() => {
    get_page_data();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event) => {
    setAccount(event.target.value);
  };

  const accountIcon = (type) => {
    return type === 'account' ?
      <AccountBalanceIcon sx={{ color: 'IconsInDropdown.main' }} /> :
      <CreditCardIcon sx={{ color: 'IconsInDropdown.main' }} />;
  };

  const getSelectedAccountType = () => {
    const selectedAccount = userAccounts.find(acc => acc.name === account);
    return selectedAccount ? selectedAccount.type : null;
  };

  useEffect(() => {
    if (account) {
      const accountType = getSelectedAccountType();
      setAccountType(accountType);
      if (accountType === 'card') {
        get_card_data();
      } else if (accountType === 'account') {
        get_bank_account_data();
      }
    }
  }, [account, dateRange]);


  useEffect(() => {
    const start = startOfMonth(new Date(selectedMonthYear));
    const end = endOfMonth(new Date(selectedMonthYear));

    setDateRange({
      from: start,
      to: end
    });
  }, [selectedMonthYear]);


  const get_card_data = async () => {
    const selectedAccount = userAccounts.find(acc => acc.name === account);
    if (!selectedAccount) {
      console.error("No selected account found for card data fetch");
      return;
    }

    const formattedFromDate = format(dateRange.from, 'yyyyMMdd'); // Format date to 'YYYY-MM-DD'
    const formattedToDate = format(dateRange.to, 'yyyyMMdd'); // Format date to 'YYYY-MM-DD'

    setIsLoading(true);
    try {
      const data = await fetchCardTransactions(
        selectedAccount.card_id,
        'ALL',
        formattedFromDate,
        formattedToDate,
        'CET'
      );

      if (data && data.transactions) {
        setTransactions(data.transactions);
      } else {
        setTransactions([]);
      }
    } catch (error) {
      console.error('Error fetching card data:', error);
      setTransactions([]);
    } finally {
      setIsLoading(false);
    }
  };


  const get_bank_account_data = async () => {
    const selectedAccount = userAccounts.find(acc => acc.name === account);
    if (!selectedAccount) {
      console.error("No selected account found for bank account data fetch");
      return;
    }

    const formattedFromDate = format(dateRange.from, 'yyyyMMdd'); // Format date to 'YYYY-MM-DD'
    const formattedToDate = format(dateRange.to, 'yyyyMMdd'); // Format date to 'YYYY-MM-DD'

    setIsLoading(true);
    try {
      const data = await fetchBankAccountTransactions(
        selectedAccount.account_id,
        'ALL',
        formattedFromDate,
        formattedToDate,
        'CET'
      );

      if (data && data.transactions) {
        setTransactions(data.transactions);
      } else {
        setTransactions([]);
      }
    } catch (error) {
      console.error('Error fetching bank account data:', error);
      setTransactions([]);
    } finally {
      setIsLoading(false);
    }
  };

  const get_page_data = async () => {
    setIsLoading(true);
    try {
      const apidata = await fetchPageData('statements');

      let data = apidata.data;

      if (apidata.status === 'error') {
        if (apidata.fdt_error_code === 'TOKEN_EXPIRED') {
          navigate('/login?action=clean-cookie&reason=session_expired');
          return;
        }
        if (apidata.fdt_error_code === 'NO_BUSINESS_ACCOUNT_FOUND') {
          alert(t('error_no_business_account_found'));
        } else {
          alert(t('error_getting_data_from_server'));
        }
        return;
      }

      if (onDataChange) {
        onDataChange(data);
      }
      if (data) {
        setPageData(data);
        // Call processAccountsData here to process and set user accounts
        processAccountsData(data);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };



  const formatDateSelect = (dateStr) => {
    const [year, month] = dateStr.split("-");
    const date = new Date(year, month - 1);
    const locale = getCookieValue("language") === "it" ? "it-IT" : "en-GB";
    return date.toLocaleString(locale, { 
      month: 'long', 
      year: 'numeric'
    }).charAt(0).toUpperCase() + date.toLocaleString(locale, { 
      month: 'long', 
      year: 'numeric'
    }).slice(1);
  };

  return (
    <ThemeProvider theme={theme}>
      {isModalOpen && (
        <ModalTransactionDetails
          transaction={selectedTransaction}
          onClose={handleCloseModal}
          pageData={pageData}
          info={userAccounts.find(acc => acc.name === account)?.info}
          isCardTransaction={accountType === 'card'}
        />
      )}
      <Card style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', padding: '10px', height: '100%' }}>
        <CardContent>
          <Grid2>
            <PageTitle>{t("bank_statements")}<PictureAsPdfIcon /></PageTitle>
          </Grid2>
          <Grid2 container spacing={2} alignItems="center">
            <FormControl style={{ marginRight: '10px', width: '450px' }}>
              <InputLabel id="account-select-label">
                {t('select_account_bankaccount_or_card')}
              </InputLabel>
              <Select
                labelId="account-select-label"
                id="account-select"
                value={account}
                label={t('select_account')}
                onChange={handleChange}
                IconComponent={ListIcon}
                renderValue={(selected) => (
                  <Box display="flex" alignItems="center">
                    {userAccounts.find((item) => item.name === selected) ? accountIcon(userAccounts.find((item) => item.name === selected).type) : null}
                    <Typography noWrap style={{ marginLeft: '8px' }}>
                      {selected}
                    </Typography>
                  </Box>
                )}
              >
                {userAccounts.map((item) => (
                  <MenuItem key={item.name} value={item.name}>
                    <ListItemIcon>
                      {accountIcon(item.type)}
                    </ListItemIcon>
                    <ListItemText primary={item.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              style={{ flexGrow: 2, marginRight: '10px' }}
              name="search_filter_unique"
              label={t('search_filter')}
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              autoComplete="no-autofill"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
              <FormControl style={{ width: '200px', marginRight: '10px' }}>
                <InputLabel id="month-year-select-label">{t('select_period')}</InputLabel>
                <Select
                  labelId="month-year-select-label"
                  id="month-year-select"
                  value={selectedMonthYear}
                  onChange={(e) => setSelectedMonthYear(e.target.value)}
                  label={t('select_statement')}
                >
                  {generateMonthYearOptions().map((option) => (
                    <MenuItem key={option} value={option}>
                      {formatDateSelect(option)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </LocalizationProvider>

            {/* PDF button - only shown when account selected */}
            {account && (
              <Grid2 xs="auto">
                <IconButton
                  onClick={() => generatePDF(
                    accountType,
                    transactions,
                    null, // dateRange not needed for statements
                    null, // sortedTransactions not needed
                    selectedMonthYear,
                    pageData,
                    userAccounts.find(acc => acc.name === account)?.info
                  )}
                  disabled={isLoading}
                  sx={{ color: 'primary.main' }}
                >
                  <img src={pdf_icon} alt="PDF" style={{ height: '24px' }} />
                </IconButton>
              </Grid2>
            )}
          </Grid2>
          {isLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="300px">
              <CircularProgress />
            </Box>
          ) : (
            <TransactionsTable
              key={searchTerm}
              transactions={transactions}
              dateRange={dateRange}
              searchTerm={searchTerm}
              account={account}
              onRowClick={handleShowTransaction}
              ongeneratePDF={() => generatePDF(
                accountType,
                transactions,
                { account, searchTerm, dateRange },
                sortedTransactions,
                selectedMonthYear,
                pageData,
                userAccounts.find(acc => acc.name === account)?.info
              )}
            />
          )}
        </CardContent>
      </Card>
    </ThemeProvider>
  );
}

export default PageStatements;