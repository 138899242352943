import { CircularProgress, Link, ThemeProvider, Typography, Stack, Box } from "@mui/material";
import { Timer } from '@mui/icons-material';
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCookieValue, setCookieValue } from "./App";
import { fetchPageData, get_api_url } from "./Util_API_calls";
import { t } from "./Util_format";
import { theme, LoginCentralPic, UnauthenticatedMainContainer, UnauthenticatedLeftContainer, UnauthenticatedRightContainer, LoginLogoMobile, ErrorContainer } from '@style/styled.js';
import { AnimatedPushNotification } from './components/AnimatedPushNotification.js';

// Define keyframes for animations
const bounce = `
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-15px);
    }
    60% {
      transform: translateY(-7px);
    }
  }
`;

const pulse = `
  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.2);
      opacity: 0.7;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
`;

// Add styles to head
const style = document.createElement('style');
style.textContent = bounce + pulse;
document.head.appendChild(style);


const TimerDisplay = ({ seconds }) => {
  return (
    <Box
      sx={{
        mt: 3,
        mb: 3,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 1
      }}
    >
      <Timer fontSize="small" color="action" />
      <Typography variant="body2">
        {t("sms_option_available_in")}
      </Typography>
      <Box
        sx={{
          position: "relative",
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
          ml: 1
        }}
      >
        <CircularProgress
          variant="determinate"
          value={(100 * seconds) / 30}
          size={32}
          sx={{
            color: theme.palette.primary.main,
            position: 'absolute'
          }}
        />
        <Typography
          variant="body2"
          sx={{
            fontWeight: "bold",
            color: theme.palette.text.secondary
          }}
        >
          {seconds}
        </Typography>
      </Box>
      <Typography variant="body2"
        sx={{
          marginLeft: '10px'
        }}
      >
        {t("seconds")}
      </Typography>
    </Box>
  );
};

function Waiting_Push() {
  const [error, setError] = useState(null);
  const [smsTimer, setSmsTimer] = useState(30);
  const [pushTimer, setPushTimer] = useState(120);
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState(false);
  const intervalRef = useRef();

  useEffect(() => {
    let timeoutId;
    if (smsTimer > 0) {
      timeoutId = setTimeout(() => setSmsTimer(smsTimer - 1), 1000);
    }
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [smsTimer]);

  useEffect(() => {
    let timeoutId;
    if (pushTimer > 0) {
      timeoutId = setTimeout(() => setPushTimer(pushTimer - 1), 1000);
    }
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [pushTimer]);

  useEffect(() => {
    if (getCookieValue("otp_delivery_method") === "push") {
      // Function to check the status
      const checkStatus = async () => {
        const apidata = await fetchPageData("home");
        if (apidata.status !== "error") {
          setCookieValue("login_otp_completed", "true");
          navigate("/home");
        }
      };

      // Check immediately
      checkStatus();

      // Then set up interval to check every 0.5 seconds
      intervalRef.current = setInterval(checkStatus, 500);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [navigate]);

  useEffect(() => {
    const cookieRow = document.cookie
      .split("; ")
      .find((row) => row.startsWith("firebaseToken="));
    const cookieValue = cookieRow ? cookieRow.split("=")[1] : null;
    const loginOtpCompleted = getCookieValue("login_otp_completed");
    const KycCompleted = getCookieValue("kyc_completed");

    if (!cookieValue) {
      navigate("/login");
    } else {
      if (KycCompleted !== "true") {
        navigate('/new_account');
        return;
      }
      if (loginOtpCompleted === "true") {
        navigate("/home");
      }
    }
  }, [navigate]);

  const onForceSendSMS = useCallback(async () => {
    setIsProcessing(true);
    setError(null);
    setSmsTimer(30);
    setPushTimer(120);

    const token = getCookieValue("firebaseToken");

    try {
      const API_URL = await get_api_url();
      const response = await fetch(
        `${API_URL}/api/auth/login-mfa/send-verification`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            device_type: "desktop",
            delivery_method: "sms",
          }),
        }
      );

      const errorData = await response.json();

      if (!response.ok || errorData.status === "error") {
        console.error("Error Response:", errorData);

        if (errorData.fdt_error_code) {
          throw new Error(t(errorData.fdt_error_code));
        } else {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      }

      // Add these lines to handle successful SMS request
      setCookieValue("otp_delivery_method", "sms");
      navigate("/login_otp");  // Navigate to OTP page after successful SMS request

      return errorData;
    } catch (error) {
      setError(error.message);
    } finally {
      setIsProcessing(false);
    }
  }, [navigate]);

  const onForceSendNewPush = useCallback(async () => {
    setIsProcessing(true);
    setError(null);
    setSmsTimer(30);
    setPushTimer(120);

    const token = getCookieValue("firebaseToken");

    try {
      const API_URL = await get_api_url();
      const response = await fetch(
        `${API_URL}/api/auth/login-mfa/send-verification`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            device_type: "desktop",
            delivery_method: "push",
          }),
        }
      );
      try {
        const errorData = await response.json();

        if (!response.ok || errorData.status === "error") {
          console.error("Error Response:", errorData);

          // Throw error with the specific error message from API if available
          if (errorData.fdt_error_code) {
            throw new Error(t(errorData.fdt_error_code));
          } else {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
        }

        return errorData;
      } catch (error) {
        console.error("Failed to process response:", error);
        throw error;
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsProcessing(false);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box id="PageWaitingPush">
        <UnauthenticatedMainContainer>
          <UnauthenticatedLeftContainer>
            <LoginCentralPic></LoginCentralPic>
          </UnauthenticatedLeftContainer>
          <UnauthenticatedRightContainer>
            <LoginLogoMobile />
            {(pushTimer > 0 && !isProcessing) && (
              <AnimatedPushNotification />
            )}

            {error && (
              <ErrorContainer isModal={false}>{error}</ErrorContainer>
            )}
            {(pushTimer <= 0 && !isProcessing) && (
              <ErrorContainer isModal={false}>{t("push_notification_expired")}</ErrorContainer>
            )}
            {isProcessing ? (
              <Box sx={{ paddingTop: '20px' }}>
                <CircularProgress size={24} color="inherit" />
              </Box>
            ) : (
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <Typography
                  variant="body2"
                  component="button"
                  disabled={isProcessing}
                  onClick={onForceSendNewPush}
                  sx={{
                    textDecoration: isProcessing ? 'none' : 'underline',
                    cursor: isProcessing ? 'default' : 'pointer',
                    border: 'none',
                    background: 'none',
                    p: 0
                  }}
                >
                  {t("send_new_push")}
                </Typography>

                {smsTimer === 0 && pushTimer > 0 && (
                  <>
                    <Typography variant="body2" color="text.secondary">|</Typography>
                    <Typography
                      variant="body2"
                      component="button"
                      disabled={isProcessing}
                      onClick={onForceSendSMS}
                      sx={{
                        textDecoration: isProcessing ? 'none' : 'underline',
                        cursor: isProcessing ? 'default' : 'pointer',
                        border: 'none',
                        background: 'none',
                        p: 0
                      }}
                    >
                      {isProcessing ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        t("send_sms")
                      )}
                    </Typography>
                  </>
                )}
              </Stack>
            )}
            {(smsTimer > 0 && pushTimer > 0 && !isProcessing) && (
              <>
                <TimerDisplay seconds={smsTimer} />
              </>
            )}

            <Link
              href="/login?action=clean-cookie"
              variant="body2"
              disabled={isProcessing}
            >
              <Typography variant="body2">
                {t('back_to_login')}
              </Typography>
            </Link>

          </UnauthenticatedRightContainer>
        </UnauthenticatedMainContainer>
      </Box>
    </ThemeProvider>
  );
}

export default Waiting_Push;