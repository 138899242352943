import React, { } from 'react';
import { Link, ThemeProvider, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { t } from './Util_format';
import { theme, LoginCentralPic, UnauthenticatedMainContainer, UnauthenticatedLeftContainer, UnauthenticatedRightContainer, LoginLogoMobile} from '@style/styled.js';     

function Identification() {
  const navigate = useNavigate();
  const handleLogin = async () => {
    navigate("/kyc");
  }

  return (
    <ThemeProvider theme={theme}>
      <Box id="PageIdentification">
        <UnauthenticatedMainContainer>
          <UnauthenticatedLeftContainer>
            <LoginCentralPic></LoginCentralPic>
          </UnauthenticatedLeftContainer>

          <UnauthenticatedRightContainer>
          <LoginLogoMobile/>
            {t("explain_kyc")}
            <Button
              variant="contained"              
              sx={{
                textTransform: "none",
              }}
              onClick={handleLogin}
            >
              {t("start_kyc")}
            </Button>
            <Link
              href="/login?action=clean-cookie"
            >
              {t("logout")}
            </Link>
          </UnauthenticatedRightContainer>
        </UnauthenticatedMainContainer>
      </Box>
    </ThemeProvider>
  );
}

export default Identification;